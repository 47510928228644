import React from 'react';

const SessionExpiredPage = () => {
  localStorage.removeItem('accountId');
  localStorage.removeItem('accountType');
  localStorage.removeItem('organizationId');
  localStorage.removeItem('email');
  localStorage.removeItem('name');
  localStorage.removeItem('token');

  setTimeout(() => {
    window.location.href = '/';
  }, 1000);

  return (
    <div className="fade-in text-center text-danger">
      <div className="pt-5" />
      <div className="pt-5" />
      Session expired.
    </div>
  );
};

export default SessionExpiredPage;
