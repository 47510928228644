import React from 'react';
import PropTypes from 'prop-types';
import './MonthlyStatusesPage.css';
import LoadingText from '../common/LoadingText';
import server from '../../server';

class MonthlyStatusesPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      organizations: [],
      totalOrganizations: [],
      projects: [],
      statusLog: [],
      filterString: '',
      searchString: '',
      page: 1,
      isLoading: false,
      rerender: false,
      year: new Date(Date.now()).getFullYear(),
      getOrganizationsRoute: `${server}/help-desk/organization/get-organizations`,
      getTotalOrganizationsRoute: `${server}/help-desk/organization/get-total-organizations`,
      getProjectsRoute: `${server}/help-desk/organization/get-projects`,
      getFullStatusLogRoute: `${server}/help-desk/statistics/get-full-status-log`,
    };
  }

  componentDidMount() {
    const { currentUserId, accountType, organizationId } = this.props;

    const {
      filterString,
      searchString,
      page,
      getOrganizationsRoute,
      getTotalOrganizationsRoute,
      getProjectsRoute,
      getFullStatusLogRoute,
    } = this.state;

    this.setState({ isLoading: true });

    fetch(getOrganizationsRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        currentUserId,
        accountType,
        organizationId,
        filterString,
        searchString,
        page,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ organizations: res, isLoading: false }));

    fetch(getTotalOrganizationsRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        currentUserId,
        accountType,
        organizationId,
        filterString,
        searchString,
        page,
      }),
    })
      .then((res) => res.json())
      .then((res) =>
        this.setState({ totalOrganizations: res, isLoading: false }),
      );

    fetch(getProjectsRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((res) => this.setState({ projects: res, isLoading: false }));

    fetch(getFullStatusLogRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((res) => this.setState({ statusLog: res }));
  }

  componentDidUpdate() {
    const { currentUserId, accountType, organizationId } = this.props;

    const {
      filterString,
      searchString,
      page,
      rerender,
      getOrganizationsRoute,
      getTotalOrganizationsRoute,
    } = this.state;

    if (rerender) {
      fetch(getOrganizationsRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          currentUserId,
          accountType,
          organizationId,
          filterString,
          searchString,
          page,
        }),
      })
        .then((res) => res.json())
        .then((res) => this.setState({ organizations: res, rerender: false }));

      fetch(getTotalOrganizationsRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          currentUserId,
          accountType,
          organizationId,
          filterString,
          searchString,
          page,
        }),
      })
        .then((res) => res.json())
        .then((res) =>
          this.setState({ totalOrganizations: res, rerender: false }),
        );
    }
  }

  calculatePages(totalEntries) {
    return Math.ceil(totalEntries / 10);
  }

  calculateTotalDurationPerOrganization(statusLog) {
    let issueOpened = 0;
    let issueRequiresClarification = 0;
    let issueUnderEvaluationTotal = 0;
    let issueConfirmedTotal = 0;
    let issueResolved = 0;
    let enhancementRequested = 0;
    let enhancementUnderDevelopment = 0;

    for (let i = 0; i < statusLog.length; i += 1) {
      if (statusLog[i].statusId.toString() === '1') {
        issueOpened +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60;
      }

      if (statusLog[i].statusId.toString() === '2') {
        issueRequiresClarification +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60;
      }

      if (statusLog[i].statusId.toString() === '3') {
        issueUnderEvaluationTotal +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60;
      }

      if (statusLog[i].statusId.toString() === '4') {
        issueConfirmedTotal +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60;
      }

      if (statusLog[i].statusId.toString() === '5') {
        issueResolved +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60;
      }

      if (statusLog[i].statusId.toString() === '7') {
        enhancementRequested +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60;
      }

      if (statusLog[i].statusId.toString() === '8') {
        enhancementUnderDevelopment +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60;
      }
    }

    return (
      <div className="fade-in">
        <table className="table">
          <tbody>
            <tr>
              <td>
                <i className="red-500 fa fa-square" />
                &ensp;Issue opened:&ensp;
                {issueOpened.toFixed(2)}
                &nbsp;h
              </td>
              <td>
                <i className="orange-500 fa fa-square" />
                &ensp;Issue requires clarification:&ensp;
                {issueRequiresClarification.toFixed(2)}
                &nbsp;h
              </td>
              <td>
                <i className="yellow-500 fa fa-square" />
                &ensp;Issue under evaluation:&ensp;
                {issueUnderEvaluationTotal.toFixed(2)}
                &nbsp;h
              </td>
              <td>
                <i className="green-500 fa fa-square" />
                &ensp;Issue confirmed:&ensp;
                {issueConfirmedTotal.toFixed(2)}
                &nbsp;h
              </td>
              <td>
                <i className="blue-500 fa fa-square" />
                &ensp;Issue resolved:&ensp;
                {issueResolved.toFixed(2)}
                &nbsp;h
              </td>
            </tr>
            <tr>
              <td>
                <i className="indigo-500 fa fa-square" />
                &ensp;Enhancement requested:&ensp;
                {enhancementRequested.toFixed(2)}
                &nbsp;h
              </td>
              <td>
                <i className="purple-500 fa fa-square" />
                &ensp;Enhancement under development:&ensp;
                {enhancementUnderDevelopment.toFixed(2)}
                &nbsp;h
              </td>
            </tr>
            <tr>
              <td>
                <b>Total</b>
              </td>
              <td>
                <b>
                  {(
                    issueOpened +
                    issueRequiresClarification +
                    issueUnderEvaluationTotal +
                    issueConfirmedTotal +
                    issueResolved +
                    enhancementRequested +
                    enhancementUnderDevelopment
                  ).toFixed(2)}
                  &nbsp;h
                </b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  calculateTotalDurationPerProject(statusLog) {
    let issueOpened = 0;
    let issueRequiresClarification = 0;
    let issueUnderEvaluation = 0;
    let issueConfirmed = 0;
    let issueResolved = 0;
    let enhancementRequested = 0;
    let enhancementUnderDevelopment = 0;

    for (let i = 0; i < statusLog.length; i += 1) {
      if (statusLog[i].statusId.toString() === '1') {
        issueOpened +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60;
      }

      if (statusLog[i].statusId.toString() === '2') {
        issueRequiresClarification +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60;
      }

      if (statusLog[i].statusId.toString() === '3') {
        issueUnderEvaluation +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60;
      }

      if (statusLog[i].statusId.toString() === '4') {
        issueConfirmed +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60;
      }

      if (statusLog[i].statusId.toString() === '5') {
        issueResolved +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60;
      }

      if (statusLog[i].statusId.toString() === '7') {
        enhancementRequested +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60;
      }

      if (statusLog[i].statusId.toString() === '8') {
        enhancementUnderDevelopment +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60;
      }
    }

    return (
      <div className="fade-in">
        <table className="table text-secondary">
          <tbody>
            <tr>
              <td>
                <i className="red-500 fa fa-square" />
                &ensp;Issue opened:&ensp;
                {issueOpened.toFixed(2)}
                &nbsp;h
              </td>
              <td>
                <i className="orange-500 fa fa-square" />
                &ensp;Issue requires clarification:&ensp;
                {issueRequiresClarification.toFixed(2)}
                &nbsp;h
              </td>
              <td>
                <i className="yellow-500 fa fa-square" />
                &ensp;Issue under evaluation:&ensp;
                {issueUnderEvaluation.toFixed(2)}
                &nbsp;h
              </td>
              <td>
                <i className="green-500 fa fa-square" />
                &ensp;Issue confirmed:&ensp;
                {issueConfirmed.toFixed(2)}
                &nbsp;h
              </td>
              <td>
                <i className="blue-500 fa fa-square" />
                &ensp;Issue resolved:&ensp;
                {issueResolved.toFixed(2)}
                &nbsp;h
              </td>
            </tr>
            <tr>
              <td>
                <i className="indigo-500 fa fa-square" />
                &ensp;Enhancement requested:&ensp;
                {enhancementRequested.toFixed(2)}
                &nbsp;h
              </td>
              <td>
                <i className="purple-500 fa fa-square" />
                &ensp;Enhancement under development:&ensp;
                {enhancementUnderDevelopment.toFixed(2)}
                &nbsp;h
              </td>
            </tr>
            <tr>
              <td>
                <b>Total</b>
              </td>
              <td>
                <b>
                  {(
                    issueOpened +
                    issueRequiresClarification +
                    issueUnderEvaluation +
                    issueConfirmed +
                    issueResolved +
                    enhancementRequested +
                    enhancementUnderDevelopment
                  ).toFixed(2)}
                  &nbsp;h
                </b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  calculateMonthlyTotalsPerOrganization(year, organizationId, statusLog) {
    const issueOpenedMonthTotals = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const issueRequiresClarificationMonthTotals = [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ];
    const issueUnderEvaluationMonthTotals = [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ];
    const issueConfirmedMonthTotals = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const issueResolvedMonthTotals = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const enhancementRequestedMonthTotals = [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ];
    const enhancementUnderDevelopmentMonthTotals = [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ];

    let issueOpenedActiveMonths = 0;
    let issueRequiresClarificationActiveMonths = 0;
    let issueUnderEvaluationActiveMonths = 0;
    let issueConfirmedActiveMonths = 0;
    let issueResolvedActiveMonths = 0;
    let enhancementRequestedActiveMonths = 0;
    let enhancementUnderDevelopmentActiveMonths = 0;

    let issueOpenedMonthlyAverage = 0;
    let issueRequiresClarificationMonthlyAverage = 0;
    let issueUnderEvaluationMonthlyAverage = 0;
    let issueConfirmedMonthlyAverage = 0;
    let issueResolvedMonthlyAverage = 0;
    let enhancementRequestedMonthlyAverage = 0;
    let enhancementUnderDevelopmentMonthlyAverage = 0;

    let issueOpenedCountJanuary = 0;
    let issueOpenedCountFebruary = 0;
    let issueOpenedCountMarch = 0;
    let issueOpenedCountApril = 0;
    let issueOpenedCountMay = 0;
    let issueOpenedCountJune = 0;
    let issueOpenedCountJuly = 0;
    let issueOpenedCountAugust = 0;
    let issueOpenedCountSeptember = 0;
    let issueOpenedCountOctober = 0;
    let issueOpenedCountNovember = 0;
    let issueOpenedCountDecember = 0;

    let issueRequiresClarificationCountJanuary = 0;
    let issueRequiresClarificationCountFebruary = 0;
    let issueRequiresClarificationCountMarch = 0;
    let issueRequiresClarificationCountApril = 0;
    let issueRequiresClarificationCountMay = 0;
    let issueRequiresClarificationCountJune = 0;
    let issueRequiresClarificationCountJuly = 0;
    let issueRequiresClarificationCountAugust = 0;
    let issueRequiresClarificationCountSeptember = 0;
    let issueRequiresClarificationCountOctober = 0;
    let issueRequiresClarificationCountNovember = 0;
    let issueRequiresClarificationCountDecember = 0;

    let issueUnderEvaluationCountJanuary = 0;
    let issueUnderEvaluationCountFebruary = 0;
    let issueUnderEvaluationCountMarch = 0;
    let issueUnderEvaluationCountApril = 0;
    let issueUnderEvaluationCountMay = 0;
    let issueUnderEvaluationCountJune = 0;
    let issueUnderEvaluationCountJuly = 0;
    let issueUnderEvaluationCountAugust = 0;
    let issueUnderEvaluationCountSeptember = 0;
    let issueUnderEvaluationCountOctober = 0;
    let issueUnderEvaluationCountNovember = 0;
    let issueUnderEvaluationCountDecember = 0;

    let issueConfirmedCountJanuary = 0;
    let issueConfirmedCountFebruary = 0;
    let issueConfirmedCountMarch = 0;
    let issueConfirmedCountApril = 0;
    let issueConfirmedCountMay = 0;
    let issueConfirmedCountJune = 0;
    let issueConfirmedCountJuly = 0;
    let issueConfirmedCountAugust = 0;
    let issueConfirmedCountSeptember = 0;
    let issueConfirmedCountOctober = 0;
    let issueConfirmedCountNovember = 0;
    let issueConfirmedCountDecember = 0;

    let issueResolvedCountJanuary = 0;
    let issueResolvedCountFebruary = 0;
    let issueResolvedCountMarch = 0;
    let issueResolvedCountApril = 0;
    let issueResolvedCountMay = 0;
    let issueResolvedCountJune = 0;
    let issueResolvedCountJuly = 0;
    let issueResolvedCountAugust = 0;
    let issueResolvedCountSeptember = 0;
    let issueResolvedCountOctober = 0;
    let issueResolvedCountNovember = 0;
    let issueResolvedCountDecember = 0;

    let enhancementRequestedCountJanuary = 0;
    let enhancementRequestedCountFebruary = 0;
    let enhancementRequestedCountMarch = 0;
    let enhancementRequestedCountApril = 0;
    let enhancementRequestedCountMay = 0;
    let enhancementRequestedCountJune = 0;
    let enhancementRequestedCountJuly = 0;
    let enhancementRequestedCountAugust = 0;
    let enhancementRequestedCountSeptember = 0;
    let enhancementRequestedCountOctober = 0;
    let enhancementRequestedCountNovember = 0;
    let enhancementRequestedCountDecember = 0;

    let enhancementUnderDevelopmentCountJanuary = 0;
    let enhancementUnderDevelopmentCountFebruary = 0;
    let enhancementUnderDevelopmentCountMarch = 0;
    let enhancementUnderDevelopmentCountApril = 0;
    let enhancementUnderDevelopmentCountMay = 0;
    let enhancementUnderDevelopmentCountJune = 0;
    let enhancementUnderDevelopmentCountJuly = 0;
    let enhancementUnderDevelopmentCountAugust = 0;
    let enhancementUnderDevelopmentCountSeptember = 0;
    let enhancementUnderDevelopmentCountOctober = 0;
    let enhancementUnderDevelopmentCountNovember = 0;
    let enhancementUnderDevelopmentCountDecember = 0;

    for (let i = 0; i < statusLog.length; i += 1) {
      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 0
      ) {
        if (statusLog[i].statusId.toString() === '1') {
          issueOpenedCountJanuary += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 1
      ) {
        if (statusLog[i].statusId.toString() === '1') {
          issueOpenedCountFebruary += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 2
      ) {
        if (statusLog[i].statusId.toString() === '1') {
          issueOpenedCountMarch += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 3
      ) {
        if (statusLog[i].statusId.toString() === '1') {
          issueOpenedCountApril += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 4
      ) {
        if (statusLog[i].statusId.toString() === '1') {
          issueOpenedCountMay += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 5
      ) {
        if (statusLog[i].statusId.toString() === '1') {
          issueOpenedCountJune += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 6
      ) {
        if (statusLog[i].statusId.toString() === '1') {
          issueOpenedCountJuly += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 7
      ) {
        if (statusLog[i].statusId.toString() === '1') {
          issueOpenedCountAugust += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 8
      ) {
        if (statusLog[i].statusId.toString() === '1') {
          issueOpenedCountSeptember += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 9
      ) {
        if (statusLog[i].statusId.toString() === '1') {
          issueOpenedCountOctober += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 10
      ) {
        if (statusLog[i].statusId.toString() === '1') {
          issueOpenedCountNovember += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 11
      ) {
        if (statusLog[i].statusId.toString() === '1') {
          issueOpenedCountDecember += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 0
      ) {
        if (statusLog[i].statusId.toString() === '2') {
          issueRequiresClarificationCountJanuary += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 1
      ) {
        if (statusLog[i].statusId.toString() === '2') {
          issueRequiresClarificationCountFebruary += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 2
      ) {
        if (statusLog[i].statusId.toString() === '2') {
          issueRequiresClarificationCountMarch += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 3
      ) {
        if (statusLog[i].statusId.toString() === '2') {
          issueRequiresClarificationCountApril += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 4
      ) {
        if (statusLog[i].statusId.toString() === '2') {
          issueRequiresClarificationCountMay += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 5
      ) {
        if (statusLog[i].statusId.toString() === '2') {
          issueRequiresClarificationCountJune += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 6
      ) {
        if (statusLog[i].statusId.toString() === '2') {
          issueRequiresClarificationCountJuly += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 7
      ) {
        if (statusLog[i].statusId.toString() === '2') {
          issueRequiresClarificationCountAugust += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 8
      ) {
        if (statusLog[i].statusId.toString() === '2') {
          issueRequiresClarificationCountSeptember += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 9
      ) {
        if (statusLog[i].statusId.toString() === '2') {
          issueRequiresClarificationCountOctober += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 10
      ) {
        if (statusLog[i].statusId.toString() === '2') {
          issueRequiresClarificationCountNovember += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 11
      ) {
        if (statusLog[i].statusId.toString() === '2') {
          issueRequiresClarificationCountDecember += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 0
      ) {
        if (statusLog[i].statusId.toString() === '3') {
          issueUnderEvaluationCountJanuary += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 1
      ) {
        if (statusLog[i].statusId.toString() === '3') {
          issueUnderEvaluationCountFebruary += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 2
      ) {
        if (statusLog[i].statusId.toString() === '3') {
          issueUnderEvaluationCountMarch += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 3
      ) {
        if (statusLog[i].statusId.toString() === '3') {
          issueUnderEvaluationCountApril += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 4
      ) {
        if (statusLog[i].statusId.toString() === '3') {
          issueUnderEvaluationCountMay += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 5
      ) {
        if (statusLog[i].statusId.toString() === '3') {
          issueUnderEvaluationCountJune += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 6
      ) {
        if (statusLog[i].statusId.toString() === '3') {
          issueUnderEvaluationCountJuly += 1;
        }
      }
      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 7
      ) {
        if (statusLog[i].statusId.toString() === '3') {
          issueUnderEvaluationCountAugust += 1;
        }
      }
      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 8
      ) {
        if (statusLog[i].statusId.toString() === '3') {
          issueUnderEvaluationCountSeptember += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 9
      ) {
        if (statusLog[i].statusId.toString() === '3') {
          issueUnderEvaluationCountOctober += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 10
      ) {
        if (statusLog[i].statusId.toString() === '3') {
          issueUnderEvaluationCountNovember += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 11
      ) {
        if (statusLog[i].statusId.toString() === '3') {
          issueUnderEvaluationCountDecember += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 0
      ) {
        if (statusLog[i].statusId.toString() === '4') {
          issueConfirmedCountJanuary += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 1
      ) {
        if (statusLog[i].statusId.toString() === '4') {
          issueConfirmedCountFebruary += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 2
      ) {
        if (statusLog[i].statusId.toString() === '4') {
          issueConfirmedCountMarch += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 3
      ) {
        if (statusLog[i].statusId.toString() === '4') {
          issueConfirmedCountApril += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 4
      ) {
        if (statusLog[i].statusId.toString() === '4') {
          issueConfirmedCountMay += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 5
      ) {
        if (statusLog[i].statusId.toString() === '4') {
          issueConfirmedCountJune += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 6
      ) {
        if (statusLog[i].statusId.toString() === '4') {
          issueConfirmedCountJuly += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 7
      ) {
        if (statusLog[i].statusId.toString() === '4') {
          issueConfirmedCountAugust += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 8
      ) {
        if (statusLog[i].statusId.toString() === '4') {
          issueConfirmedCountSeptember += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 9
      ) {
        if (statusLog[i].statusId.toString() === '4') {
          issueConfirmedCountOctober += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 10
      ) {
        if (statusLog[i].statusId.toString() === '4') {
          issueConfirmedCountNovember += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 11
      ) {
        if (statusLog[i].statusId.toString() === '4') {
          issueConfirmedCountDecember += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 0
      ) {
        if (statusLog[i].statusId.toString() === '5') {
          issueResolvedCountJanuary += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 1
      ) {
        if (statusLog[i].statusId.toString() === '5') {
          issueResolvedCountFebruary += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 2
      ) {
        if (statusLog[i].statusId.toString() === '5') {
          issueResolvedCountMarch += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 3
      ) {
        if (statusLog[i].statusId.toString() === '5') {
          issueResolvedCountApril += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 4
      ) {
        if (statusLog[i].statusId.toString() === '5') {
          issueResolvedCountMay += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 5
      ) {
        if (statusLog[i].statusId.toString() === '5') {
          issueResolvedCountJune += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 6
      ) {
        if (statusLog[i].statusId.toString() === '5') {
          issueResolvedCountJuly += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 7
      ) {
        if (statusLog[i].statusId.toString() === '5') {
          issueResolvedCountAugust += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 8
      ) {
        if (statusLog[i].statusId.toString() === '5') {
          issueResolvedCountSeptember += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 9
      ) {
        if (statusLog[i].statusId.toString() === '5') {
          issueResolvedCountOctober += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 10
      ) {
        if (statusLog[i].statusId.toString() === '5') {
          issueResolvedCountNovember += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 11
      ) {
        if (statusLog[i].statusId.toString() === '5') {
          issueResolvedCountDecember += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 0
      ) {
        if (statusLog[i].statusId.toString() === '7') {
          enhancementRequestedCountJanuary += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 1
      ) {
        if (statusLog[i].statusId.toString() === '7') {
          enhancementRequestedCountFebruary += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 2
      ) {
        if (statusLog[i].statusId.toString() === '7') {
          enhancementRequestedCountMarch += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 3
      ) {
        if (statusLog[i].statusId.toString() === '7') {
          enhancementRequestedCountApril += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 4
      ) {
        if (statusLog[i].statusId.toString() === '7') {
          enhancementRequestedCountMay += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 5
      ) {
        if (statusLog[i].statusId.toString() === '7') {
          enhancementRequestedCountJune += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 6
      ) {
        if (statusLog[i].statusId.toString() === '7') {
          enhancementRequestedCountJuly += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 7
      ) {
        if (statusLog[i].statusId.toString() === '7') {
          enhancementRequestedCountAugust += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 8
      ) {
        if (statusLog[i].statusId.toString() === '7') {
          enhancementRequestedCountSeptember += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 9
      ) {
        if (statusLog[i].statusId.toString() === '7') {
          enhancementRequestedCountOctober += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 10
      ) {
        if (statusLog[i].statusId.toString() === '7') {
          enhancementRequestedCountNovember += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 11
      ) {
        if (statusLog[i].statusId.toString() === '7') {
          enhancementRequestedCountDecember += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 0
      ) {
        if (statusLog[i].statusId.toString() === '8') {
          enhancementUnderDevelopmentCountJanuary += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 1
      ) {
        if (statusLog[i].statusId.toString() === '8') {
          enhancementUnderDevelopmentCountFebruary += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 2
      ) {
        if (statusLog[i].statusId.toString() === '8') {
          enhancementUnderDevelopmentCountMarch += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 3
      ) {
        if (statusLog[i].statusId.toString() === '8') {
          enhancementUnderDevelopmentCountApril += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 4
      ) {
        if (statusLog[i].statusId.toString() === '8') {
          enhancementUnderDevelopmentCountMay += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 5
      ) {
        if (statusLog[i].statusId.toString() === '8') {
          enhancementUnderDevelopmentCountJune += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 6
      ) {
        if (statusLog[i].statusId.toString() === '8') {
          enhancementUnderDevelopmentCountJuly += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 7
      ) {
        if (statusLog[i].statusId.toString() === '8') {
          enhancementUnderDevelopmentCountAugust += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 8
      ) {
        if (statusLog[i].statusId.toString() === '8') {
          enhancementUnderDevelopmentCountSeptember += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 9
      ) {
        if (statusLog[i].statusId.toString() === '8') {
          enhancementUnderDevelopmentCountOctober += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 10
      ) {
        if (statusLog[i].statusId.toString() === '8') {
          enhancementUnderDevelopmentCountNovember += 1;
        }
      }

      if (
        statusLog[i].organizationId === organizationId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 11
      ) {
        if (statusLog[i].statusId.toString() === '8') {
          enhancementUnderDevelopmentCountDecember += 1;
        }
      }
    }

    if (statusLog) {
      for (let i = 0; i < statusLog.length; i += 1) {
        if (
          statusLog[i].organizationId === organizationId &&
          new Date(statusLog[i].dateStarted).getFullYear() === year &&
          new Date(statusLog[i].dateStarted).getMonth() === 0
        ) {
          if (statusLog[i].statusId.toString() === '1') {
            issueOpenedMonthTotals[0] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueOpenedCountJanuary;
          }

          if (statusLog[i].statusId.toString() === '2') {
            issueRequiresClarificationMonthTotals[0] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueRequiresClarificationCountJanuary;
          }

          if (statusLog[i].statusId.toString() === '3') {
            issueUnderEvaluationMonthTotals[0] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueUnderEvaluationCountJanuary;
          }

          if (statusLog[i].statusId.toString() === '4') {
            issueConfirmedMonthTotals[0] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueConfirmedCountJanuary;
          }

          if (statusLog[i].statusId.toString() === '5') {
            issueResolvedMonthTotals[0] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueResolvedCountJanuary;
          }

          if (statusLog[i].statusId.toString() === '7') {
            enhancementRequestedMonthTotals[0] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementRequestedCountJanuary;
          }

          if (statusLog[i].statusId.toString() === '8') {
            enhancementUnderDevelopmentMonthTotals[0] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementUnderDevelopmentCountJanuary;
          }
        }
      }

      for (let i = 0; i < statusLog.length; i += 1) {
        if (
          statusLog[i].organizationId === organizationId &&
          new Date(statusLog[i].dateStarted).getFullYear() === year &&
          new Date(statusLog[i].dateStarted).getMonth() === 1
        ) {
          if (statusLog[i].statusId.toString() === '1') {
            issueOpenedMonthTotals[1] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueOpenedCountFebruary;
          }

          if (statusLog[i].statusId.toString() === '2') {
            issueRequiresClarificationMonthTotals[1] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueRequiresClarificationCountFebruary;
          }

          if (statusLog[i].statusId.toString() === '3') {
            issueUnderEvaluationMonthTotals[1] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueUnderEvaluationCountFebruary;
          }

          if (statusLog[i].statusId.toString() === '4') {
            issueConfirmedMonthTotals[1] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueConfirmedCountFebruary;
          }

          if (statusLog[i].statusId.toString() === '5') {
            issueResolvedMonthTotals[1] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueResolvedCountFebruary;
          }

          if (statusLog[i].statusId.toString() === '7') {
            enhancementRequestedMonthTotals[1] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementRequestedCountFebruary;
          }

          if (statusLog[i].statusId.toString() === '8') {
            enhancementUnderDevelopmentMonthTotals[1] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementUnderDevelopmentCountFebruary;
          }
        }
      }

      for (let i = 0; i < statusLog.length; i += 1) {
        if (
          statusLog[i].organizationId === organizationId &&
          new Date(statusLog[i].dateStarted).getFullYear() === year &&
          new Date(statusLog[i].dateStarted).getMonth() === 2
        ) {
          if (statusLog[i].statusId.toString() === '1') {
            issueOpenedMonthTotals[2] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueOpenedCountMarch;
          }

          if (statusLog[i].statusId.toString() === '2') {
            issueRequiresClarificationMonthTotals[2] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueRequiresClarificationCountMarch;
          }

          if (statusLog[i].statusId.toString() === '3') {
            issueUnderEvaluationMonthTotals[2] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueUnderEvaluationCountMarch;
          }

          if (statusLog[i].statusId.toString() === '4') {
            issueConfirmedMonthTotals[2] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueConfirmedCountMarch;
          }

          if (statusLog[i].statusId.toString() === '5') {
            issueResolvedMonthTotals[2] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueResolvedCountMarch;
          }

          if (statusLog[i].statusId.toString() === '7') {
            enhancementRequestedMonthTotals[2] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementRequestedCountMarch;
          }

          if (statusLog[i].statusId.toString() === '8') {
            enhancementUnderDevelopmentMonthTotals[2] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementUnderDevelopmentCountMarch;
          }
        }
      }

      for (let i = 0; i < statusLog.length; i += 1) {
        if (
          statusLog[i].organizationId === organizationId &&
          new Date(statusLog[i].dateStarted).getFullYear() === year &&
          new Date(statusLog[i].dateStarted).getMonth() === 3
        ) {
          if (statusLog[i].statusId.toString() === '1') {
            issueOpenedMonthTotals[3] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueOpenedCountApril;
          }

          if (statusLog[i].statusId.toString() === '2') {
            issueRequiresClarificationMonthTotals[3] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueRequiresClarificationCountApril;
          }

          if (statusLog[i].statusId.toString() === '3') {
            issueUnderEvaluationMonthTotals[3] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueUnderEvaluationCountApril;
          }

          if (statusLog[i].statusId.toString() === '4') {
            issueConfirmedMonthTotals[3] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueConfirmedCountApril;
          }

          if (statusLog[i].statusId.toString() === '5') {
            issueResolvedMonthTotals[3] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueResolvedCountApril;
          }

          if (statusLog[i].statusId.toString() === '7') {
            enhancementRequestedMonthTotals[3] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementRequestedCountApril;
          }

          if (statusLog[i].statusId.toString() === '8') {
            enhancementUnderDevelopmentMonthTotals[3] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementUnderDevelopmentCountApril;
          }
        }
      }

      for (let i = 0; i < statusLog.length; i += 1) {
        if (
          statusLog[i].organizationId === organizationId &&
          new Date(statusLog[i].dateStarted).getFullYear() === year &&
          new Date(statusLog[i].dateStarted).getMonth() === 4
        ) {
          if (statusLog[i].statusId.toString() === '1') {
            issueOpenedMonthTotals[4] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueOpenedCountMay;
          }

          if (statusLog[i].statusId.toString() === '2') {
            issueRequiresClarificationMonthTotals[4] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueRequiresClarificationCountMay;
          }

          if (statusLog[i].statusId.toString() === '3') {
            issueUnderEvaluationMonthTotals[4] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueUnderEvaluationCountMay;
          }

          if (statusLog[i].statusId.toString() === '4') {
            issueConfirmedMonthTotals[4] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueConfirmedCountMay;
          }

          if (statusLog[i].statusId.toString() === '5') {
            issueResolvedMonthTotals[4] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueResolvedCountMay;
          }

          if (statusLog[i].statusId.toString() === '7') {
            enhancementRequestedMonthTotals[4] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementRequestedCountMay;
          }

          if (statusLog[i].statusId.toString() === '8') {
            enhancementUnderDevelopmentMonthTotals[4] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementUnderDevelopmentCountMay;
          }
        }
      }

      for (let i = 0; i < statusLog.length; i += 1) {
        if (
          statusLog[i].organizationId === organizationId &&
          new Date(statusLog[i].dateStarted).getFullYear() === year &&
          new Date(statusLog[i].dateStarted).getMonth() === 5
        ) {
          if (statusLog[i].statusId.toString() === '1') {
            issueOpenedMonthTotals[5] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueOpenedCountJune;
          }

          if (statusLog[i].statusId.toString() === '2') {
            issueRequiresClarificationMonthTotals[5] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueRequiresClarificationCountJune;
          }

          if (statusLog[i].statusId.toString() === '3') {
            issueUnderEvaluationMonthTotals[5] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueUnderEvaluationCountJune;
          }

          if (statusLog[i].statusId.toString() === '4') {
            issueConfirmedMonthTotals[5] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueConfirmedCountJune;
          }

          if (statusLog[i].statusId.toString() === '5') {
            issueResolvedMonthTotals[5] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueResolvedCountJune;
          }

          if (statusLog[i].statusId.toString() === '7') {
            enhancementRequestedMonthTotals[5] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementRequestedCountJune;
          }

          if (statusLog[i].statusId.toString() === '8') {
            enhancementUnderDevelopmentMonthTotals[5] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementUnderDevelopmentCountJune;
          }
        }
      }

      for (let i = 0; i < statusLog.length; i += 1) {
        if (
          statusLog[i].organizationId === organizationId &&
          new Date(statusLog[i].dateStarted).getFullYear() === year &&
          new Date(statusLog[i].dateStarted).getMonth() === 6
        ) {
          if (statusLog[i].statusId.toString() === '1') {
            issueOpenedMonthTotals[6] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueOpenedCountJuly;
          }

          if (statusLog[i].statusId.toString() === '2') {
            issueRequiresClarificationMonthTotals[6] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueRequiresClarificationCountJuly;
          }

          if (statusLog[i].statusId.toString() === '3') {
            issueUnderEvaluationMonthTotals[6] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueUnderEvaluationCountJuly;
          }

          if (statusLog[i].statusId.toString() === '4') {
            issueConfirmedMonthTotals[6] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueConfirmedCountJuly;
          }

          if (statusLog[i].statusId.toString() === '5') {
            issueResolvedMonthTotals[6] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueResolvedCountJuly;
          }

          if (statusLog[i].statusId.toString() === '7') {
            enhancementRequestedMonthTotals[6] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementRequestedCountJuly;
          }

          if (statusLog[i].statusId.toString() === '8') {
            enhancementUnderDevelopmentMonthTotals[6] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementUnderDevelopmentCountJuly;
          }
        }
      }

      for (let i = 0; i < statusLog.length; i += 1) {
        if (
          statusLog[i].organizationId === organizationId &&
          new Date(statusLog[i].dateStarted).getFullYear() === year &&
          new Date(statusLog[i].dateStarted).getMonth() === 7
        ) {
          if (statusLog[i].statusId.toString() === '1') {
            issueOpenedMonthTotals[7] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueOpenedCountAugust;
          }

          if (statusLog[i].statusId.toString() === '2') {
            issueRequiresClarificationMonthTotals[7] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueRequiresClarificationCountAugust;
          }

          if (statusLog[i].statusId.toString() === '3') {
            issueUnderEvaluationMonthTotals[7] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueUnderEvaluationCountAugust;
          }

          if (statusLog[i].statusId.toString() === '4') {
            issueConfirmedMonthTotals[7] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueConfirmedCountAugust;
          }

          if (statusLog[i].statusId.toString() === '5') {
            issueResolvedMonthTotals[7] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueResolvedCountAugust;
          }

          if (statusLog[i].statusId.toString() === '7') {
            enhancementRequestedMonthTotals[7] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementRequestedCountAugust;
          }

          if (statusLog[i].statusId.toString() === '8') {
            enhancementUnderDevelopmentMonthTotals[7] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementUnderDevelopmentCountAugust;
          }
        }
      }

      for (let i = 0; i < statusLog.length; i += 1) {
        if (
          statusLog[i].organizationId === organizationId &&
          new Date(statusLog[i].dateStarted).getFullYear() === year &&
          new Date(statusLog[i].dateStarted).getMonth() === 8
        ) {
          if (statusLog[i].statusId.toString() === '1') {
            issueOpenedMonthTotals[8] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueOpenedCountSeptember;
          }

          if (statusLog[i].statusId.toString() === '2') {
            issueRequiresClarificationMonthTotals[8] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueRequiresClarificationCountSeptember;
          }

          if (statusLog[i].statusId.toString() === '3') {
            issueUnderEvaluationMonthTotals[8] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueUnderEvaluationCountSeptember;
          }

          if (statusLog[i].statusId.toString() === '4') {
            issueConfirmedMonthTotals[8] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueConfirmedCountSeptember;
          }

          if (statusLog[i].statusId.toString() === '5') {
            issueResolvedMonthTotals[8] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueResolvedCountSeptember;
          }

          if (statusLog[i].statusId.toString() === '7') {
            enhancementRequestedMonthTotals[8] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementRequestedCountSeptember;
          }

          if (statusLog[i].statusId.toString() === '8') {
            enhancementUnderDevelopmentMonthTotals[8] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementUnderDevelopmentCountSeptember;
          }
        }
      }

      for (let i = 0; i < statusLog.length; i += 1) {
        if (
          statusLog[i].organizationId === organizationId &&
          new Date(statusLog[i].dateStarted).getFullYear() === year &&
          new Date(statusLog[i].dateStarted).getMonth() === 9
        ) {
          if (statusLog[i].statusId.toString() === '1') {
            issueOpenedMonthTotals[9] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueOpenedCountOctober;
          }

          if (statusLog[i].statusId.toString() === '2') {
            issueRequiresClarificationMonthTotals[9] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueRequiresClarificationCountOctober;
          }

          if (statusLog[i].statusId.toString() === '3') {
            issueUnderEvaluationMonthTotals[9] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueUnderEvaluationCountOctober;
          }

          if (statusLog[i].statusId.toString() === '4') {
            issueConfirmedMonthTotals[9] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueConfirmedCountOctober;
          }

          if (statusLog[i].statusId.toString() === '5') {
            issueResolvedMonthTotals[9] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueResolvedCountOctober;
          }

          if (statusLog[i].statusId.toString() === '7') {
            enhancementRequestedMonthTotals[9] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementRequestedCountOctober;
          }

          if (statusLog[i].statusId.toString() === '8') {
            enhancementUnderDevelopmentMonthTotals[9] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementUnderDevelopmentCountOctober;
          }
        }
      }

      for (let i = 0; i < statusLog.length; i += 1) {
        if (
          statusLog[i].organizationId === organizationId &&
          new Date(statusLog[i].dateStarted).getFullYear() === year &&
          new Date(statusLog[i].dateStarted).getMonth() === 10
        ) {
          if (statusLog[i].statusId.toString() === '1') {
            issueOpenedMonthTotals[10] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueOpenedCountNovember;
          }

          if (statusLog[i].statusId.toString() === '2') {
            issueRequiresClarificationMonthTotals[10] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueRequiresClarificationCountNovember;
          }

          if (statusLog[i].statusId.toString() === '3') {
            issueUnderEvaluationMonthTotals[10] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueUnderEvaluationCountNovember;
          }

          if (statusLog[i].statusId.toString() === '4') {
            issueConfirmedMonthTotals[10] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueConfirmedCountNovember;
          }

          if (statusLog[i].statusId.toString() === '5') {
            issueResolvedMonthTotals[10] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueResolvedCountNovember;
          }

          if (statusLog[i].statusId.toString() === '7') {
            enhancementRequestedMonthTotals[10] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementRequestedCountNovember;
          }

          if (statusLog[i].statusId.toString() === '8') {
            enhancementUnderDevelopmentMonthTotals[10] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementUnderDevelopmentCountNovember;
          }
        }
      }

      for (let i = 0; i < statusLog.length; i += 1) {
        if (
          statusLog[i].organizationId === organizationId &&
          new Date(statusLog[i].dateStarted).getFullYear() === year &&
          new Date(statusLog[i].dateStarted).getMonth() === 11
        ) {
          if (statusLog[i].statusId.toString() === '1') {
            issueOpenedMonthTotals[11] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueOpenedCountDecember;
          }

          if (statusLog[i].statusId.toString() === '2') {
            issueRequiresClarificationMonthTotals[11] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueRequiresClarificationCountDecember;
          }

          if (statusLog[i].statusId.toString() === '3') {
            issueUnderEvaluationMonthTotals[11] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueUnderEvaluationCountDecember;
          }

          if (statusLog[i].statusId.toString() === '4') {
            issueConfirmedMonthTotals[11] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueConfirmedCountDecember;
          }

          if (statusLog[i].statusId.toString() === '5') {
            issueResolvedMonthTotals[11] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueResolvedCountDecember;
          }

          if (statusLog[i].statusId.toString() === '7') {
            enhancementRequestedMonthTotals[11] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementRequestedCountDecember;
          }

          if (statusLog[i].statusId.toString() === '8') {
            enhancementUnderDevelopmentMonthTotals[11] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementUnderDevelopmentCountDecember;
          }
        }
      }
    }

    // Issue opened monthly average

    for (let i = 0; i < issueOpenedMonthTotals.length; i += 1) {
      if (issueOpenedMonthTotals[i] > 0) {
        issueOpenedActiveMonths += 1;
      }
    }

    if (issueOpenedActiveMonths > 0) {
      let issueOpenedYearTotal = 0;

      for (let i = 0; i < issueOpenedMonthTotals.length; i += 1) {
        issueOpenedYearTotal += issueOpenedMonthTotals[i];
      }

      if (issueOpenedYearTotal % issueOpenedActiveMonths === 0) {
        issueOpenedMonthlyAverage =
          issueOpenedYearTotal / issueOpenedActiveMonths;
      } else {
        issueOpenedMonthlyAverage = (
          issueOpenedYearTotal / issueOpenedActiveMonths
        ).toFixed(2);
      }
    } else {
      issueOpenedActiveMonths = 0;
    }

    // Issue requires clarification monthly average

    for (let i = 0; i < issueRequiresClarificationMonthTotals.length; i += 1) {
      if (issueRequiresClarificationMonthTotals[i] > 0) {
        issueRequiresClarificationActiveMonths += 1;
      }
    }

    if (issueRequiresClarificationActiveMonths > 0) {
      let issueRequiresClarificationYearTotal = 0;

      for (
        let i = 0;
        i < issueRequiresClarificationMonthTotals.length;
        i += 1
      ) {
        issueRequiresClarificationYearTotal +=
          issueRequiresClarificationMonthTotals[i];
      }

      if (
        issueRequiresClarificationYearTotal %
          issueRequiresClarificationActiveMonths ===
        0
      ) {
        issueRequiresClarificationMonthlyAverage =
          issueRequiresClarificationYearTotal /
          issueRequiresClarificationActiveMonths;
      } else {
        issueRequiresClarificationMonthlyAverage = (
          issueRequiresClarificationYearTotal /
          issueRequiresClarificationActiveMonths
        ).toFixed(2);
      }
    } else {
      issueRequiresClarificationActiveMonths = 0;
    }

    // Issue under evaluation monthly average

    for (let i = 0; i < issueUnderEvaluationMonthTotals.length; i += 1) {
      if (issueUnderEvaluationMonthTotals[i] > 0) {
        issueUnderEvaluationActiveMonths += 1;
      }
    }

    if (issueUnderEvaluationActiveMonths > 0) {
      let issueUnderEvaluationYearTotal = 0;

      for (let i = 0; i < issueUnderEvaluationMonthTotals.length; i += 1) {
        issueUnderEvaluationYearTotal += issueUnderEvaluationMonthTotals[i];
      }

      if (
        issueUnderEvaluationYearTotal % issueUnderEvaluationActiveMonths ===
        0
      ) {
        issueUnderEvaluationMonthlyAverage =
          issueUnderEvaluationYearTotal / issueUnderEvaluationActiveMonths;
      } else {
        issueUnderEvaluationMonthlyAverage = (
          issueUnderEvaluationYearTotal / issueUnderEvaluationActiveMonths
        ).toFixed(2);
      }
    } else {
      issueUnderEvaluationActiveMonths = 0;
    }

    // Issue confirmed monthly average

    for (let i = 0; i < issueConfirmedMonthTotals.length; i += 1) {
      if (issueConfirmedMonthTotals[i] > 0) {
        issueConfirmedActiveMonths += 1;
      }
    }

    if (issueConfirmedActiveMonths > 0) {
      let issueConfirmedYearTotal = 0;

      for (let i = 0; i < issueConfirmedMonthTotals.length; i += 1) {
        issueConfirmedYearTotal += issueConfirmedMonthTotals[i];
      }

      if (issueConfirmedYearTotal % issueConfirmedActiveMonths === 0) {
        issueConfirmedMonthlyAverage =
          issueConfirmedYearTotal / issueConfirmedActiveMonths;
      } else {
        issueConfirmedMonthlyAverage = (
          issueConfirmedYearTotal / issueConfirmedActiveMonths
        ).toFixed(2);
      }
    } else {
      issueConfirmedActiveMonths = 0;
    }

    // Issue resolved monthly average

    for (let i = 0; i < issueResolvedMonthTotals.length; i += 1) {
      if (issueResolvedMonthTotals[i] > 0) {
        issueResolvedActiveMonths += 1;
      }
    }

    if (issueResolvedActiveMonths > 0) {
      let issueResolvedYearTotal = 0;

      for (let i = 0; i < issueResolvedMonthTotals.length; i += 1) {
        issueResolvedYearTotal += issueResolvedMonthTotals[i];
      }

      if (issueResolvedYearTotal % issueResolvedActiveMonths === 0) {
        issueResolvedMonthlyAverage =
          issueResolvedYearTotal / issueResolvedActiveMonths;
      } else {
        issueResolvedMonthlyAverage = (
          issueResolvedYearTotal / issueResolvedActiveMonths
        ).toFixed(2);
      }
    } else {
      issueResolvedActiveMonths = 0;
    }

    // Enhancement requested monthly average

    for (let i = 0; i < enhancementRequestedMonthTotals.length; i += 1) {
      if (enhancementRequestedMonthTotals[i] > 0) {
        enhancementRequestedActiveMonths += 1;
      }
    }

    if (enhancementRequestedActiveMonths > 0) {
      let enhancementRequestedYearTotal = 0;

      for (let i = 0; i < enhancementRequestedMonthTotals.length; i += 1) {
        enhancementRequestedYearTotal += enhancementRequestedMonthTotals[i];
      }

      if (
        enhancementRequestedYearTotal % enhancementRequestedActiveMonths ===
        0
      ) {
        enhancementRequestedMonthlyAverage =
          enhancementRequestedYearTotal / enhancementRequestedActiveMonths;
      } else {
        enhancementRequestedMonthlyAverage = (
          enhancementRequestedYearTotal / enhancementRequestedActiveMonths
        ).toFixed(2);
      }
    } else {
      enhancementRequestedActiveMonths = 0;
    }

    // Enhancement under development monthly average

    for (let i = 0; i < enhancementUnderDevelopmentMonthTotals.length; i += 1) {
      if (enhancementUnderDevelopmentMonthTotals[i] > 0) {
        enhancementUnderDevelopmentActiveMonths += 1;
      }
    }

    if (enhancementUnderDevelopmentActiveMonths > 0) {
      let enhancementUnderDevelopmentYearTotal = 0;

      for (
        let i = 0;
        i < enhancementUnderDevelopmentMonthTotals.length;
        i += 1
      ) {
        enhancementUnderDevelopmentYearTotal +=
          enhancementUnderDevelopmentMonthTotals[i];
      }

      if (
        enhancementUnderDevelopmentYearTotal %
          enhancementUnderDevelopmentActiveMonths ===
        0
      ) {
        enhancementUnderDevelopmentMonthlyAverage =
          enhancementUnderDevelopmentYearTotal /
          enhancementUnderDevelopmentActiveMonths;
      } else {
        enhancementUnderDevelopmentMonthlyAverage = (
          enhancementUnderDevelopmentYearTotal /
          enhancementUnderDevelopmentActiveMonths
        ).toFixed(2);
      }
    } else {
      enhancementUnderDevelopmentActiveMonths = 0;
    }

    return (
      <div>
        <table className="organization-status-year-table table">
          <tbody>
            <tr>
              <td>
                {issueOpenedActiveMonths === 1 ? (
                  <div>
                    <i className="red-500 fa fa-square" />
                    &ensp;Issue opened: waiting for agent response
                  </div>
                ) : (
                  <div>
                    <i className="red-500 fa fa-square" />
                    &ensp;Issue opened: waiting for agent response
                  </div>
                )}
                {issueRequiresClarificationActiveMonths === 1 ? (
                  <div>
                    <i className="orange-500 fa fa-square" />
                    &ensp;Issue requries clarification: waiting for client
                    response
                  </div>
                ) : (
                  <div>
                    <i className="orange-500 fa fa-square" />
                    &ensp;Issue requries clarification: waiting for client
                    response
                  </div>
                )}
                {issueUnderEvaluationActiveMonths === 1 ? (
                  <div>
                    <i className="yellow-500 fa fa-square" />
                    &ensp;Issue under evaluation: waiting for developer feedback
                  </div>
                ) : (
                  <div>
                    <i className="yellow-500 fa fa-square" />
                    &ensp;Issue under evaluation: waiting for developer feedback
                  </div>
                )}
                {issueConfirmedActiveMonths === 1 ? (
                  <div>
                    <i className="green-500 fa fa-square" />
                    &ensp;Issue confirmed: waiting for developer patch
                  </div>
                ) : (
                  <div>
                    <i className="green-500 fa fa-square" />
                    &ensp;Issue confirmed: waiting for developer patch
                  </div>
                )}
                {issueResolvedActiveMonths === 1 ? (
                  <div>
                    <i className="blue-500 fa fa-square" />
                    &ensp;Issue resolved: waiting for client confirmation
                  </div>
                ) : (
                  <div>
                    <i className="blue-500 fa fa-square" />
                    &ensp;Issue resolved: waiting for client confirmation
                  </div>
                )}
                {enhancementRequestedActiveMonths === 1 ? (
                  <div>
                    <i className="indigo-500 fa fa-square" />
                    &ensp;Enhancement requested: waiting for developer
                    confirmation
                  </div>
                ) : (
                  <div>
                    <i className="indigo-500 fa fa-square" />
                    &ensp;Enhancement requested: waiting for developer
                    confirmation
                  </div>
                )}
                {enhancementUnderDevelopmentActiveMonths === 1 ? (
                  <div>
                    <i className="purple-500 fa fa-square" />
                    &ensp;Enhancement under development: waiting for developer
                    patch
                  </div>
                ) : (
                  <div>
                    <i className="purple-500 fa fa-square" />
                    &ensp;Enhancement under development: waiting for developer
                    patch
                  </div>
                )}
              </td>
              <td>
                {issueOpenedActiveMonths === 1 ? (
                  <div className="text-right">
                    <b>
                      {Math.round(issueOpenedMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {issueOpenedActiveMonths}
                    &nbsp;month)
                  </div>
                ) : (
                  <div className="text-right">
                    <b>
                      {Math.round(issueOpenedMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {issueOpenedActiveMonths}
                    &nbsp;months)
                  </div>
                )}
                {issueRequiresClarificationActiveMonths === 1 ? (
                  <div className="text-right">
                    <b>
                      {Math.round(issueRequiresClarificationMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {issueRequiresClarificationActiveMonths}
                    &nbsp;month)
                  </div>
                ) : (
                  <div className="text-right">
                    <b>
                      {Math.round(issueRequiresClarificationMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {issueRequiresClarificationActiveMonths}
                    &nbsp;months)
                  </div>
                )}
                {issueUnderEvaluationActiveMonths === 1 ? (
                  <div className="text-right">
                    <b>
                      {Math.round(issueUnderEvaluationMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {issueUnderEvaluationActiveMonths}
                    &nbsp;month)
                  </div>
                ) : (
                  <div className="text-right">
                    <b>
                      {Math.round(issueUnderEvaluationMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {issueUnderEvaluationActiveMonths}
                    &nbsp;months)
                  </div>
                )}
                {issueConfirmedActiveMonths === 1 ? (
                  <div className="text-right">
                    <b>
                      {Math.round(issueConfirmedMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {issueConfirmedActiveMonths}
                    &nbsp;month)
                  </div>
                ) : (
                  <div className="text-right">
                    <b>
                      {Math.round(issueConfirmedMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {issueConfirmedActiveMonths}
                    &nbsp;months)
                  </div>
                )}
                {issueResolvedActiveMonths === 1 ? (
                  <div className="text-right">
                    <b>
                      {Math.round(issueResolvedMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {issueResolvedActiveMonths}
                    &nbsp;month)
                  </div>
                ) : (
                  <div className="text-right">
                    <b>
                      {Math.round(issueResolvedMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {issueResolvedActiveMonths}
                    &nbsp;months)
                  </div>
                )}
                {enhancementRequestedActiveMonths === 1 ? (
                  <div className="text-right">
                    <b>
                      {Math.round(enhancementRequestedMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {enhancementRequestedActiveMonths}
                    &nbsp;month)
                  </div>
                ) : (
                  <div className="text-right">
                    <b>
                      {Math.round(enhancementRequestedMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {enhancementRequestedActiveMonths}
                    &nbsp;months)
                  </div>
                )}
                {enhancementUnderDevelopmentActiveMonths === 1 ? (
                  <div className="text-right">
                    <b>
                      {Math.round(enhancementUnderDevelopmentMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {enhancementUnderDevelopmentActiveMonths}
                    &nbsp;month)
                  </div>
                ) : (
                  <div className="text-right">
                    <b>
                      {Math.round(enhancementUnderDevelopmentMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {enhancementUnderDevelopmentActiveMonths}
                    &nbsp;months)
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <table className="organization-status-month-table table">
          <tbody>
            <tr>
              <td>
                <br />
                <div>
                  <i className="red-500 fa fa-square" />
                  &ensp;Issue opened: waiting for agent response
                </div>
                <div>
                  <i className="orange-500 fa fa-square" />
                  &ensp;Issue requires clarification: waiting for client
                  response
                </div>
                <div>
                  <i className="yellow-500 fa fa-square" />
                  &ensp;Issue under evaluation: waiting for developer feedback
                </div>
                <div>
                  <i className="green-500 fa fa-square" />
                  &ensp;Issue confirmed: waiting for developer patch
                </div>
                <div>
                  <i className="blue-500 fa fa-square" />
                  &ensp;Issue resolved: waiting for client confirmation
                </div>
                <div>
                  <i className="indigo-500 fa fa-square" />
                  &ensp;Enhancement requested: waiting for developer
                  confirmation
                </div>
                <div>
                  <i className="purple-500 fa fa-square" />
                  &ensp;Enhancement under development: waiting for developer
                  patch
                </div>
              </td>
              <td>
                <div className="text-right">Jan</div>
                <div className="text-right">
                  {Math.round(issueOpenedMonthTotals[0])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueRequiresClarificationMonthTotals[0])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueUnderEvaluationMonthTotals[0])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueConfirmedMonthTotals[0])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueResolvedMonthTotals[0])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementRequestedMonthTotals[0])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementUnderDevelopmentMonthTotals[0])}
                  &nbsp;h
                </div>
              </td>
              <td>
                <div className="text-right">Feb</div>
                <div className="text-right">
                  {Math.round(issueOpenedMonthTotals[1])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueRequiresClarificationMonthTotals[1])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueUnderEvaluationMonthTotals[1])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueConfirmedMonthTotals[1])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueResolvedMonthTotals[1])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementRequestedMonthTotals[1])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementUnderDevelopmentMonthTotals[1])}
                  &nbsp;h
                </div>
              </td>
              <td>
                <div className="text-right">Mar</div>
                <div className="text-right">
                  {Math.round(issueOpenedMonthTotals[2])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueRequiresClarificationMonthTotals[2])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueUnderEvaluationMonthTotals[2])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueConfirmedMonthTotals[2])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueResolvedMonthTotals[2])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementRequestedMonthTotals[2])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementUnderDevelopmentMonthTotals[2])}
                  &nbsp;h
                </div>
              </td>
              <td>
                <div className="text-right">Apr</div>
                <div className="text-right">
                  {Math.round(issueOpenedMonthTotals[3])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueRequiresClarificationMonthTotals[3])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueUnderEvaluationMonthTotals[3])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueConfirmedMonthTotals[3])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueResolvedMonthTotals[3])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementRequestedMonthTotals[3])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementUnderDevelopmentMonthTotals[3])}
                  &nbsp;h
                </div>
              </td>
              <td>
                <div className="text-right">May</div>
                <div className="text-right">
                  {Math.round(issueOpenedMonthTotals[4])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueRequiresClarificationMonthTotals[4])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueUnderEvaluationMonthTotals[4])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueConfirmedMonthTotals[4])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueResolvedMonthTotals[4])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementRequestedMonthTotals[4])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementUnderDevelopmentMonthTotals[4])}
                  &nbsp;h
                </div>
              </td>
              <td>
                <div className="text-right">Jun</div>
                <div className="text-right">
                  {Math.round(issueOpenedMonthTotals[5])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueRequiresClarificationMonthTotals[5])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueUnderEvaluationMonthTotals[5])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueConfirmedMonthTotals[5])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueResolvedMonthTotals[5])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementRequestedMonthTotals[5])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementUnderDevelopmentMonthTotals[5])}
                  &nbsp;h
                </div>
              </td>
              <td>
                <div className="text-right">Jul</div>
                <div className="text-right">
                  {Math.round(issueOpenedMonthTotals[6])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueRequiresClarificationMonthTotals[6])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueUnderEvaluationMonthTotals[6])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueConfirmedMonthTotals[6])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueResolvedMonthTotals[6])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementRequestedMonthTotals[6])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementUnderDevelopmentMonthTotals[6])}
                  &nbsp;h
                </div>
              </td>
              <td>
                <div className="text-right">Aug</div>
                <div className="text-right">
                  {Math.round(issueOpenedMonthTotals[7])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueRequiresClarificationMonthTotals[7])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueUnderEvaluationMonthTotals[7])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueConfirmedMonthTotals[7])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueResolvedMonthTotals[7])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementRequestedMonthTotals[7])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementUnderDevelopmentMonthTotals[7])}
                  &nbsp;h
                </div>
              </td>
              <td>
                <div className="text-right">Sep</div>
                <div className="text-right">
                  {Math.round(issueOpenedMonthTotals[8])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueRequiresClarificationMonthTotals[8])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueUnderEvaluationMonthTotals[8])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueConfirmedMonthTotals[8])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueResolvedMonthTotals[8])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementRequestedMonthTotals[8])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementUnderDevelopmentMonthTotals[8])}
                  &nbsp;h
                </div>
              </td>
              <td>
                <div className="text-right">Oct</div>
                <div className="text-right">
                  {Math.round(issueOpenedMonthTotals[9])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueRequiresClarificationMonthTotals[9])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueUnderEvaluationMonthTotals[9])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueConfirmedMonthTotals[9])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueResolvedMonthTotals[9])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementRequestedMonthTotals[9])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementUnderDevelopmentMonthTotals[9])}
                  &nbsp;h
                </div>
              </td>
              <td>
                <div className="text-right">Nov</div>
                <div className="text-right">
                  {Math.round(issueOpenedMonthTotals[10])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueRequiresClarificationMonthTotals[10])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueUnderEvaluationMonthTotals[10])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueConfirmedMonthTotals[10])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueResolvedMonthTotals[10])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementRequestedMonthTotals[10])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementUnderDevelopmentMonthTotals[10])}
                  &nbsp;h
                </div>
              </td>
              <td>
                <div className="text-right">Dec</div>
                <div className="text-right">
                  {Math.round(issueOpenedMonthTotals[11])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueRequiresClarificationMonthTotals[11])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueUnderEvaluationMonthTotals[11])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueConfirmedMonthTotals[11])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueResolvedMonthTotals[11])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementRequestedMonthTotals[11])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementUnderDevelopmentMonthTotals[11])}
                  &nbsp;h
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  calculateMonthlyTotalsPerProject(year, projectId, statusLog) {
    const issueOpenedMonthTotals = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const issueRequiresClarificationMonthTotals = [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ];
    const issueUnderEvaluationMonthTotals = [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ];
    const issueConfirmedMonthTotals = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const issueResolvedMonthTotals = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const enhancementRequestedMonthTotals = [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ];
    const enhancementUnderDevelopmentMonthTotals = [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ];

    let issueOpenedActiveMonths = 0;
    let issueRequiresClarificationActiveMonths = 0;
    let issueUnderEvaluationActiveMonths = 0;
    let issueConfirmedActiveMonths = 0;
    let issueResolvedActiveMonths = 0;
    let enhancementRequestedActiveMonths = 0;
    let enhancementUnderDevelopmentActiveMonths = 0;

    let issueOpenedMonthlyAverage = 0;
    let issueRequiresClarificationMonthlyAverage = 0;
    let issueUnderEvaluationMonthlyAverage = 0;
    let issueConfirmedMonthlyAverage = 0;
    let issueResolvedMonthlyAverage = 0;
    let enhancementRequestedMonthlyAverage = 0;
    let enhancementUnderDevelopmentMonthlyAverage = 0;

    let issueOpenedCountJanuary = 0;
    let issueOpenedCountFebruary = 0;
    let issueOpenedCountMarch = 0;
    let issueOpenedCountApril = 0;
    let issueOpenedCountMay = 0;
    let issueOpenedCountJune = 0;
    let issueOpenedCountJuly = 0;
    let issueOpenedCountAugust = 0;
    let issueOpenedCountSeptember = 0;
    let issueOpenedCountOctober = 0;
    let issueOpenedCountNovember = 0;
    let issueOpenedCountDecember = 0;

    let issueRequiresClarificationCountJanuary = 0;
    let issueRequiresClarificationCountFebruary = 0;
    let issueRequiresClarificationCountMarch = 0;
    let issueRequiresClarificationCountApril = 0;
    let issueRequiresClarificationCountMay = 0;
    let issueRequiresClarificationCountJune = 0;
    let issueRequiresClarificationCountJuly = 0;
    let issueRequiresClarificationCountAugust = 0;
    let issueRequiresClarificationCountSeptember = 0;
    let issueRequiresClarificationCountOctober = 0;
    let issueRequiresClarificationCountNovember = 0;
    let issueRequiresClarificationCountDecember = 0;

    let issueUnderEvaluationCountJanuary = 0;
    let issueUnderEvaluationCountFebruary = 0;
    let issueUnderEvaluationCountMarch = 0;
    let issueUnderEvaluationCountApril = 0;
    let issueUnderEvaluationCountMay = 0;
    let issueUnderEvaluationCountJune = 0;
    let issueUnderEvaluationCountJuly = 0;
    let issueUnderEvaluationCountAugust = 0;
    let issueUnderEvaluationCountSeptember = 0;
    let issueUnderEvaluationCountOctober = 0;
    let issueUnderEvaluationCountNovember = 0;
    let issueUnderEvaluationCountDecember = 0;

    let issueConfirmedCountJanuary = 0;
    let issueConfirmedCountFebruary = 0;
    let issueConfirmedCountMarch = 0;
    let issueConfirmedCountApril = 0;
    let issueConfirmedCountMay = 0;
    let issueConfirmedCountJune = 0;
    let issueConfirmedCountJuly = 0;
    let issueConfirmedCountAugust = 0;
    let issueConfirmedCountSeptember = 0;
    let issueConfirmedCountOctober = 0;
    let issueConfirmedCountNovember = 0;
    let issueConfirmedCountDecember = 0;

    let issueResolvedCountJanuary = 0;
    let issueResolvedCountFebruary = 0;
    let issueResolvedCountMarch = 0;
    let issueResolvedCountApril = 0;
    let issueResolvedCountMay = 0;
    let issueResolvedCountJune = 0;
    let issueResolvedCountJuly = 0;
    let issueResolvedCountAugust = 0;
    let issueResolvedCountSeptember = 0;
    let issueResolvedCountOctober = 0;
    let issueResolvedCountNovember = 0;
    let issueResolvedCountDecember = 0;

    let enhancementRequestedCountJanuary = 0;
    let enhancementRequestedCountFebruary = 0;
    let enhancementRequestedCountMarch = 0;
    let enhancementRequestedCountApril = 0;
    let enhancementRequestedCountMay = 0;
    let enhancementRequestedCountJune = 0;
    let enhancementRequestedCountJuly = 0;
    let enhancementRequestedCountAugust = 0;
    let enhancementRequestedCountSeptember = 0;
    let enhancementRequestedCountOctober = 0;
    let enhancementRequestedCountNovember = 0;
    let enhancementRequestedCountDecember = 0;

    let enhancementUnderDevelopmentCountJanuary = 0;
    let enhancementUnderDevelopmentCountFebruary = 0;
    let enhancementUnderDevelopmentCountMarch = 0;
    let enhancementUnderDevelopmentCountApril = 0;
    let enhancementUnderDevelopmentCountMay = 0;
    let enhancementUnderDevelopmentCountJune = 0;
    let enhancementUnderDevelopmentCountJuly = 0;
    let enhancementUnderDevelopmentCountAugust = 0;
    let enhancementUnderDevelopmentCountSeptember = 0;
    let enhancementUnderDevelopmentCountOctober = 0;
    let enhancementUnderDevelopmentCountNovember = 0;
    let enhancementUnderDevelopmentCountDecember = 0;

    for (let i = 0; i < statusLog.length; i += 1) {
      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 0
      ) {
        if (statusLog[i].statusId.toString() === '1') {
          issueOpenedCountJanuary += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 1
      ) {
        if (statusLog[i].statusId.toString() === '1') {
          issueOpenedCountFebruary += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 2
      ) {
        if (statusLog[i].statusId.toString() === '1') {
          issueOpenedCountMarch += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 3
      ) {
        if (statusLog[i].statusId.toString() === '1') {
          issueOpenedCountApril += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 4
      ) {
        if (statusLog[i].statusId.toString() === '1') {
          issueOpenedCountMay += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 5
      ) {
        if (statusLog[i].statusId.toString() === '1') {
          issueOpenedCountJune += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 6
      ) {
        if (statusLog[i].statusId.toString() === '1') {
          issueOpenedCountJuly += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 7
      ) {
        if (statusLog[i].statusId.toString() === '1') {
          issueOpenedCountAugust += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 8
      ) {
        if (statusLog[i].statusId.toString() === '1') {
          issueOpenedCountSeptember += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 9
      ) {
        if (statusLog[i].statusId.toString() === '1') {
          issueOpenedCountOctober += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 10
      ) {
        if (statusLog[i].statusId.toString() === '1') {
          issueOpenedCountNovember += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 11
      ) {
        if (statusLog[i].statusId.toString() === '1') {
          issueOpenedCountDecember += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 0
      ) {
        if (statusLog[i].statusId.toString() === '2') {
          issueRequiresClarificationCountJanuary += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 1
      ) {
        if (statusLog[i].statusId.toString() === '2') {
          issueRequiresClarificationCountFebruary += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 2
      ) {
        if (statusLog[i].statusId.toString() === '2') {
          issueRequiresClarificationCountMarch += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 3
      ) {
        if (statusLog[i].statusId.toString() === '2') {
          issueRequiresClarificationCountApril += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 4
      ) {
        if (statusLog[i].statusId.toString() === '2') {
          issueRequiresClarificationCountMay += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 5
      ) {
        if (statusLog[i].statusId.toString() === '2') {
          issueRequiresClarificationCountJune += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 6
      ) {
        if (statusLog[i].statusId.toString() === '2') {
          issueRequiresClarificationCountJuly += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 7
      ) {
        if (statusLog[i].statusId.toString() === '2') {
          issueRequiresClarificationCountAugust += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 8
      ) {
        if (statusLog[i].statusId.toString() === '2') {
          issueRequiresClarificationCountSeptember += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 9
      ) {
        if (statusLog[i].statusId.toString() === '2') {
          issueRequiresClarificationCountOctober += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 10
      ) {
        if (statusLog[i].statusId.toString() === '2') {
          issueRequiresClarificationCountNovember += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 11
      ) {
        if (statusLog[i].statusId.toString() === '2') {
          issueRequiresClarificationCountDecember += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 0
      ) {
        if (statusLog[i].statusId.toString() === '3') {
          issueUnderEvaluationCountJanuary += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 1
      ) {
        if (statusLog[i].statusId.toString() === '3') {
          issueUnderEvaluationCountFebruary += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 2
      ) {
        if (statusLog[i].statusId.toString() === '3') {
          issueUnderEvaluationCountMarch += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 3
      ) {
        if (statusLog[i].statusId.toString() === '3') {
          issueUnderEvaluationCountApril += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 4
      ) {
        if (statusLog[i].statusId.toString() === '3') {
          issueUnderEvaluationCountMay += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 5
      ) {
        if (statusLog[i].statusId.toString() === '3') {
          issueUnderEvaluationCountJune += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 6
      ) {
        if (statusLog[i].statusId.toString() === '3') {
          issueUnderEvaluationCountJuly += 1;
        }
      }
      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 7
      ) {
        if (statusLog[i].statusId.toString() === '3') {
          issueUnderEvaluationCountAugust += 1;
        }
      }
      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 8
      ) {
        if (statusLog[i].statusId.toString() === '3') {
          issueUnderEvaluationCountSeptember += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 9
      ) {
        if (statusLog[i].statusId.toString() === '3') {
          issueUnderEvaluationCountOctober += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 10
      ) {
        if (statusLog[i].statusId.toString() === '3') {
          issueUnderEvaluationCountNovember += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 11
      ) {
        if (statusLog[i].statusId.toString() === '3') {
          issueUnderEvaluationCountDecember += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 0
      ) {
        if (statusLog[i].statusId.toString() === '4') {
          issueConfirmedCountJanuary += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 1
      ) {
        if (statusLog[i].statusId.toString() === '4') {
          issueConfirmedCountFebruary += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 2
      ) {
        if (statusLog[i].statusId.toString() === '4') {
          issueConfirmedCountMarch += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 3
      ) {
        if (statusLog[i].statusId.toString() === '4') {
          issueConfirmedCountApril += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 4
      ) {
        if (statusLog[i].statusId.toString() === '4') {
          issueConfirmedCountMay += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 5
      ) {
        if (statusLog[i].statusId.toString() === '4') {
          issueConfirmedCountJune += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 6
      ) {
        if (statusLog[i].statusId.toString() === '4') {
          issueConfirmedCountJuly += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 7
      ) {
        if (statusLog[i].statusId.toString() === '4') {
          issueConfirmedCountAugust += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 8
      ) {
        if (statusLog[i].statusId.toString() === '4') {
          issueConfirmedCountSeptember += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 9
      ) {
        if (statusLog[i].statusId.toString() === '4') {
          issueConfirmedCountOctober += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 10
      ) {
        if (statusLog[i].statusId.toString() === '4') {
          issueConfirmedCountNovember += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 11
      ) {
        if (statusLog[i].statusId.toString() === '4') {
          issueConfirmedCountDecember += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 0
      ) {
        if (statusLog[i].statusId.toString() === '5') {
          issueResolvedCountJanuary += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 1
      ) {
        if (statusLog[i].statusId.toString() === '5') {
          issueResolvedCountFebruary += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 2
      ) {
        if (statusLog[i].statusId.toString() === '5') {
          issueResolvedCountMarch += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 3
      ) {
        if (statusLog[i].statusId.toString() === '5') {
          issueResolvedCountApril += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 4
      ) {
        if (statusLog[i].statusId.toString() === '5') {
          issueResolvedCountMay += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 5
      ) {
        if (statusLog[i].statusId.toString() === '5') {
          issueResolvedCountJune += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 6
      ) {
        if (statusLog[i].statusId.toString() === '5') {
          issueResolvedCountJuly += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 7
      ) {
        if (statusLog[i].statusId.toString() === '5') {
          issueResolvedCountAugust += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 8
      ) {
        if (statusLog[i].statusId.toString() === '5') {
          issueResolvedCountSeptember += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 9
      ) {
        if (statusLog[i].statusId.toString() === '5') {
          issueResolvedCountOctober += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 10
      ) {
        if (statusLog[i].statusId.toString() === '5') {
          issueResolvedCountNovember += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 11
      ) {
        if (statusLog[i].statusId.toString() === '5') {
          issueResolvedCountDecember += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 0
      ) {
        if (statusLog[i].statusId.toString() === '7') {
          enhancementRequestedCountJanuary += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 1
      ) {
        if (statusLog[i].statusId.toString() === '7') {
          enhancementRequestedCountFebruary += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 2
      ) {
        if (statusLog[i].statusId.toString() === '7') {
          enhancementRequestedCountMarch += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 3
      ) {
        if (statusLog[i].statusId.toString() === '7') {
          enhancementRequestedCountApril += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 4
      ) {
        if (statusLog[i].statusId.toString() === '7') {
          enhancementRequestedCountMay += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 5
      ) {
        if (statusLog[i].statusId.toString() === '7') {
          enhancementRequestedCountJune += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 6
      ) {
        if (statusLog[i].statusId.toString() === '7') {
          enhancementRequestedCountJuly += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 7
      ) {
        if (statusLog[i].statusId.toString() === '7') {
          enhancementRequestedCountAugust += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 8
      ) {
        if (statusLog[i].statusId.toString() === '7') {
          enhancementRequestedCountSeptember += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 9
      ) {
        if (statusLog[i].statusId.toString() === '7') {
          enhancementRequestedCountOctober += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 10
      ) {
        if (statusLog[i].statusId.toString() === '7') {
          enhancementRequestedCountNovember += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 11
      ) {
        if (statusLog[i].statusId.toString() === '7') {
          enhancementRequestedCountDecember += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 0
      ) {
        if (statusLog[i].statusId.toString() === '8') {
          enhancementUnderDevelopmentCountJanuary += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 1
      ) {
        if (statusLog[i].statusId.toString() === '8') {
          enhancementUnderDevelopmentCountFebruary += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 2
      ) {
        if (statusLog[i].statusId.toString() === '8') {
          enhancementUnderDevelopmentCountMarch += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 3
      ) {
        if (statusLog[i].statusId.toString() === '8') {
          enhancementUnderDevelopmentCountApril += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 4
      ) {
        if (statusLog[i].statusId.toString() === '8') {
          enhancementUnderDevelopmentCountMay += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 5
      ) {
        if (statusLog[i].statusId.toString() === '8') {
          enhancementUnderDevelopmentCountJune += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 6
      ) {
        if (statusLog[i].statusId.toString() === '8') {
          enhancementUnderDevelopmentCountJuly += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 7
      ) {
        if (statusLog[i].statusId.toString() === '8') {
          enhancementUnderDevelopmentCountAugust += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 8
      ) {
        if (statusLog[i].statusId.toString() === '8') {
          enhancementUnderDevelopmentCountSeptember += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 9
      ) {
        if (statusLog[i].statusId.toString() === '8') {
          enhancementUnderDevelopmentCountOctober += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 10
      ) {
        if (statusLog[i].statusId.toString() === '8') {
          enhancementUnderDevelopmentCountNovember += 1;
        }
      }

      if (
        statusLog[i].projectId === projectId &&
        new Date(statusLog[i].dateStarted).getFullYear() === year &&
        new Date(statusLog[i].dateStarted).getMonth() === 11
      ) {
        if (statusLog[i].statusId.toString() === '8') {
          enhancementUnderDevelopmentCountDecember += 1;
        }
      }
    }

    if (statusLog) {
      for (let i = 0; i < statusLog.length; i += 1) {
        if (
          statusLog[i].projectId === projectId &&
          new Date(statusLog[i].dateStarted).getFullYear() === year &&
          new Date(statusLog[i].dateStarted).getMonth() === 0
        ) {
          if (statusLog[i].statusId.toString() === '1') {
            issueOpenedMonthTotals[0] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueOpenedCountJanuary;
          }

          if (statusLog[i].statusId.toString() === '2') {
            issueRequiresClarificationMonthTotals[0] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueRequiresClarificationCountJanuary;
          }

          if (statusLog[i].statusId.toString() === '3') {
            issueUnderEvaluationMonthTotals[0] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueUnderEvaluationCountJanuary;
          }

          if (statusLog[i].statusId.toString() === '4') {
            issueConfirmedMonthTotals[0] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueConfirmedCountJanuary;
          }

          if (statusLog[i].statusId.toString() === '5') {
            issueResolvedMonthTotals[0] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueResolvedCountJanuary;
          }

          if (statusLog[i].statusId.toString() === '7') {
            enhancementRequestedMonthTotals[0] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementRequestedCountJanuary;
          }

          if (statusLog[i].statusId.toString() === '8') {
            enhancementUnderDevelopmentMonthTotals[0] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementUnderDevelopmentCountJanuary;
          }
        }
      }

      for (let i = 0; i < statusLog.length; i += 1) {
        if (
          statusLog[i].projectId === projectId &&
          new Date(statusLog[i].dateStarted).getFullYear() === year &&
          new Date(statusLog[i].dateStarted).getMonth() === 1
        ) {
          if (statusLog[i].statusId.toString() === '1') {
            issueOpenedMonthTotals[1] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueOpenedCountFebruary;
          }

          if (statusLog[i].statusId.toString() === '2') {
            issueRequiresClarificationMonthTotals[1] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueRequiresClarificationCountFebruary;
          }

          if (statusLog[i].statusId.toString() === '3') {
            issueUnderEvaluationMonthTotals[1] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueUnderEvaluationCountFebruary;
          }

          if (statusLog[i].statusId.toString() === '4') {
            issueConfirmedMonthTotals[1] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueConfirmedCountFebruary;
          }

          if (statusLog[i].statusId.toString() === '5') {
            issueResolvedMonthTotals[1] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueResolvedCountFebruary;
          }

          if (statusLog[i].statusId.toString() === '7') {
            enhancementRequestedMonthTotals[1] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementRequestedCountFebruary;
          }

          if (statusLog[i].statusId.toString() === '8') {
            enhancementUnderDevelopmentMonthTotals[1] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementUnderDevelopmentCountFebruary;
          }
        }
      }

      for (let i = 0; i < statusLog.length; i += 1) {
        if (
          statusLog[i].projectId === projectId &&
          new Date(statusLog[i].dateStarted).getFullYear() === year &&
          new Date(statusLog[i].dateStarted).getMonth() === 2
        ) {
          if (statusLog[i].statusId.toString() === '1') {
            issueOpenedMonthTotals[2] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueOpenedCountMarch;
          }

          if (statusLog[i].statusId.toString() === '2') {
            issueRequiresClarificationMonthTotals[2] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueRequiresClarificationCountMarch;
          }

          if (statusLog[i].statusId.toString() === '3') {
            issueUnderEvaluationMonthTotals[2] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueUnderEvaluationCountMarch;
          }

          if (statusLog[i].statusId.toString() === '4') {
            issueConfirmedMonthTotals[2] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueConfirmedCountMarch;
          }

          if (statusLog[i].statusId.toString() === '5') {
            issueResolvedMonthTotals[2] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueResolvedCountMarch;
          }

          if (statusLog[i].statusId.toString() === '7') {
            enhancementRequestedMonthTotals[2] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementRequestedCountMarch;
          }

          if (statusLog[i].statusId.toString() === '8') {
            enhancementUnderDevelopmentMonthTotals[2] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementUnderDevelopmentCountMarch;
          }
        }
      }

      for (let i = 0; i < statusLog.length; i += 1) {
        if (
          statusLog[i].projectId === projectId &&
          new Date(statusLog[i].dateStarted).getFullYear() === year &&
          new Date(statusLog[i].dateStarted).getMonth() === 3
        ) {
          if (statusLog[i].statusId.toString() === '1') {
            issueOpenedMonthTotals[3] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueOpenedCountApril;
          }

          if (statusLog[i].statusId.toString() === '2') {
            issueRequiresClarificationMonthTotals[3] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueRequiresClarificationCountApril;
          }

          if (statusLog[i].statusId.toString() === '3') {
            issueUnderEvaluationMonthTotals[3] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueUnderEvaluationCountApril;
          }

          if (statusLog[i].statusId.toString() === '4') {
            issueConfirmedMonthTotals[3] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueConfirmedCountApril;
          }

          if (statusLog[i].statusId.toString() === '5') {
            issueResolvedMonthTotals[3] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueResolvedCountApril;
          }

          if (statusLog[i].statusId.toString() === '7') {
            enhancementRequestedMonthTotals[3] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementRequestedCountApril;
          }

          if (statusLog[i].statusId.toString() === '8') {
            enhancementUnderDevelopmentMonthTotals[3] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementUnderDevelopmentCountApril;
          }
        }
      }

      for (let i = 0; i < statusLog.length; i += 1) {
        if (
          statusLog[i].projectId === projectId &&
          new Date(statusLog[i].dateStarted).getFullYear() === year &&
          new Date(statusLog[i].dateStarted).getMonth() === 4
        ) {
          if (statusLog[i].statusId.toString() === '1') {
            issueOpenedMonthTotals[4] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueOpenedCountMay;
          }

          if (statusLog[i].statusId.toString() === '2') {
            issueRequiresClarificationMonthTotals[4] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueRequiresClarificationCountMay;
          }

          if (statusLog[i].statusId.toString() === '3') {
            issueUnderEvaluationMonthTotals[4] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueUnderEvaluationCountMay;
          }

          if (statusLog[i].statusId.toString() === '4') {
            issueConfirmedMonthTotals[4] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueConfirmedCountMay;
          }

          if (statusLog[i].statusId.toString() === '5') {
            issueResolvedMonthTotals[4] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueResolvedCountMay;
          }

          if (statusLog[i].statusId.toString() === '7') {
            enhancementRequestedMonthTotals[4] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementRequestedCountMay;
          }

          if (statusLog[i].statusId.toString() === '8') {
            enhancementUnderDevelopmentMonthTotals[4] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementUnderDevelopmentCountMay;
          }
        }
      }

      for (let i = 0; i < statusLog.length; i += 1) {
        if (
          statusLog[i].projectId === projectId &&
          new Date(statusLog[i].dateStarted).getFullYear() === year &&
          new Date(statusLog[i].dateStarted).getMonth() === 5
        ) {
          if (statusLog[i].statusId.toString() === '1') {
            issueOpenedMonthTotals[5] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueOpenedCountJune;
          }

          if (statusLog[i].statusId.toString() === '2') {
            issueRequiresClarificationMonthTotals[5] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueRequiresClarificationCountJune;
          }

          if (statusLog[i].statusId.toString() === '3') {
            issueUnderEvaluationMonthTotals[5] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueUnderEvaluationCountJune;
          }

          if (statusLog[i].statusId.toString() === '4') {
            issueConfirmedMonthTotals[5] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueConfirmedCountJune;
          }

          if (statusLog[i].statusId.toString() === '5') {
            issueResolvedMonthTotals[5] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueResolvedCountJune;
          }

          if (statusLog[i].statusId.toString() === '7') {
            enhancementRequestedMonthTotals[5] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementRequestedCountJune;
          }

          if (statusLog[i].statusId.toString() === '8') {
            enhancementUnderDevelopmentMonthTotals[5] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementUnderDevelopmentCountJune;
          }
        }
      }

      for (let i = 0; i < statusLog.length; i += 1) {
        if (
          statusLog[i].projectId === projectId &&
          new Date(statusLog[i].dateStarted).getFullYear() === year &&
          new Date(statusLog[i].dateStarted).getMonth() === 6
        ) {
          if (statusLog[i].statusId.toString() === '1') {
            issueOpenedMonthTotals[6] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueOpenedCountJuly;
          }

          if (statusLog[i].statusId.toString() === '2') {
            issueRequiresClarificationMonthTotals[6] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueRequiresClarificationCountJuly;
          }

          if (statusLog[i].statusId.toString() === '3') {
            issueUnderEvaluationMonthTotals[6] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueUnderEvaluationCountJuly;
          }

          if (statusLog[i].statusId.toString() === '4') {
            issueConfirmedMonthTotals[6] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueConfirmedCountJuly;
          }

          if (statusLog[i].statusId.toString() === '5') {
            issueResolvedMonthTotals[6] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueResolvedCountJuly;
          }

          if (statusLog[i].statusId.toString() === '7') {
            enhancementRequestedMonthTotals[6] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementRequestedCountJuly;
          }

          if (statusLog[i].statusId.toString() === '8') {
            enhancementUnderDevelopmentMonthTotals[6] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementUnderDevelopmentCountJuly;
          }
        }
      }

      for (let i = 0; i < statusLog.length; i += 1) {
        if (
          statusLog[i].projectId === projectId &&
          new Date(statusLog[i].dateStarted).getFullYear() === year &&
          new Date(statusLog[i].dateStarted).getMonth() === 7
        ) {
          if (statusLog[i].statusId.toString() === '1') {
            issueOpenedMonthTotals[7] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueOpenedCountAugust;
          }

          if (statusLog[i].statusId.toString() === '2') {
            issueRequiresClarificationMonthTotals[7] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueRequiresClarificationCountAugust;
          }

          if (statusLog[i].statusId.toString() === '3') {
            issueUnderEvaluationMonthTotals[7] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueUnderEvaluationCountAugust;
          }

          if (statusLog[i].statusId.toString() === '4') {
            issueConfirmedMonthTotals[7] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueConfirmedCountAugust;
          }

          if (statusLog[i].statusId.toString() === '5') {
            issueResolvedMonthTotals[7] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueResolvedCountAugust;
          }

          if (statusLog[i].statusId.toString() === '7') {
            enhancementRequestedMonthTotals[7] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementRequestedCountAugust;
          }

          if (statusLog[i].statusId.toString() === '8') {
            enhancementUnderDevelopmentMonthTotals[7] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementUnderDevelopmentCountAugust;
          }
        }
      }

      for (let i = 0; i < statusLog.length; i += 1) {
        if (
          statusLog[i].projectId === projectId &&
          new Date(statusLog[i].dateStarted).getFullYear() === year &&
          new Date(statusLog[i].dateStarted).getMonth() === 8
        ) {
          if (statusLog[i].statusId.toString() === '1') {
            issueOpenedMonthTotals[8] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueOpenedCountSeptember;
          }

          if (statusLog[i].statusId.toString() === '2') {
            issueRequiresClarificationMonthTotals[8] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueRequiresClarificationCountSeptember;
          }

          if (statusLog[i].statusId.toString() === '3') {
            issueUnderEvaluationMonthTotals[8] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueUnderEvaluationCountSeptember;
          }

          if (statusLog[i].statusId.toString() === '4') {
            issueConfirmedMonthTotals[8] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueConfirmedCountSeptember;
          }

          if (statusLog[i].statusId.toString() === '5') {
            issueResolvedMonthTotals[8] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueResolvedCountSeptember;
          }

          if (statusLog[i].statusId.toString() === '7') {
            enhancementRequestedMonthTotals[8] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementRequestedCountSeptember;
          }

          if (statusLog[i].statusId.toString() === '8') {
            enhancementUnderDevelopmentMonthTotals[8] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementUnderDevelopmentCountSeptember;
          }
        }
      }

      for (let i = 0; i < statusLog.length; i += 1) {
        if (
          statusLog[i].projectId === projectId &&
          new Date(statusLog[i].dateStarted).getFullYear() === year &&
          new Date(statusLog[i].dateStarted).getMonth() === 9
        ) {
          if (statusLog[i].statusId.toString() === '1') {
            issueOpenedMonthTotals[9] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueOpenedCountOctober;
          }

          if (statusLog[i].statusId.toString() === '2') {
            issueRequiresClarificationMonthTotals[9] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueRequiresClarificationCountOctober;
          }

          if (statusLog[i].statusId.toString() === '3') {
            issueUnderEvaluationMonthTotals[9] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueUnderEvaluationCountOctober;
          }

          if (statusLog[i].statusId.toString() === '4') {
            issueConfirmedMonthTotals[9] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueConfirmedCountOctober;
          }

          if (statusLog[i].statusId.toString() === '5') {
            issueResolvedMonthTotals[9] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueResolvedCountOctober;
          }

          if (statusLog[i].statusId.toString() === '7') {
            enhancementRequestedMonthTotals[9] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementRequestedCountOctober;
          }

          if (statusLog[i].statusId.toString() === '8') {
            enhancementUnderDevelopmentMonthTotals[9] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementUnderDevelopmentCountOctober;
          }
        }
      }

      for (let i = 0; i < statusLog.length; i += 1) {
        if (
          statusLog[i].projectId === projectId &&
          new Date(statusLog[i].dateStarted).getFullYear() === year &&
          new Date(statusLog[i].dateStarted).getMonth() === 10
        ) {
          if (statusLog[i].statusId.toString() === '1') {
            issueOpenedMonthTotals[10] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueOpenedCountNovember;
          }

          if (statusLog[i].statusId.toString() === '2') {
            issueRequiresClarificationMonthTotals[10] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueRequiresClarificationCountNovember;
          }

          if (statusLog[i].statusId.toString() === '3') {
            issueUnderEvaluationMonthTotals[10] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueUnderEvaluationCountNovember;
          }

          if (statusLog[i].statusId.toString() === '4') {
            issueConfirmedMonthTotals[10] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueConfirmedCountNovember;
          }

          if (statusLog[i].statusId.toString() === '5') {
            issueResolvedMonthTotals[10] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueResolvedCountNovember;
          }

          if (statusLog[i].statusId.toString() === '7') {
            enhancementRequestedMonthTotals[10] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementRequestedCountNovember;
          }

          if (statusLog[i].statusId.toString() === '8') {
            enhancementUnderDevelopmentMonthTotals[10] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementUnderDevelopmentCountNovember;
          }
        }
      }

      for (let i = 0; i < statusLog.length; i += 1) {
        if (
          statusLog[i].projectId === projectId &&
          new Date(statusLog[i].dateStarted).getFullYear() === year &&
          new Date(statusLog[i].dateStarted).getMonth() === 11
        ) {
          if (statusLog[i].statusId.toString() === '1') {
            issueOpenedMonthTotals[11] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueOpenedCountDecember;
          }

          if (statusLog[i].statusId.toString() === '2') {
            issueRequiresClarificationMonthTotals[11] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueRequiresClarificationCountDecember;
          }

          if (statusLog[i].statusId.toString() === '3') {
            issueUnderEvaluationMonthTotals[11] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueUnderEvaluationCountDecember;
          }

          if (statusLog[i].statusId.toString() === '4') {
            issueConfirmedMonthTotals[11] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueConfirmedCountDecember;
          }

          if (statusLog[i].statusId.toString() === '5') {
            issueResolvedMonthTotals[11] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              issueResolvedCountDecember;
          }

          if (statusLog[i].statusId.toString() === '7') {
            enhancementRequestedMonthTotals[11] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementRequestedCountDecember;
          }

          if (statusLog[i].statusId.toString() === '8') {
            enhancementUnderDevelopmentMonthTotals[11] +=
              (new Date(statusLog[i].dateEnded).getTime() -
                new Date(statusLog[i].dateStarted).getTime()) /
              1000 /
              60 /
              60 /
              enhancementUnderDevelopmentCountDecember;
          }
        }
      }
    }

    // Issue opened monthly average

    for (let i = 0; i < issueOpenedMonthTotals.length; i += 1) {
      if (issueOpenedMonthTotals[i] > 0) {
        issueOpenedActiveMonths += 1;
      }
    }

    if (issueOpenedActiveMonths > 0) {
      let issueOpenedYearTotal = 0;

      for (let i = 0; i < issueOpenedMonthTotals.length; i += 1) {
        issueOpenedYearTotal += issueOpenedMonthTotals[i];
      }

      if (issueOpenedYearTotal % issueOpenedActiveMonths === 0) {
        issueOpenedMonthlyAverage =
          issueOpenedYearTotal / issueOpenedActiveMonths;
      } else {
        issueOpenedMonthlyAverage = (
          issueOpenedYearTotal / issueOpenedActiveMonths
        ).toFixed(2);
      }
    } else {
      issueOpenedActiveMonths = 0;
    }

    // Issue requires clarification monthly average

    for (let i = 0; i < issueRequiresClarificationMonthTotals.length; i += 1) {
      if (issueRequiresClarificationMonthTotals[i] > 0) {
        issueRequiresClarificationActiveMonths += 1;
      }
    }

    if (issueRequiresClarificationActiveMonths > 0) {
      let issueRequiresClarificationYearTotal = 0;

      for (
        let i = 0;
        i < issueRequiresClarificationMonthTotals.length;
        i += 1
      ) {
        issueRequiresClarificationYearTotal +=
          issueRequiresClarificationMonthTotals[i];
      }

      if (
        issueRequiresClarificationYearTotal %
          issueRequiresClarificationActiveMonths ===
        0
      ) {
        issueRequiresClarificationMonthlyAverage =
          issueRequiresClarificationYearTotal /
          issueRequiresClarificationActiveMonths;
      } else {
        issueRequiresClarificationMonthlyAverage = (
          issueRequiresClarificationYearTotal /
          issueRequiresClarificationActiveMonths
        ).toFixed(2);
      }
    } else {
      issueRequiresClarificationActiveMonths = 0;
    }

    // Issue under evaluation monthly average

    for (let i = 0; i < issueUnderEvaluationMonthTotals.length; i += 1) {
      if (issueUnderEvaluationMonthTotals[i] > 0) {
        issueUnderEvaluationActiveMonths += 1;
      }
    }

    if (issueUnderEvaluationActiveMonths > 0) {
      let issueUnderEvaluationYearTotal = 0;

      for (let i = 0; i < issueUnderEvaluationMonthTotals.length; i += 1) {
        issueUnderEvaluationYearTotal += issueUnderEvaluationMonthTotals[i];
      }

      if (
        issueUnderEvaluationYearTotal % issueUnderEvaluationActiveMonths ===
        0
      ) {
        issueUnderEvaluationMonthlyAverage =
          issueUnderEvaluationYearTotal / issueUnderEvaluationActiveMonths;
      } else {
        issueUnderEvaluationMonthlyAverage = (
          issueUnderEvaluationYearTotal / issueUnderEvaluationActiveMonths
        ).toFixed(2);
      }
    } else {
      issueUnderEvaluationActiveMonths = 0;
    }

    // Issue confirmed monthly average

    for (let i = 0; i < issueConfirmedMonthTotals.length; i += 1) {
      if (issueConfirmedMonthTotals[i] > 0) {
        issueConfirmedActiveMonths += 1;
      }
    }

    if (issueConfirmedActiveMonths > 0) {
      let issueConfirmedYearTotal = 0;

      for (let i = 0; i < issueConfirmedMonthTotals.length; i += 1) {
        issueConfirmedYearTotal += issueConfirmedMonthTotals[i];
      }

      if (issueConfirmedYearTotal % issueConfirmedActiveMonths === 0) {
        issueConfirmedMonthlyAverage =
          issueConfirmedYearTotal / issueConfirmedActiveMonths;
      } else {
        issueConfirmedMonthlyAverage = (
          issueConfirmedYearTotal / issueConfirmedActiveMonths
        ).toFixed(2);
      }
    } else {
      issueConfirmedActiveMonths = 0;
    }

    // Issue resolved monthly average

    for (let i = 0; i < issueResolvedMonthTotals.length; i += 1) {
      if (issueResolvedMonthTotals[i] > 0) {
        issueResolvedActiveMonths += 1;
      }
    }

    if (issueResolvedActiveMonths > 0) {
      let issueResolvedYearTotal = 0;

      for (let i = 0; i < issueResolvedMonthTotals.length; i += 1) {
        issueResolvedYearTotal += issueResolvedMonthTotals[i];
      }

      if (issueResolvedYearTotal % issueResolvedActiveMonths === 0) {
        issueResolvedMonthlyAverage =
          issueResolvedYearTotal / issueResolvedActiveMonths;
      } else {
        issueResolvedMonthlyAverage = (
          issueResolvedYearTotal / issueResolvedActiveMonths
        ).toFixed(2);
      }
    } else {
      issueResolvedActiveMonths = 0;
    }

    // Enhancement requested monthly average

    for (let i = 0; i < enhancementRequestedMonthTotals.length; i += 1) {
      if (enhancementRequestedMonthTotals[i] > 0) {
        enhancementRequestedActiveMonths += 1;
      }
    }

    if (enhancementRequestedActiveMonths > 0) {
      let enhancementRequestedYearTotal = 0;

      for (let i = 0; i < enhancementRequestedMonthTotals.length; i += 1) {
        enhancementRequestedYearTotal += enhancementRequestedMonthTotals[i];
      }

      if (
        enhancementRequestedYearTotal % enhancementRequestedActiveMonths ===
        0
      ) {
        enhancementRequestedMonthlyAverage =
          enhancementRequestedYearTotal / enhancementRequestedActiveMonths;
      } else {
        enhancementRequestedMonthlyAverage = (
          enhancementRequestedYearTotal / enhancementRequestedActiveMonths
        ).toFixed(2);
      }
    } else {
      enhancementRequestedActiveMonths = 0;
    }

    // Enhancement under development monthly average

    for (let i = 0; i < enhancementUnderDevelopmentMonthTotals.length; i += 1) {
      if (enhancementUnderDevelopmentMonthTotals[i] > 0) {
        enhancementUnderDevelopmentActiveMonths += 1;
      }
    }

    if (enhancementUnderDevelopmentActiveMonths > 0) {
      let enhancementUnderDevelopmentYearTotal = 0;

      for (
        let i = 0;
        i < enhancementUnderDevelopmentMonthTotals.length;
        i += 1
      ) {
        enhancementUnderDevelopmentYearTotal +=
          enhancementUnderDevelopmentMonthTotals[i];
      }

      if (
        enhancementUnderDevelopmentYearTotal %
          enhancementUnderDevelopmentActiveMonths ===
        0
      ) {
        enhancementUnderDevelopmentMonthlyAverage =
          enhancementUnderDevelopmentYearTotal /
          enhancementUnderDevelopmentActiveMonths;
      } else {
        enhancementUnderDevelopmentMonthlyAverage = (
          enhancementUnderDevelopmentYearTotal /
          enhancementUnderDevelopmentActiveMonths
        ).toFixed(2);
      }
    } else {
      enhancementUnderDevelopmentActiveMonths = 0;
    }

    return (
      <div>
        <table className="project-status-year-table table text-secondary">
          <tbody>
            <tr>
              <td className="project-status-year-td">
                {issueOpenedActiveMonths === 1 ? (
                  <div>
                    <i className="red-500 fa fa-square" />
                    &ensp;Issue opened: waiting for agent response
                  </div>
                ) : (
                  <div>
                    <i className="red-500 fa fa-square" />
                    &ensp;Issue opened: waiting for agent response
                  </div>
                )}
                {issueRequiresClarificationActiveMonths === 1 ? (
                  <div>
                    <i className="orange-500 fa fa-square" />
                    &ensp;Issue requries clarification: waiting for client
                    response
                  </div>
                ) : (
                  <div>
                    <i className="orange-500 fa fa-square" />
                    &ensp;Issue requries clarification: waiting for client
                    response
                  </div>
                )}
                {issueUnderEvaluationActiveMonths === 1 ? (
                  <div>
                    <i className="yellow-500 fa fa-square" />
                    &ensp;Issue under evaluation: waiting for developer feedback
                  </div>
                ) : (
                  <div>
                    <i className="yellow-500 fa fa-square" />
                    &ensp;Issue under evaluation: waiting for developer feedback
                  </div>
                )}
                {issueConfirmedActiveMonths === 1 ? (
                  <div>
                    <i className="green-500 fa fa-square" />
                    &ensp;Issue confirmed: waiting for developer patch
                  </div>
                ) : (
                  <div>
                    <i className="green-500 fa fa-square" />
                    &ensp;Issue confirmed: waiting for developer patch
                  </div>
                )}
                {issueResolvedActiveMonths === 1 ? (
                  <div>
                    <i className="blue-500 fa fa-square" />
                    &ensp;Issue resolved: waiting for client confirmation
                  </div>
                ) : (
                  <div>
                    <i className="blue-500 fa fa-square" />
                    &ensp;Issue resolved: waiting for client confirmation
                  </div>
                )}
                {enhancementRequestedActiveMonths === 1 ? (
                  <div>
                    <i className="indigo-500 fa fa-square" />
                    &ensp;Enhancement requested: waiting for developer
                    confirmation
                  </div>
                ) : (
                  <div>
                    <i className="indigo-500 fa fa-square" />
                    &ensp;Enhancement requested: waiting for developer
                    confirmation
                  </div>
                )}
                {enhancementUnderDevelopmentActiveMonths === 1 ? (
                  <div>
                    <i className="purple-500 fa fa-square" />
                    &ensp;Enhancement under development: waiting for developer
                    patch
                  </div>
                ) : (
                  <div>
                    <i className="purple-500 fa fa-square" />
                    &ensp;Enhancement under development: waiting for developer
                    patch
                  </div>
                )}
              </td>
              <td>
                {issueOpenedActiveMonths === 1 ? (
                  <div className="text-right">
                    <b>
                      {Math.round(issueOpenedMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {issueOpenedActiveMonths}
                    &nbsp;month)
                  </div>
                ) : (
                  <div className="text-right">
                    <b>
                      {Math.round(issueOpenedMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {issueOpenedActiveMonths}
                    &nbsp;months)
                  </div>
                )}
                {issueRequiresClarificationActiveMonths === 1 ? (
                  <div className="text-right">
                    <b>
                      {Math.round(issueRequiresClarificationMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {issueRequiresClarificationActiveMonths}
                    &nbsp;month)
                  </div>
                ) : (
                  <div className="text-right">
                    <b>
                      {Math.round(issueRequiresClarificationMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {issueRequiresClarificationActiveMonths}
                    &nbsp;months)
                  </div>
                )}
                {issueUnderEvaluationActiveMonths === 1 ? (
                  <div className="text-right">
                    <b>
                      {Math.round(issueUnderEvaluationMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {issueUnderEvaluationActiveMonths}
                    &nbsp;month)
                  </div>
                ) : (
                  <div className="text-right">
                    <b>
                      {Math.round(issueUnderEvaluationMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {issueUnderEvaluationActiveMonths}
                    &nbsp;months)
                  </div>
                )}
                {issueConfirmedActiveMonths === 1 ? (
                  <div className="text-right">
                    <b>
                      {Math.round(issueConfirmedMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {issueConfirmedActiveMonths}
                    &nbsp;month)
                  </div>
                ) : (
                  <div className="text-right">
                    <b>
                      {Math.round(issueConfirmedMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {issueConfirmedActiveMonths}
                    &nbsp;months)
                  </div>
                )}
                {issueResolvedActiveMonths === 1 ? (
                  <div className="text-right">
                    <b>
                      {Math.round(issueResolvedMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {issueResolvedActiveMonths}
                    &nbsp;month)
                  </div>
                ) : (
                  <div className="text-right">
                    <b>
                      {Math.round(issueResolvedMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {issueResolvedActiveMonths}
                    &nbsp;months)
                  </div>
                )}
                {enhancementRequestedActiveMonths === 1 ? (
                  <div className="text-right">
                    <b>
                      {Math.round(enhancementRequestedMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {enhancementRequestedActiveMonths}
                    &nbsp;month)
                  </div>
                ) : (
                  <div className="text-right">
                    <b>
                      {Math.round(enhancementRequestedMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {enhancementRequestedActiveMonths}
                    &nbsp;months)
                  </div>
                )}
                {enhancementUnderDevelopmentActiveMonths === 1 ? (
                  <div className="text-right">
                    <b>
                      {Math.round(enhancementUnderDevelopmentMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {enhancementUnderDevelopmentActiveMonths}
                    &nbsp;month)
                  </div>
                ) : (
                  <div className="text-right">
                    <b>
                      {Math.round(enhancementUnderDevelopmentMonthlyAverage)}
                      &nbsp;h
                    </b>
                    &nbsp; average per month (active for&nbsp;
                    {enhancementUnderDevelopmentActiveMonths}
                    &nbsp;months)
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <table className="project-status-month-table table text-secondary">
          <tbody>
            <tr>
              <td>
                <br />
                <div>
                  <i className="red-500 fa fa-square" />
                  &ensp;Issue opened: waiting for agent response
                </div>
                <div>
                  <i className="orange-500 fa fa-square" />
                  &ensp;Issue requires clarification: waiting for client
                  response
                </div>
                <div>
                  <i className="yellow-500 fa fa-square" />
                  &ensp;Issue under evaluation: waiting for developer feedback
                </div>
                <div>
                  <i className="green-500 fa fa-square" />
                  &ensp;Issue confirmed: waiting for developer patch
                </div>
                <div>
                  <i className="blue-500 fa fa-square" />
                  &ensp;Issue resolved: waiting for client confirmation
                </div>
                <div>
                  <i className="indigo-500 fa fa-square" />
                  &ensp;Enhancement requested: waiting for developer
                  confirmation
                </div>
                <div>
                  <i className="purple-500 fa fa-square" />
                  &ensp;Enhancement under development: waiting for developer
                  patch
                </div>
              </td>
              <td>
                <div className="text-right">Jan</div>
                <div className="text-right">
                  {Math.round(issueOpenedMonthTotals[0])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueRequiresClarificationMonthTotals[0])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueUnderEvaluationMonthTotals[0])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueConfirmedMonthTotals[0])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueResolvedMonthTotals[0])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementRequestedMonthTotals[0])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementUnderDevelopmentMonthTotals[0])}
                  &nbsp;h
                </div>
              </td>
              <td>
                <div className="text-right">Feb</div>
                <div className="text-right">
                  {Math.round(issueOpenedMonthTotals[1])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueRequiresClarificationMonthTotals[1])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueUnderEvaluationMonthTotals[1])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueConfirmedMonthTotals[1])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueResolvedMonthTotals[1])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementRequestedMonthTotals[1])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementUnderDevelopmentMonthTotals[1])}
                  &nbsp;h
                </div>
              </td>
              <td>
                <div className="text-right">Mar</div>
                <div className="text-right">
                  {Math.round(issueOpenedMonthTotals[2])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueRequiresClarificationMonthTotals[2])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueUnderEvaluationMonthTotals[2])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueConfirmedMonthTotals[2])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueResolvedMonthTotals[2])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementRequestedMonthTotals[2])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementUnderDevelopmentMonthTotals[2])}
                  &nbsp;h
                </div>
              </td>
              <td>
                <div className="text-right">Apr</div>
                <div className="text-right">
                  {Math.round(issueOpenedMonthTotals[3])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueRequiresClarificationMonthTotals[3])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueUnderEvaluationMonthTotals[3])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueConfirmedMonthTotals[3])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueResolvedMonthTotals[3])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementRequestedMonthTotals[3])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementUnderDevelopmentMonthTotals[3])}
                  &nbsp;h
                </div>
              </td>
              <td>
                <div className="text-right">May</div>
                <div className="text-right">
                  {Math.round(issueOpenedMonthTotals[4])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueRequiresClarificationMonthTotals[4])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueUnderEvaluationMonthTotals[4])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueConfirmedMonthTotals[4])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueResolvedMonthTotals[4])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementRequestedMonthTotals[4])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementUnderDevelopmentMonthTotals[4])}
                  &nbsp;h
                </div>
              </td>
              <td>
                <div className="text-right">Jun</div>
                <div className="text-right">
                  {Math.round(issueOpenedMonthTotals[5])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueRequiresClarificationMonthTotals[5])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueUnderEvaluationMonthTotals[5])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueConfirmedMonthTotals[5])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueResolvedMonthTotals[5])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementRequestedMonthTotals[5])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementUnderDevelopmentMonthTotals[5])}
                  &nbsp;h
                </div>
              </td>
              <td>
                <div className="text-right">Jul</div>
                <div className="text-right">
                  {Math.round(issueOpenedMonthTotals[6])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueRequiresClarificationMonthTotals[6])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueUnderEvaluationMonthTotals[6])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueConfirmedMonthTotals[6])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueResolvedMonthTotals[6])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementRequestedMonthTotals[6])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementUnderDevelopmentMonthTotals[6])}
                  &nbsp;h
                </div>
              </td>
              <td>
                <div className="text-right">Aug</div>
                <div className="text-right">
                  {Math.round(issueOpenedMonthTotals[7])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueRequiresClarificationMonthTotals[7])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueUnderEvaluationMonthTotals[7])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueConfirmedMonthTotals[7])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueResolvedMonthTotals[7])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementRequestedMonthTotals[7])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementUnderDevelopmentMonthTotals[7])}
                  &nbsp;h
                </div>
              </td>
              <td>
                <div className="text-right">Sep</div>
                <div className="text-right">
                  {Math.round(issueOpenedMonthTotals[8])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueRequiresClarificationMonthTotals[8])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueUnderEvaluationMonthTotals[8])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueConfirmedMonthTotals[8])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueResolvedMonthTotals[8])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementRequestedMonthTotals[8])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementUnderDevelopmentMonthTotals[8])}
                  &nbsp;h
                </div>
              </td>
              <td>
                <div className="text-right">Oct</div>
                <div className="text-right">
                  {Math.round(issueOpenedMonthTotals[9])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueRequiresClarificationMonthTotals[9])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueUnderEvaluationMonthTotals[9])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueConfirmedMonthTotals[9])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueResolvedMonthTotals[9])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementRequestedMonthTotals[9])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementUnderDevelopmentMonthTotals[9])}
                  &nbsp;h
                </div>
              </td>
              <td>
                <div className="text-right">Nov</div>
                <div className="text-right">
                  {Math.round(issueOpenedMonthTotals[10])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueRequiresClarificationMonthTotals[10])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueUnderEvaluationMonthTotals[10])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueConfirmedMonthTotals[10])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueResolvedMonthTotals[10])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementRequestedMonthTotals[10])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementUnderDevelopmentMonthTotals[10])}
                  &nbsp;h
                </div>
              </td>
              <td>
                <div className="text-right">Dec</div>
                <div className="text-right">
                  {Math.round(issueOpenedMonthTotals[11])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueRequiresClarificationMonthTotals[11])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueUnderEvaluationMonthTotals[11])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueConfirmedMonthTotals[11])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(issueResolvedMonthTotals[11])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementRequestedMonthTotals[11])}
                  &nbsp;h
                </div>
                <div className="text-right">
                  {Math.round(enhancementUnderDevelopmentMonthTotals[11])}
                  &nbsp;h
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const {
      organizations,
      totalOrganizations,
      projects,
      statusLog,
      page,
      isLoading,
      year,
    } = this.state;

    if (isLoading) {
      return <LoadingText />;
    }

    let totalEntries = 0;
    let totalPages = 1;

    if (totalOrganizations[0]) {
      totalEntries = parseInt(totalOrganizations[0].totalOrganizations, 10);
      totalPages = this.calculatePages(
        parseInt(totalOrganizations[0].totalOrganizations, 10),
      );
    }

    const isFirstPage = page - 1 === 0;
    const isLastPage = page === totalPages;

    const previousPage = [];
    const nextPage = [];

    if (totalPages < 3) {
      for (let i = page + 1; i < page + 2; i += 1) {
        nextPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }

      for (let i = page - 1; i < page; i += 1) {
        previousPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    } else if (page - 1 < 1) {
      for (let i = page + 1; i < page + 3; i += 1) {
        nextPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    } else if (page < totalPages) {
      for (let i = page - 1; i < page; i += 1) {
        previousPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }

      for (let i = page + 1; i < page + 2; i += 1) {
        nextPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    } else if (page === totalPages) {
      for (let i = page - 2; i < page; i += 1) {
        previousPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    }

    const yearSpan = [];

    for (let i = 2020; i <= new Date(Date.now()).getFullYear(); i += 1) {
      yearSpan.push(i);
    }

    return (
      <div className="fade-in container-fluid p-5">
        <div className="pt-4" />
        <div className="row">
          <h5 className="text-secondary">Monthly Statuses</h5>
        </div>
        {yearSpan.map((_) =>
          year === _ ? (
            <span key={_}>
              <button
                type="button"
                className="btn border btn-sm btn-info"
                onClick={() => this.setState({ year: _ })}
                disabled
              >
                {_}
              </button>
            </span>
          ) : (
            <span key={_}>
              <button
                type="button"
                className="btn border btn-sm btn-light"
                onClick={() => this.setState({ year: _ })}
              >
                {_}
              </button>
            </span>
          ),
        )}
        <br />
        <div className="row justify-content-md-center">
          <div className="col-12">
            <table className="table">
              <tbody>
                {organizations.map((org) => (
                  <tr key={org.organizationId}>
                    <td className="fade-in">
                      <b>{org.name}</b>
                      {this.calculateMonthlyTotalsPerOrganization(
                        year,
                        org.organizationId,
                        statusLog,
                      )}
                      {projects
                        .filter(
                          (proj) => proj.organizationId === org.organizationId,
                        )
                        .map((proj) => (
                          <div key={proj.projectId} className="px-5">
                            <ul className="text-secondary">
                              <li>
                                <b>
                                  &#91;
                                  {proj.code}
                                  &#93;&nbsp;
                                  {proj.name}
                                </b>
                              </li>
                            </ul>

                            {this.calculateMonthlyTotalsPerProject(
                              year,
                              proj.projectId,
                              statusLog,
                            )}
                          </div>
                        ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div>
          {totalEntries > 0 ? (
            <div>
              {isFirstPage && isLastPage ? (
                <div className="text-center">
                  <button
                    type="button"
                    className="btn border btn-light"
                    disabled
                  >
                    &lsaquo;
                  </button>
                  <button
                    type="button"
                    className="btn border btn-info"
                    disabled
                  >
                    {page}
                  </button>
                  <button
                    type="button"
                    className="btn border btn-light"
                    disabled
                  >
                    &rsaquo;
                  </button>
                </div>
              ) : (
                <div>
                  {isFirstPage ? (
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn border btn-light"
                        disabled
                      >
                        &lsaquo;
                      </button>
                      <button
                        type="button"
                        className="btn border btn-info"
                        disabled
                      >
                        {page}
                      </button>
                      {nextPage}
                      <button
                        type="button"
                        className="btn border btn-light"
                        onClick={() =>
                          this.setState({
                            page: page + 1,
                            rerender: true,
                          })
                        }
                      >
                        &rsaquo;
                      </button>
                    </div>
                  ) : (
                    <div>
                      {isLastPage ? (
                        <div className="text-center">
                          <button
                            type="button"
                            className="btn border btn-light"
                            onClick={() =>
                              this.setState({
                                page: page - 1,
                                rerender: true,
                              })
                            }
                          >
                            &lsaquo;
                          </button>
                          {previousPage}
                          <button
                            type="button"
                            className="btn border btn-info"
                            disabled
                          >
                            {page}
                          </button>
                          <button
                            type="button"
                            className="btn border btn-light"
                            disabled
                          >
                            &rsaquo;
                          </button>
                        </div>
                      ) : (
                        <div>
                          {!isFirstPage && !isLastPage ? (
                            <div className="text-center">
                              <button
                                type="button"
                                className="btn border btn-light"
                                onClick={() =>
                                  this.setState({
                                    page: page - 1,
                                    rerender: true,
                                  })
                                }
                              >
                                &lsaquo;
                              </button>
                              {previousPage}
                              <button
                                type="button"
                                className="btn border btn-info"
                                disabled
                              >
                                {page}
                              </button>
                              {nextPage}
                              <button
                                type="button"
                                className="btn border btn-light"
                                onClick={() =>
                                  this.setState({
                                    page: page + 1,
                                    rerender: true,
                                  })
                                }
                              >
                                &rsaquo;
                              </button>
                            </div>
                          ) : (
                            <div />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  }
}

MonthlyStatusesPage.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  accountType: PropTypes.string.isRequired,
  organizationId: PropTypes.number.isRequired,
};

export default MonthlyStatusesPage;
