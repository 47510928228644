import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import server from '../../server';

class EditAccountPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      organizations: [],
      getOrganizationOptionsRoute: `${server}/help-desk/account/get-organization-options`,
      editAccountRoute: `${server}/help-desk/account/edit-account`,
    };
  }

  componentDidMount() {
    const { getOrganizationOptionsRoute } = this.state;

    fetch(getOrganizationOptionsRoute, { method: 'POST' })
      .then((res) => res.json())
      .then((res) => this.setState({ organizations: res }));
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { editAccountRoute } = this.state;

    fetch(editAccountRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        accountId: event.target.accountId.value,
        type: event.target.type.value,
        organizationId: event.target.organizationId.value,
        email: event.target.email.value,
        name: event.target.name.value,
        position: event.target.position.value,
        mobile: event.target.mobile.value,
        landline: event.target.landline.value,
        subscriptionDuration: event.target.subscriptionDuration.value,
        // status: event.target.status.value,
      }),
    });

    function AccountEditedNotification() {
      return (
        <div className="alert alert-success">
          <span>Account edited.</span>
        </div>
      );
    }

    ReactDOM.render(
      <AccountEditedNotification />,
      document.getElementById('notification'),
    );

    setTimeout(() => {
      window.location.href = '/manage-accounts';
    }, 1000);
  }

  convertToLocalDate(date) {
    const localDate = new Date(date).toLocaleDateString('en-GB', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

    return localDate;
  }

  convertToLocalTime(time) {
    const localTime = new Date(time).toLocaleTimeString('en-GB');

    return localTime;
  }

  render() {
    const { account } = this.props;

    const {
      accountId = account.accountId,
      type = account.type,
      organizationId = account.organizationId,
      email = account.email,
      name = account.name,
      position = account.position,
      mobile = account.mobile,
      landline = account.landline,
      subscriptionStarted = account.subscriptionStarted,
      subscriptionDuration = account.subscriptionDuration,
    } = account;

    const { organizations } = this.state;

    return (
      <div className="fade-in centered-form mx-auto">
        <div className="pt-3 pl-3">
          <a href="manage-accounts" className="back-link text-secondary">
            <i className="fas fa-arrow-left back-link-icon" />
            &nbsp;Back
          </a>
        </div>
        <form onSubmit={this.handleSubmit.bind(this)}>
          <h5 className="text-center text-secondary">Edit Account</h5>
          <br />
          <input type="hidden" name="accountId" defaultValue={accountId} />
          <div className="form-group">
            <label htmlFor="type" className="col-12 text-secondary">
              Type
              <select
                id="type"
                className="form-control"
                name="type"
                defaultValue={type}
                onChange={this.handleChange.bind(this)}
              >
                <option value="User">User</option>
                <option value="Admin">Admin</option>
              </select>
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="organizationId" className="col-12 text-secondary">
              Organization
              <select
                id="organization-id"
                className="form-control"
                name="organizationId"
                defaultValue={organizationId}
                onChange={this.handleChange.bind(this)}
              >
                {organizations
                  .filter((org) => org.organizationId === organizationId)
                  .map((org) => (
                    <option key={org.organizationId} value={org.organizationId}>
                      {org.name}
                    </option>
                  ))}
                {organizations
                  .filter((org) => org.organizationId !== organizationId)
                  .map((org) => (
                    <option key={org.organizationId} value={org.organizationId}>
                      {org.name}
                    </option>
                  ))}
              </select>
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="email" className="col-12 text-secondary">
              Email
              <input
                type="text"
                id="email"
                className="form-control"
                name="email"
                defaultValue={email}
                onChange={this.handleChange.bind(this)}
                placeholder="Enter email"
              />
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="name" className="col-12 text-secondary">
              Name
              <input
                type="text"
                id="name"
                className="form-control"
                name="name"
                defaultValue={name}
                onChange={this.handleChange.bind(this)}
                placeholder="Enter name"
              />
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="position" className="col-12 text-secondary">
              Position
              <input
                type="text"
                id="position"
                className="form-control"
                name="position"
                defaultValue={position}
                onChange={this.handleChange.bind(this)}
                placeholder="Enter position"
              />
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="mobile" className="col-12 text-secondary">
              Mobile
              <input
                type="text"
                id="mobile"
                className="form-control"
                name="mobile"
                defaultValue={mobile}
                onChange={this.handleChange.bind(this)}
                placeholder="Enter mobile"
              />
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="landline" className="col-12 text-secondary">
              Landline
              <input
                type="text"
                id="landline"
                className="form-control"
                name="landline"
                defaultValue={landline}
                onChange={this.handleChange.bind(this)}
                placeholder="Enter landline"
              />
            </label>
          </div>
          <div className="form-group">
            <label
              htmlFor="subscriptionStarted"
              className="col-12 text-secondary"
            >
              Subscription Started
              <div>
                &ensp;
                {this.convertToLocalDate(subscriptionStarted)}
                &ensp;
                {this.convertToLocalTime(subscriptionStarted)}
              </div>
            </label>
          </div>
          <div className="form-group">
            <label
              htmlFor="subscriptionDuration"
              className="col-12 text-secondary"
            >
              Subscription Duration (Days)
              <input
                type="text"
                id="subscriptionDuration"
                className="form-control"
                name="subscriptionDuration"
                defaultValue={subscriptionDuration}
                onChange={this.handleChange.bind(this)}
                placeholder="Enter subscription duration"
              />
            </label>
          </div>
          {/* <div className="form-group">
            <label htmlFor="status" className="col-12 text-secondary">
              Status
              <select
                id="status"
                className="form-control"
                name="status"
                defaultValue={status}
                onChange={this.handleChange.bind(this)}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </label>
          </div> */}
          <br />
          <div className="text-center">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

EditAccountPage.propTypes = {
  organizationId: PropTypes.number.isRequired,
  account: PropTypes.shape({
    accountId: PropTypes.number,
    type: PropTypes.string,
    organizationId: PropTypes.number,
    email: PropTypes.string,
    name: PropTypes.string,
    position: PropTypes.string,
    mobile: PropTypes.string,
    landline: PropTypes.string,
    subscriptionStarted: PropTypes.string,
    subscriptionDuration: PropTypes.number,
    // status: PropTypes.string,
  }).isRequired,
};

export default EditAccountPage;
