import React from 'react';
import ReactDOM from 'react-dom';
import server from '../../server';

class AddOrganizationPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      addOrganizationRoute: `${server}/help-desk/organization/add-organization`,
    };
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { name, addOrganizationRoute } = this.state;

    function EmptyFieldsNotification() {
      return (
        <div className="alert alert-warning">
          <span>Enter the required data.</span>
        </div>
      );
    }

    function OrganizationAddedNotification() {
      return (
        <div className="alert alert-success">
          <span>Organization added.</span>
        </div>
      );
    }

    if (name === '') {
      ReactDOM.render(
        <EmptyFieldsNotification />,
        document.getElementById('notification'),
      );
    } else {
      fetch(addOrganizationRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          type: event.target.type.value,
          name: event.target.name.value,
          address: event.target.address.value,
          email: event.target.email.value,
          mobile: event.target.mobile.value,
          landline: event.target.landline.value,
        }),
      });

      ReactDOM.render(
        <OrganizationAddedNotification />,
        document.getElementById('notification'),
      );

      setTimeout(() => {
        window.location.href = '/manage-organizations';
      }, 1000);
    }
  }

  render() {
    return (
      <div className="fade-in centered-form mx-auto">
        <div className="pt-3 pl-3">
          <a href="manage-organizations" className="back-link text-secondary">
            <i className="fas fa-arrow-left back-link-icon" />
            &nbsp;Back
          </a>
        </div>
        <form onSubmit={this.handleSubmit.bind(this)}>
          <h5 className="text-center text-secondary">Add Organization</h5>
          <br />
          <div className="form-group">
            <label htmlFor="type" className="col-12 text-secondary">
              Type
              <select
                id="type"
                className="form-control"
                name="type"
                onChange={this.handleChange.bind(this)}
              >
                <option value="Client">Client</option>
                <option value="Affiliate">Affiliate</option>
              </select>
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="name" className="col-12 text-secondary">
              Name
              <input
                type="text"
                id="name"
                className="form-control"
                name="name"
                onChange={this.handleChange.bind(this)}
                placeholder="Enter name"
              />
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="address" className="col-12 text-secondary">
              Address
              <input
                type="text"
                id="address"
                className="form-control"
                name="address"
                onChange={this.handleChange.bind(this)}
                placeholder="Enter address"
              />
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="email" className="col-12 text-secondary">
              Email
              <input
                type="text"
                id="email"
                className="form-control"
                name="email"
                onChange={this.handleChange.bind(this)}
                placeholder="Enter email"
              />
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="mobile" className="col-12 text-secondary">
              Mobile
              <input
                type="text"
                id="mobile"
                className="form-control"
                name="mobile"
                onChange={this.handleChange.bind(this)}
                placeholder="Enter mobile"
              />
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="landline" className="col-12 text-secondary">
              Landline
              <input
                type="text"
                id="landline"
                className="form-control"
                name="landline"
                onChange={this.handleChange.bind(this)}
                placeholder="Enter landline"
              />
            </label>
          </div>
          <br />
          <div className="text-center">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default AddOrganizationPage;
