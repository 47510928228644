import React from 'react';
import PropTypes from 'prop-types';
import LoadingText from '../common/LoadingText';

class StatusText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  render() {
    const { status } = this.props;

    const { isLoading } = this.state;

    if (isLoading) {
      return <LoadingText />;
    }

    switch (status) {
      case 'Issue opened: waiting for agent response':
        return (
          <span>
            <i className="red-500 fa fa-square" />
            &ensp;
            {status}
          </span>
        );
      case 'Issue requires clarification: waiting for client response':
        return (
          <span>
            <i className="orange-500 fa fa-square" />
            &ensp;
            {status}
          </span>
        );
      case 'Issue under evaluation: waiting for developer feedback':
        return (
          <span>
            <i className="yellow-500 fa fa-square" />
            &ensp;
            {status}
          </span>
        );
      case 'Issue confirmed: waiting for developer patch':
        return (
          <span>
            <i className="green-500 fa fa-square" />
            &ensp;
            {status}
          </span>
        );
      case 'Issue resolved: waiting for client confirmation':
        return (
          <span>
            <i className="blue-500 fa fa-square" />
            &ensp;
            {status}
          </span>
        );
      case 'Issue closed':
        return (
          <span>
            <i className="gray-500 fa fa-square" />
            &ensp;
            {status}
          </span>
        );
      case 'Enhancement requested: waiting for developer confirmation':
        return (
          <span>
            <i className="indigo-500 fa fa-square" />
            &ensp;
            {status}
          </span>
        );
      case 'Enhancement under development: waiting for developer patch':
        return (
          <span>
            <i className="purple-500 fa fa-square" />
            &ensp;
            {status}
          </span>
        );
      case 'Enhancement completed':
        return (
          <span>
            <i className="brown-500 fa fa-square" />
            &ensp;
            {status}
          </span>
        );
      default:
        return <span>{status}</span>;
    }
  }
}

StatusText.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusText;
