import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './ControlPanel.css';
import StartConversationPage from '../conversation/StartConversationPage';
import ManageConversationsPage from '../conversation/ManageConversationsPage';
import CreateAccountPage from '../account/CreateAccountPage';
import ManageAccountsPage from '../account/ManageAccountsPage';
import AddOrganizationPage from '../organization/AddOrganizationPage';
import ManageOrganizationsPage from '../organization/ManageOrganizationsPage';
import MonthlyConversationsPage from '../statistics/MonthlyConversationsPage';
import MonthlyStatusesPage from '../statistics/MonthlyStatusesPage';
import LifetimeConversationsPage from '../statistics/LifetimeConversationsPage';
import LifetimeStatusesPage from '../statistics/LifetimeStatusesPage';
import NotificationDropdownButton from '../notification/NotificationDropdownButton';
import StatusText from '../notification/StatusText';
import ProfilePage from '../user/ProfilePage';
import ChangePasswordPage from '../user/ChangePasswordPage';
import ConversationPage from '../conversation/ConversationPage';
import NotificationsPage from '../notification/NotificationsPage';
import server from '../../server';
import socket from '../../socket';

class ControlPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      account: [],
      conversation: [],
      renderConversationPage: false,
      getAccountRoute: `${server}/help-desk/account/get-account`,
      getConversationRoute: `${server}/help-desk/conversation/get-conversation`,
      logoutBroadcastRoute: `${server}/help-desk/broadcast/logout-broadcast`,
    };
  }

  componentDidMount() {
    const { accountId } = this.props;

    const { getAccountRoute } = this.state;

    fetch(getAccountRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        accountId,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ account: res }));
  }

  componentDidUpdate() {
    const { organizationId } = this.props;

    const { account, getConversationRoute } = this.state;

    const getConversation = (conversationId) => {
      if (account[0]) {
        fetch(getConversationRoute, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            conversationId,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            this.setState({ renderConversationPage: true, conversation: res });
          });
      }
    };

    let type = '';

    if (account[0]) {
      type = account[0].type;
    }

    socket.on('start-conversation', (emit) => {
      function StartConversationNotification() {
        return (
          <div
            className="notification clickable-alert alert alert-info"
            onClick={() => getConversation(emit.conversationId)}
          >
            <div>
              <b className="text-info">{emit.name}</b>
              &nbsp;started a conversation in&nbsp;
              <b className="text-info">
                {emit.reference}
                &#58;
              </b>
            </div>
            <div className="truncated-alert">
              <b>{emit.title}</b>
            </div>
          </div>
        );
      }

      if (type === 'Admin') {
        ReactDOM.render(
          <StartConversationNotification />,
          document.getElementById('notification'),
        );
      }
    });

    socket.on('send-message', (emit) => {
      function SendMessageNotification() {
        return (
          <div
            className="notification clickable-alert alert alert-info"
            onClick={() => getConversation(emit.conversationId)}
          >
            <div>
              <b className="text-info">{emit.name}</b>
              &nbsp;sent a message in&nbsp;
              <b className="text-info">
                {emit.reference}
                &#58;
              </b>
            </div>
            <div className="truncated-alert">
              <i>{emit.message}</i>
            </div>
          </div>
        );
      }

      if (
        type === 'Admin' ||
        emit.organizationId.toString() === organizationId.toString()
      ) {
        ReactDOM.render(
          <SendMessageNotification />,
          document.getElementById('notification'),
        );
      }
    });

    socket.on('change-status', (emit) => {
      function ChangeStatusNotification() {
        return (
          <div
            className="notification clickable-alert alert alert-info"
            onClick={() => getConversation(emit.conversationId)}
          >
            <span>
              <b className="text-info">{emit.name}</b>
              &nbsp;changed the status of&nbsp;
              <b className="text-info">{emit.reference}</b>
              :
              <br />
              <div>
                <StatusText statusId={emit.statusId} />
              </div>
            </span>
          </div>
        );
      }

      if (
        type === 'Admin' ||
        emit.organizationId.toString() === organizationId.toString()
      ) {
        ReactDOM.render(
          <ChangeStatusNotification />,
          document.getElementById('notification'),
        );
      }
    });
  }

  getConversation(conversationId) {
    const { account, getConversationRoute } = this.state;

    if (account[0]) {
      fetch(getConversationRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          conversationId,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          this.setState({ conversation: res });
        });
    }
  }

  convertToLocalDate(date) {
    const localDate = new Date(date).toLocaleDateString('en-GB', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

    return localDate;
  }

  convertToLocalTime(time) {
    const localTime = new Date(time).toLocaleTimeString('en-GB');

    return localTime;
  }

  checkAccountStatus(date, duration) {
    const subscriptionExpiration = new Date(
      new Date(date).setDate(new Date(date).getDate() + duration),
    );

    const currentDate = new Date();

    let accountStatus = '';

    switch (true) {
      case currentDate < subscriptionExpiration:
        accountStatus = 'Active';

        return accountStatus;

      case currentDate > subscriptionExpiration:
        accountStatus = 'Inactive';

        return accountStatus;

      default:
        accountStatus = 'Active';

        return accountStatus;
    }
  }

  removeMainContent() {
    ReactDOM.render(<div />, document.getElementById('main'));
  }

  handleLogout() {
    const { account, logoutBroadcastRoute } = this.state;

    localStorage.removeItem('accountId');
    localStorage.removeItem('organizationId');
    localStorage.removeItem('email');
    localStorage.removeItem('name');
    localStorage.removeItem('token');

    fetch(logoutBroadcastRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        accountId: account[0].accountId,
        organizationId: account[0].organizationId,
        email: account[0].email,
        name: account[0].name,
      }),
    });

    socket.emit('logout', {
      accountId: account[0].accountId,
      organizationId: account[0].organizationId,
      email: account[0].email,
      name: account[0].name,
    });

    function LogoutNotification() {
      return (
        <div className="alert alert-success">
          <span>Logged out.</span>
        </div>
      );
    }

    ReactDOM.render(
      <LogoutNotification />,
      document.getElementById('notification'),
    );

    setTimeout(() => {
      window.location.href = '/';
    }, 1000);
  }

  render() {
    const { accountId, organizationId, email } = this.props;

    const { account, conversation, renderConversationPage } = this.state;

    if (account[0]) {
      const {
        type: accountType,
        subscriptionStarted,
        subscriptionDuration,
      } = account[0];

      return (
        <div>
          {accountType === 'Admin' ? (
            <div>
              <div className="control-panel container-fluid bg-dark">
                <div className="row">
                  {/* <div className="header">
                    <span className="left-header-block">
                      <div className="new-btn">
                        <button
                          type="button"
                          className="btn btn-sm btn-info"
                          data-toggle="dropdown"
                        >
                          <i className="fa fa-plus" />
                          &nbsp;New
                        </button>
                        <div className="new-dropdown dropdown">
                          <div className="dropdown-menu">
                            <a
                              href="/start-conversation"
                              className="dropdown-button dropdown-item btn btn-light text-secondary"
                            >
                              <div className="text-center">
                                <i className="fa fa-comments fa-2x text-secondary" />
                                <div className="text-center">Conversation</div>
                              </div>
                            </a>
                            <a
                              href="/manage-conversations"
                              className="dropdown-button dropdown-item btn btn-light text-secondary"
                            >
                              <div className="text-center">
                                <i className="fa fa-user fa-2x text-secondary" />
                                <div className="text-center">User</div>
                              </div>
                            </a>
                            <a
                              href="/manage-conversations"
                              className="dropdown-button dropdown-item btn btn-light text-secondary"
                            >
                              <div className="text-center">
                                <i className="fa fa-building fa-2x text-secondary" />
                                <div className="text-center">Organization</div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                  <div>
                    <span className="right-header-block">
                      <div className="search-bar">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text bg-light">
                              <i className="fa fa-search text-secondary" />
                            </span>
                          </div>
                          <input
                            id="search-bar"
                            className="form-control"
                            name="searchString"
                            placeholder="Search"
                          />
                        </div>
                      </div>
                      <div className="notification-icon">
                        <i className="fa fa-bell-o text-secondary fa-lg" />
                      </div>
                      <div className="user-icon bg-secondary">
                        <i className="fa fa-user text-light fa-lg" />
                      </div>
                    </span>
                  </div> */}
                  <div className="col-9">
                    <div className="row">
                      <div className="ml-5" />
                      <div className="ml-2" />
                      <div className="dropdown">
                        <button
                          type="button"
                          className="control-panel-btn btn btn-dark text-light"
                          data-toggle="dropdown"
                        >
                          <i className="fa fa-comments fa-lg" />
                          &ensp;Conversation
                        </button>
                        <div className="dropdown-menu bg-dark">
                          {this.checkAccountStatus(
                            subscriptionStarted,
                            subscriptionDuration,
                          ) === 'Active' && (
                            <a
                              href="/start-conversation"
                              className="dropdown-item btn btn-dark text-light"
                            >
                              Start Conversation
                            </a>
                          )}
                          <a
                            href="/manage-conversations"
                            className="dropdown-item btn btn-dark text-light"
                          >
                            Manage Conversations
                          </a>
                        </div>
                      </div>
                      <div className="dropdown">
                        <button
                          type="button"
                          className="control-panel-btn btn btn-dark text-light"
                          data-toggle="dropdown"
                        >
                          <i className="fa fa-id-card fa-lg" />
                          &ensp;Account
                        </button>
                        <div className="dropdown-menu bg-dark">
                          <a
                            href="/create-account"
                            className="dropdown-item btn btn-dark text-light"
                          >
                            Create Account
                          </a>
                          <a
                            href="/manage-accounts"
                            className="dropdown-item btn btn-dark text-light"
                          >
                            Manage Accounts
                          </a>
                        </div>
                      </div>
                      <div className="dropdown">
                        <button
                          type="button"
                          className="control-panel-btn btn btn-dark text-light"
                          data-toggle="dropdown"
                        >
                          <i className="fa fa-building fa-lg" />
                          &ensp;Organization
                        </button>
                        <div className="dropdown-menu bg-dark">
                          <a
                            href="/add-organization"
                            className="dropdown-item btn btn-dark text-light"
                          >
                            Add Organization
                          </a>
                          <a
                            href="/manage-organizations"
                            className="dropdown-item btn btn-dark text-light"
                          >
                            Manage Organizations
                          </a>
                        </div>
                      </div>
                      <div className="dropdown">
                        <button
                          type="button"
                          className="control-panel-btn btn btn-dark text-light"
                          data-toggle="dropdown"
                        >
                          <i className="fa fa-chart-bar fa-lg" />
                          &ensp;Statistics
                        </button>
                        <div className="dropdown-menu bg-dark">
                          <a
                            href="/monthly-conversations"
                            className="dropdown-item btn btn-dark text-light"
                          >
                            Monthly Conversations
                          </a>
                          <a
                            href="/monthly-statuses"
                            className="dropdown-item btn btn-dark text-light"
                          >
                            Monthly Statuses
                          </a>
                          <a
                            href="/lifetime-conversations"
                            className="dropdown-item btn btn-dark text-light"
                          >
                            Lifetime Conversations
                          </a>
                          <a
                            href="/lifetime-statuses"
                            className="dropdown-item btn btn-dark text-light"
                          >
                            Lifetime Statuses
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="row float-right">
                      <NotificationDropdownButton
                        accountId={accountId}
                        organizationId={organizationId}
                        setConversationState={(_) =>
                          this.setState({
                            conversation: _,
                          })
                        }
                        setRenderConversationState={(_) =>
                          this.setState({
                            renderConversationPage: _,
                          })
                        }
                      />
                      <div className="dropdown">
                        <button
                          type="button"
                          className="control-panel-btn btn btn-dark text-light"
                          data-toggle="dropdown"
                        >
                          <i className="fa fa-user fa-lg" />
                          &ensp;
                          {email}
                        </button>
                        <div className="dropdown-menu dropdown-menu-right bg-dark">
                          <a
                            href="/profile"
                            className="dropdown-item btn btn-dark text-light"
                          >
                            Profile
                          </a>
                          <a
                            href="/change-password"
                            className="dropdown-item btn btn-dark text-light"
                          >
                            Change Password
                          </a>
                          <a
                            className="dropdown-item btn btn-dark text-light"
                            onClick={this.handleLogout.bind(this)}
                          >
                            Logout
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {renderConversationPage ? (
                <ConversationPage
                  currentUserId={accountId}
                  accountType={account[0].type}
                  organizationId={organizationId}
                  conversation={conversation[0]}
                />
              ) : (
                <Router>
                  <Switch>
                    <Route path="/start-conversation">
                      <StartConversationPage
                        currentUserId={accountId}
                        accountType={account[0].type}
                        organizationId={account[0].organizationId}
                        accountStatus={this.checkAccountStatus(
                          subscriptionStarted,
                          subscriptionDuration,
                        )}
                      />
                    </Route>
                    <Route path="/manage-conversations">
                      <ManageConversationsPage
                        currentUserId={accountId}
                        accountType={account[0].type}
                        organizationId={account[0].organizationId}
                      />
                    </Route>
                    <Route path="/create-account">
                      <CreateAccountPage
                        currentUserId={accountId}
                        accountType={account[0].type}
                        organizationId={account[0].organizationId}
                      />
                    </Route>
                    <Route path="/manage-accounts">
                      <ManageAccountsPage
                        currentUserId={accountId}
                        accountType={account[0].type}
                        organizationId={account[0].organizationId}
                      />
                    </Route>
                    <Route path="/add-organization">
                      <AddOrganizationPage
                        currentUserId={accountId}
                        accountType={account[0].type}
                        organizationId={account[0].organizationId}
                      />
                    </Route>
                    <Route path="/manage-organizations">
                      <ManageOrganizationsPage
                        currentUserId={accountId}
                        accountType={account[0].type}
                        organizationId={account[0].organizationId}
                      />
                    </Route>
                    <Route path="/monthly-conversations">
                      <MonthlyConversationsPage
                        currentUserId={accountId}
                        accountType={account[0].type}
                        organizationId={account[0].organizationId}
                      />
                    </Route>
                    <Route path="/monthly-statuses">
                      <MonthlyStatusesPage
                        currentUserId={accountId}
                        accountType={account[0].type}
                        organizationId={account[0].organizationId}
                      />
                    </Route>
                    <Route path="/lifetime-conversations">
                      <LifetimeConversationsPage
                        currentUserId={accountId}
                        accountType={account[0].type}
                        organizationId={account[0].organizationId}
                      />
                    </Route>
                    <Route path="/lifetime-statuses">
                      <LifetimeStatusesPage
                        currentUserId={accountId}
                        accountType={account[0].type}
                        organizationId={account[0].organizationId}
                      />
                    </Route>
                    <Route path="/notifications">
                      <NotificationsPage
                        accountId={accountId}
                        organizationId={organizationId}
                      />
                    </Route>
                    <Route path="/profile">
                      <ProfilePage accountId={accountId} />
                    </Route>
                    <Route path="/change-password">
                      <ChangePasswordPage accountId={accountId} email={email} />
                    </Route>
                    <Route path="/">
                      <ManageConversationsPage
                        currentUserId={accountId}
                        accountType={account[0].type}
                        organizationId={account[0].organizationId}
                      />
                    </Route>
                  </Switch>
                </Router>
              )}
            </div>
          ) : (
            <div>
              <div className="control-panel container-fluid bg-dark">
                <div className="row">
                  {/* <div className="header">
                    <span className="left-header-block">
                      <div className="new-btn">
                        <button
                          type="button"
                          className="btn btn-sm btn-info"
                          data-toggle="dropdown"
                        >
                          <i className="fa fa-plus" />
                          &nbsp;New
                        </button>
                        <div className="new-dropdown dropdown">
                          <div className="dropdown-menu">
                            <a
                              href="/start-conversation"
                              className="dropdown-button dropdown-item btn btn-light text-secondary"
                            >
                              <div className="text-center">
                                <i className="fa fa-comments fa-2x text-secondary" />
                                <div className="text-center">Conversation</div>
                              </div>
                            </a>
                            <a
                              href="/manage-conversations"
                              className="dropdown-button dropdown-item btn btn-light text-secondary"
                            >
                              <div className="text-center">
                                <i className="fa fa-user fa-2x text-secondary" />
                                <div className="text-center">User</div>
                              </div>
                            </a>
                            <a
                              href="/manage-conversations"
                              className="dropdown-button dropdown-item btn btn-light text-secondary"
                            >
                              <div className="text-center">
                                <i className="fa fa-building fa-2x text-secondary" />
                                <div className="text-center">Organization</div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </span>
                    <div>
                      <span className="right-header-block">
                        <div className="search-bar">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text bg-light">
                                <i className="fa fa-search text-secondary" />
                              </span>
                            </div>
                            <input
                              id="search-bar"
                              className="form-control"
                              name="searchString"
                              placeholder="Search"
                            />
                          </div>
                        </div>
                        <div className="notification-icon">
                          <i className="fa fa-bell-o text-secondary fa-lg" />
                        </div>
                        <div className="user-icon bg-secondary">
                          <i className="fa fa-user text-light fa-lg" />
                        </div>
                      </span>
                    </div>
                  </div> */}
                  <div className="col-9">
                    <div className="row btn-group">
                      <div className="ml-5" />
                      <div className="ml-2" />
                      <div className="dropdown">
                        <button
                          type="button"
                          className="control-panel-btn btn btn-dark text-light"
                          data-toggle="dropdown"
                        >
                          <i className="fa fa-comments fa-lg" />
                          &ensp;Conversations
                        </button>
                        <div className="dropdown-menu bg-dark">
                          {this.checkAccountStatus(
                            subscriptionStarted,
                            subscriptionDuration,
                          ) === 'Active' && (
                            <a
                              href="/start-conversation"
                              className="dropdown-item btn btn-dark text-light"
                            >
                              Start Conversation
                            </a>
                          )}
                          <a
                            href="/manage-conversations"
                            className="dropdown-item btn btn-dark text-light"
                          >
                            Manage Conversations
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="row float-right">
                      <NotificationDropdownButton
                        accountId={accountId}
                        organizationId={organizationId}
                        setConversationState={(_) =>
                          this.setState({
                            conversation: _,
                          })
                        }
                        setRenderConversationState={(_) =>
                          this.setState({ renderConversationPage: _ })
                        }
                      />
                      <div className="dropdown">
                        <button
                          type="button"
                          className="control-panel-btn btn btn-dark text-light"
                          data-toggle="dropdown"
                        >
                          <i className="fa fa-user fa-lg" />
                          &ensp;
                          {email}
                        </button>
                        <div className="dropdown-menu dropdown-menu-right bg-dark">
                          <a
                            href="/profile"
                            className="dropdown-item btn btn-dark text-light"
                          >
                            Profile
                          </a>
                          <a
                            href="/change-password"
                            className="dropdown-item btn btn-dark text-light"
                          >
                            Change Password
                          </a>
                          <a
                            className="dropdown-item btn btn-light text-light"
                            onClick={this.handleLogout.bind(this)}
                          >
                            Logout
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {renderConversationPage ? (
                <ConversationPage
                  currentUserId={accountId}
                  accountType={account[0].type}
                  organizationId={organizationId}
                  conversation={conversation[0]}
                />
              ) : (
                <Router>
                  <Switch>
                    <Route path="/start-conversation">
                      <StartConversationPage
                        currentUserId={accountId}
                        accountType={account[0].type}
                        organizationId={account[0].organizationId}
                        accountStatus={this.checkAccountStatus(
                          subscriptionStarted,
                          subscriptionDuration,
                        )}
                      />
                    </Route>
                    <Route path="/manage-conversations">
                      <ManageConversationsPage
                        currentUserId={accountId}
                        accountType={account[0].type}
                        organizationId={account[0].organizationId}
                      />
                    </Route>
                    <Route path="/notifications">
                      <NotificationsPage
                        accountId={accountId}
                        organizationId={organizationId}
                      />
                    </Route>
                    <Route path="/profile">
                      <ProfilePage accountId={accountId} />
                    </Route>
                    <Route path="/change-password">
                      <ChangePasswordPage accountId={accountId} email={email} />
                    </Route>
                    <Route path="/">
                      <ManageConversationsPage
                        currentUserId={accountId}
                        accountType={account[0].type}
                        organizationId={account[0].organizationId}
                      />
                    </Route>
                  </Switch>
                </Router>
              )}
            </div>
          )}
        </div>
      );
    }
    return <div />;
  }
}

ControlPanel.propTypes = {
  accountId: PropTypes.number.isRequired,
  organizationId: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
};

export default ControlPanel;
