import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ConversationPage from './ConversationPage';
import server from '../../server';
import socket from '../../socket';

class StartConversationPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      account: [],
      projects: [],
      conversation: {},
      renderConversationPage: false,
      getAccountRoute: `${server}/help-desk/account/get-account`,
      getProjectOptionsRoute: `${server}/help-desk/conversation/get-project-options`,
      startConversationRoute: `${server}/help-desk/conversation/start-conversation`,
      startConversationBroadcastRoute: `${server}/help-desk/broadcast/start-conversation-broadcast`,
      getNewestConversation: `${server}/help-desk/conversation/get-newest-conversation`,
    };
  }

  componentDidMount() {
    const { currentUserId, accountType, organizationId } = this.props;

    const { getAccountRoute, getProjectOptionsRoute } = this.state;

    fetch(getAccountRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        accountId: currentUserId,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ account: res }));

    fetch(getProjectOptionsRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        currentUserId,
        accountType,
        organizationId,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ projects: res }));
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  generateReference(projectId) {
    const { projects } = this.state;

    const [selectedProject] = projects.filter(
      (project) => project.projectId.toString() === projectId,
    );

    const { code, totalTickets } = selectedProject;

    return `HD-${code}-${Number(totalTickets) + 1}`;
  }

  handleSubmit(event) {
    event.preventDefault();

    const { currentUserId } = this.props;

    const {
      account,
      startConversationRoute,
      startConversationBroadcastRoute,
      getNewestConversation,
    } = this.state;

    const { name } = account[0];

    const organizationId = event.target.organizationId.value;
    const projectId = event.target.projectId.value;
    const reference = this.generateReference(event.target.projectId.value);
    const type = event.target.type.value;
    const title = event.target.title.value;
    const authorId = currentUserId;
    const description = event.target.description.value;

    const attachmentInput = document.getElementById('attachment');

    let attachment = '';

    if (attachmentInput.files) {
      const file = attachmentInput.files[0];

      attachment = file;
    }

    const formData = new FormData();

    function EmptyFieldsNotification() {
      return (
        <div className="alert alert-warning">
          <span>Enter the required data.</span>
        </div>
      );
    }

    function ConversationStartedNotification() {
      return (
        <div className="alert alert-success">
          <span>Conversation started.</span>
        </div>
      );
    }

    formData.append('organizationId', organizationId.toString());
    formData.append('projectId', projectId.toString());
    formData.append('reference', reference);
    formData.append('type', type);
    formData.append('authorId', authorId.toString());
    formData.append('title', title);
    formData.append('description', description);
    formData.append('attachment', attachment);

    if (projectId === '' || title === '' || description === '') {
      ReactDOM.render(
        <EmptyFieldsNotification />,
        document.getElementById('notification'),
      );
    } else {
      fetch(startConversationRoute, {
        method: 'POST',
        body: formData,
      }).then(() => {
        fetch(getNewestConversation, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            authorId: currentUserId,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            this.setState({
              conversation: res[0],
              renderConversationPage: true,
            });

            fetch(startConversationBroadcastRoute, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                accountId: currentUserId,
                organizationId,
                conversationId: res[0].conversationId,
              }),
            });

            socket.emit('start-conversation', {
              currentUserId,
              name,
              conversationId: res[0].conversationId,
              organizationId: res[0].organizationId,
              projectId: res[0].projectId,
              reference: res[0].reference,
              type: res[0].type,
              title: res[0].title,
              authorId: res[0].authorId,
              statusId: res[0].statusId,
              description: res[0].description,
              attachment: res[0].attachment,
              dateCreated: res[0].dateCreated,
            });
          });
      });

      ReactDOM.render(
        <ConversationStartedNotification />,
        document.getElementById('notification'),
      );
    }
  }

  render() {
    const {
      currentUserId,
      accountType,
      accountStatus,
      organizationId,
    } = this.props;

    const { projects, conversation, renderConversationPage } = this.state;

    if (accountStatus === 'Inactive') {
      return (
        <div className="fade-in text-center text-danger">
          <div className="pt-5" />
          <div className="pt-5" />
          Subscription expired.
        </div>
      );
    }

    return (
      <div>
        {renderConversationPage ? (
          <ConversationPage
            currentUserId={currentUserId}
            accountType={accountType}
            organizationId={organizationId}
            conversation={conversation}
          />
        ) : (
          <div className="fade-in centered-form mx-auto">
            <div className="pt-3 pl-3">
              <a
                href="manage-conversations"
                className="back-link text-secondary"
              >
                <i className="fas fa-arrow-left back-link-icon" />
                &nbsp;Back
              </a>
            </div>
            <form onSubmit={this.handleSubmit.bind(this)}>
              <h5 className="text-center text-secondary">Start Conversation</h5>
              <br />
              <input
                type="hidden"
                name="organizationId"
                defaultValue={organizationId}
              />
              <div className="form-group">
                <label htmlFor="project-id" className="col-12 text-secondary">
                  Project
                  <select
                    id="project-id"
                    className="form-control"
                    name="projectId"
                    onChange={this.handleChange.bind(this)}
                  >
                    {projects.map((project) => (
                      <option key={project.projectId} value={project.projectId}>
                        {project.name}
                        &ensp;&#40;HD&#45;
                        {project.code}
                        &#45;
                        {project.totalTickets + 1}
                        &#41;
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="type" className="col-12 text-secondary">
                  Type
                  <select
                    id="type"
                    className="form-control"
                    name="type"
                    onChange={this.handleChange.bind(this)}
                  >
                    <option value="Bug">Bug</option>
                    <option value="Enhancement Request">
                      Enhancement Request
                    </option>
                    <option value="Question">Question</option>
                    <option value="Task">Task</option>
                  </select>
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="title" className="col-12 text-secondary">
                  Title
                  <input
                    type="text"
                    id="title"
                    className="form-control"
                    name="title"
                    onChange={this.handleChange.bind(this)}
                    placeholder="Enter title"
                  />
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="description" className="col-12 text-secondary">
                  Description
                  <textarea
                    rows={4}
                    id="description"
                    className="form-control"
                    name="description"
                    onChange={this.handleChange.bind(this)}
                    placeholder="Enter description"
                  />
                </label>
              </div>
              <div className="form-group">
                <div className="text-center">
                  <div className="row">
                    <div className="col-12">
                      <i className="fa fa-paperclip" />
                      &ensp;
                      <input
                        type="file"
                        id="attachment"
                        className="mb-4"
                        name="attachment"
                      />
                    </div>
                  </div>
                </div>
                <br />
              </div>
              <div className="text-center">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

StartConversationPage.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  accountType: PropTypes.string.isRequired,
  accountStatus: PropTypes.string.isRequired,
  organizationId: PropTypes.number.isRequired,
};

export default StartConversationPage;
