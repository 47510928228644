const env = 'PROD';
let server;

if (env === 'DEV') {
  server = 'http://localhost:4000';
}

if (env === 'PROD') {
  server = 'https://help.oojeema.com:4000';
}

export default server;
