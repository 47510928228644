import React from 'react';
import PropTypes from 'prop-types';
import LoadingText from '../common/LoadingText';
import server from '../../server';

class MessageDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      downloadAttachmentRoute: `${server}/help-desk/conversation/download-attachment`,
    };
  }

  convertToLocalDate(date) {
    const localDate = new Date(date).toLocaleDateString('en-GB', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

    return localDate;
  }

  convertToLocalTime(time) {
    const localTime = new Date(time).toLocaleTimeString('en-GB');

    return localTime;
  }

  displayAttachment(attachment) {
    const { senderId } = this.props;

    const { downloadAttachmentRoute } = this.state;

    if (attachment) {
      return (
        <form method="POST" action={downloadAttachmentRoute}>
          <input type="hidden" name="senderId" defaultValue={senderId} />
          <input type="hidden" name="attachment" defaultValue={attachment} />
          <i className="fa fa-paperclip" />
          &ensp;
          <button type="submit" className="btn btn-light">
            {attachment.substring(20)}
          </button>
        </form>
      );
    }

    return <div />;
  }

  render() {
    const {
      currentUserId,
      senderId,
      name,
      message,
      attachment,
      timestamp,
    } = this.props;

    const { isLoading } = this.state;

    if (isLoading) {
      return (
        <div className="m-5">
          <LoadingText />
        </div>
      );
    }

    let isSentByCurrentUser = false;

    if (senderId.toString() === currentUserId.toString()) {
      isSentByCurrentUser = true;
    }

    return isSentByCurrentUser ? (
      <div className="fade-in p-3 bg-light">
        <span>
          <b className="py-2 text-info">{name}</b>
          <small className="text-secondary">
            &ensp;
            {this.convertToLocalDate(timestamp)}
            &ensp;
            {this.convertToLocalTime(timestamp)}
          </small>
        </span>
        <div className="mt-2">{message}</div>
        <div className="mt-2">{this.displayAttachment(attachment)}</div>
      </div>
    ) : (
      <div className="fade-in p-3 bg-light">
        <span>
          <b className="py-2 text-secondary">{name}</b>
          <small className="text-secondary">
            &ensp;
            {this.convertToLocalDate(timestamp)}
            &ensp;
            {this.convertToLocalTime(timestamp)}
          </small>
        </span>
        <div className="mt-2">{message}</div>
        <div className="mt-2">{this.displayAttachment(attachment)}</div>
      </div>
    );
  }
}

MessageDisplay.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  senderId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  attachment: PropTypes.string,
  timestamp: PropTypes.string.isRequired,
};

MessageDisplay.defaultProps = {
  attachment: '',
};

export default MessageDisplay;
