import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './ManageConversationsPage.css';
import LastMessageDisplay from './LastMessageDisplay';
import ConversationPage from './ConversationPage';
import StatusText from './StatusText';
import LoadingText from '../common/LoadingText';
import server from '../../server';

class ManageConversationsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      conversation: {},
      conversations: [],
      totalConversations: [],
      organizations: [],
      organizationFilterString: '',
      statusFilterString: '',
      searchString: '',
      page: 1,
      isLoading: false,
      renderConversationPage: false,
      rerender: false,
      getConversationsRoute: `${server}/help-desk/conversation/get-conversations`,
      getTotalConversationsRoute: `${server}/help-desk/conversation/get-total-conversations`,
      exportConversationsRoute: `${server}/help-desk/conversation/export-conversations`,
      getOrganizationOptionsRoute: `${server}/help-desk/account/get-organization-options`,
    };
  }

  componentDidMount() {
    const { currentUserId, accountType, organizationId } = this.props;

    const {
      organizationFilterString,
      statusFilterString,
      searchString,
      page,
      getOrganizationOptionsRoute,
      getConversationsRoute,
      getTotalConversationsRoute,
    } = this.state;

    this.setState({ isLoading: true });

    fetch(getOrganizationOptionsRoute, { method: 'POST' })
      .then((res) => res.json())
      .then((res) => this.setState({ organizations: res }));

    fetch(getConversationsRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        currentUserId,
        accountType,
        organizationId,
        organizationFilterString,
        statusFilterString,
        searchString,
        page,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ conversations: res, isLoading: false }));

    fetch(getTotalConversationsRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        currentUserId,
        accountType,
        organizationId,
        organizationFilterString,
        statusFilterString,
        searchString,
        page,
      }),
    })
      .then((res) => res.json())
      .then((res) =>
        this.setState({ totalConversations: res, isLoading: false }),
      );
  }

  componentDidUpdate() {
    const { currentUserId, accountType, organizationId } = this.props;

    const {
      organizationFilterString,
      statusFilterString,
      searchString,
      page,
      rerender,
      getConversationsRoute,
      getTotalConversationsRoute,
    } = this.state;

    if (rerender) {
      fetch(getConversationsRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          currentUserId,
          accountType,
          organizationId,
          organizationFilterString,
          statusFilterString,
          searchString,
          page,
        }),
      })
        .then((res) => res.json())
        .then((res) => this.setState({ conversations: res, rerender: false }));

      fetch(getTotalConversationsRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          currentUserId,
          accountType,
          organizationId,
          organizationFilterString,
          statusFilterString,
          searchString,
          page,
        }),
      })
        .then((res) => res.json())
        .then((res) =>
          this.setState({ totalConversations: res, rerender: false }),
        );
    }
  }

  getConversations(page) {
    const { currentUserId, accountType } = this.props;

    const { getConversationsRoute } = this.state;

    fetch(getConversationsRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        currentUserId,
        accountType,
        page,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ conversations: res }));
  }

  calculatePages(totalEntries) {
    return Math.ceil(totalEntries / 10);
  }

  handleChangeOrganizationFilter(event) {
    this.setState({
      organizationFilterString: event.target.value,
      page: 1,
      rerender: true,
    });
  }

  handleChangeStatusFilter(event) {
    this.setState({
      statusFilterString: event.target.value,
      page: 1,
      rerender: true,
    });
  }

  handleClickClearOrganizationFilter() {
    const manageConversationsFilter = document.getElementById(
      'manage-conversations-organization-filter',
    );

    manageConversationsFilter.value = '';

    this.setState({ organizationFilterString: '', page: 1, rerender: true });
  }

  handleClickClearStatusFilter() {
    const manageConversationsStatusFilter = document.getElementById(
      'manage-conversations-status-filter',
    );

    manageConversationsStatusFilter.value = '';

    this.setState({ statusFilterString: '', page: 1, rerender: true });
  }

  handleChangeSearch(event) {
    this.setState({
      searchString: event.target.value,
      page: 1,
      rerender: true,
    });
  }

  handleClickClearSearch() {
    const manageConversationsSearchBar = document.getElementById(
      'manage-conversations-search-bar',
    );

    manageConversationsSearchBar.value = '';

    this.setState({ searchString: '', page: 1, rerender: true });
  }

  convertToLocalDate(date) {
    const localDate = new Date(date).toLocaleDateString('en-GB', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

    return localDate;
  }

  convertToLocalTime(time) {
    const localTime = new Date(time).toLocaleTimeString('en-GB');

    return localTime;
  }

  displayConversation(conversation) {
    const { currentUserId, accountType, organizationId } = this.props;

    ReactDOM.render(<div />, document.getElementById('root'));

    ReactDOM.render(
      <ConversationPage
        currentUserId={currentUserId}
        accountType={accountType}
        organizationId={organizationId}
        conversation={conversation}
      />,
      document.getElementById('main'),
    );
  }

  render() {
    const { currentUserId, accountType, organizationId } = this.props;

    const {
      conversation,
      conversations,
      totalConversations,
      organizations,
      page,
      isLoading,
      renderConversationPage,
      exportConversationsRoute,
    } = this.state;

    if (isLoading) {
      return <LoadingText />;
    }

    const issueOpened = 'Issue opened: waiting for agent response';
    const issueRequiresClarification =
      'Issue requires clarification: waiting for client response';
    const issueUnderEvaluation =
      'Issue under evaluation: waiting for developer feedback';
    const issueConfirmed = 'Issue confirmed: waiting for developer patch';
    const issueResolved = 'Issue resolved: waiting for client confirmation';
    const issueClosed = 'Issue closed';
    const enhancementRequested =
      'Enhancement requested: waiting for developer confirmation';
    const enhancementUnderDevelopment =
      'Enhancement under development: waiting for developer patch';
    const enhancementCompleted = 'Enhancement completed';

    let totalEntries = 0;
    let totalPages = 1;

    if (totalConversations[0]) {
      totalEntries = parseInt(totalConversations[0].totalConversations, 10);
      totalPages = this.calculatePages(
        parseInt(totalConversations[0].totalConversations, 10),
      );
    }

    const isFirstPage = page - 1 === 0;
    const isLastPage = page === totalPages;

    const previousPage = [];
    const nextPage = [];

    if (totalPages < 3) {
      for (let i = page + 1; i < page + 2; i += 1) {
        nextPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }

      for (let i = page - 1; i < page; i += 1) {
        previousPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    } else if (page - 1 < 1) {
      for (let i = page + 1; i < page + 3; i += 1) {
        nextPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    } else if (page < totalPages) {
      for (let i = page - 1; i < page; i += 1) {
        previousPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }

      for (let i = page + 1; i < page + 2; i += 1) {
        nextPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    } else if (page === totalPages) {
      for (let i = page - 2; i < page; i += 1) {
        previousPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    }

    return (
      <div>
        {renderConversationPage ? (
          <ConversationPage
            currentUserId={currentUserId}
            accountType={accountType}
            organizationId={organizationId}
            conversation={conversation}
          />
        ) : (
          <div className="manage-conversations-page fade-in">
            <div className="pt-5" />
            <div className="row">
              <div className="manage-conversations-title ml-4">
                <h5 className="text-secondary">
                  Manage Conversations&ensp;&#40;
                  {totalEntries}
                  &#41;
                </h5>
                &ensp;
                <span>
                  {accountType === 'Admin' ? (
                    <form method="GET" action={exportConversationsRoute}>
                      <button type="submit" className="btn btn-info">
                        Export
                      </button>
                    </form>
                  ) : (
                    <div />
                  )}
                </span>
                &ensp;
                <div className="float-right">
                  <div className="mr-4">
                    <div className="float-right">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          {accountType === 'Admin' ? (
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="fa fa-filter" />
                                </span>
                              </div>
                              <select
                                id="manage-conversations-organization-filter"
                                className="manage-conversations-organization-filter form-control text-secondary"
                                onChange={this.handleChangeOrganizationFilter.bind(
                                  this,
                                )}
                                defaultValue=""
                              >
                                <option value="" disabled>
                                  Organization
                                </option>
                                {organizations.map((org) => (
                                  <option
                                    key={org.organizationId}
                                    value={org.organizationId}
                                  >
                                    {org.name}
                                  </option>
                                ))}
                              </select>
                              <div className="input-group-append">
                                <button
                                  type="button"
                                  className="btn input-group-text"
                                  onClick={this.handleClickClearOrganizationFilter.bind(
                                    this,
                                  )}
                                >
                                  <i className="fa fa-undo" />
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div />
                          )}
                          &ensp;
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fa fa-filter" />
                              </span>
                            </div>
                            <select
                              id="manage-conversations-status-filter"
                              className="manage-conversations-status-filter form-control text-secondary"
                              onChange={this.handleChangeStatusFilter.bind(
                                this,
                              )}
                              defaultValue=""
                            >
                              <option value="" disabled>
                                Status
                              </option>
                              <option value={1}>{issueOpened}</option>
                              <option value={2}>
                                {issueRequiresClarification}
                              </option>
                              <option value={3}>{issueUnderEvaluation}</option>
                              <option value={4}>{issueConfirmed}</option>
                              <option value={5}>{issueResolved}</option>
                              <option value={6}>{issueClosed}</option>
                              <option value={7}>{enhancementRequested}</option>
                              <option value={8}>
                                {enhancementUnderDevelopment}
                              </option>
                              <option value={9}>{enhancementCompleted}</option>
                            </select>
                            <div className="input-group-append">
                              <button
                                type="button"
                                className="btn input-group-text"
                                onClick={this.handleClickClearStatusFilter.bind(
                                  this,
                                )}
                              >
                                <i className="fa fa-undo" />
                              </button>
                            </div>
                          </div>
                          &ensp;
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fa fa-search" />
                              </span>
                            </div>
                            <input
                              id="manage-conversations-search-bar"
                              className="manage-conversations-search-bar form-control"
                              name="searchString"
                              onChange={this.handleChangeSearch.bind(this)}
                              placeholder="Search"
                            />
                            {/* <div className="input-group-append">
                            <button
                              type="button"
                              className=""
                              onClick={this.handleClickClearSearch.bind(this)}
                            >
                              <i className="fa fa-undo" />
                            </button>
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <table className="table">
              <tbody className="conversation-tbody">
                {conversations.map((_) => (
                  <tr
                    key={_.conversationId}
                    className="clickable"
                    onClick={() =>
                      this.setState({
                        renderConversationPage: true,
                        conversation: _,
                      })
                    }
                  >
                    <td className="fade-in w-50">
                      <small>
                        {_.reference}
                        &nbsp;
                        {_.type}
                      </small>
                      <br />
                      <b>{_.authorName}</b>
                      <br />
                      <small className="text-secondary">
                        {this.convertToLocalDate(_.dateCreated)}
                        &ensp;
                        {this.convertToLocalTime(_.dateCreated)}
                      </small>
                      <br />
                      <small>
                        <StatusText status={_.status} />
                      </small>
                    </td>
                    <td className="fade-in w-50">
                      <div className="mb-2">
                        <b>{_.title}</b>
                      </div>
                      <div>
                        <LastMessageDisplay
                          currentUserId={currentUserId}
                          conversationId={_.conversationId}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="conversation-pagination">
              {totalEntries > 0 ? (
                <div>
                  {isFirstPage && isLastPage ? (
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn border btn-light"
                        disabled
                      >
                        &lsaquo;
                      </button>
                      <button
                        type="button"
                        className="btn border btn-info"
                        disabled
                      >
                        {page}
                      </button>
                      <button
                        type="button"
                        className="btn border btn-light"
                        disabled
                      >
                        &rsaquo;
                      </button>
                    </div>
                  ) : (
                    <div>
                      {isFirstPage ? (
                        <div className="text-center">
                          <button
                            type="button"
                            className="btn border btn-light"
                            disabled
                          >
                            &lsaquo;
                          </button>
                          <button
                            type="button"
                            className="btn border btn-info"
                            disabled
                          >
                            {page}
                          </button>
                          {nextPage}
                          <button
                            type="button"
                            className="btn border btn-light"
                            onClick={() =>
                              this.setState({ page: page + 1, rerender: true })
                            }
                          >
                            &rsaquo;
                          </button>
                        </div>
                      ) : (
                        <div>
                          {isLastPage ? (
                            <div className="text-center">
                              <button
                                type="button"
                                className="btn border btn-light"
                                onClick={() =>
                                  this.setState({
                                    page: page - 1,
                                    rerender: true,
                                  })
                                }
                              >
                                &lsaquo;
                              </button>
                              {previousPage}
                              <button
                                type="button"
                                className="btn border btn-info"
                                disabled
                              >
                                {page}
                              </button>
                              <button
                                type="button"
                                className="btn border btn-light"
                                disabled
                              >
                                &rsaquo;
                              </button>
                            </div>
                          ) : (
                            <div>
                              {!isFirstPage && !isLastPage ? (
                                <div className="text-center">
                                  <button
                                    type="button"
                                    className="btn border btn-light"
                                    onClick={() =>
                                      this.setState({
                                        page: page - 1,
                                        rerender: true,
                                      })
                                    }
                                  >
                                    &lsaquo;
                                  </button>
                                  {previousPage}
                                  <button
                                    type="button"
                                    className="btn border btn-info"
                                    disabled
                                  >
                                    {page}
                                  </button>
                                  {nextPage}
                                  <button
                                    type="button"
                                    className="btn border btn-light"
                                    onClick={() =>
                                      this.setState({
                                        page: page + 1,
                                        rerender: true,
                                      })
                                    }
                                  >
                                    &rsaquo;
                                  </button>
                                </div>
                              ) : (
                                <div />
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

ManageConversationsPage.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  accountType: PropTypes.string.isRequired,
  organizationId: PropTypes.number.isRequired,
};

export default ManageConversationsPage;
