import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import './ManageOrganizationsPage.css';
import AddProjectPage from './AddProjectPage';
import EditOrganizationPage from './EditOrganizationPage';
import EditProjectPage from './EditProjectPage';
import CreateAccountPage from '../account/CreateAccountPage';
import EditAccountPage from '../account/EditAccountPage';
import LoadingText from '../common/LoadingText';
import server from '../../server';

class ManageOrganizationsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      organization: {},
      organizations: [],
      totalOrganizations: [],
      project: {},
      projects: [],
      member: {},
      members: [],
      filterString: '',
      searchString: '',
      page: 1,
      isLoading: false,
      renderEditOrganizationPage: false,
      renderAddProjectPage: false,
      renderEditProjectPage: false,
      renderCreateAccountPage: false,
      renderEditAccountPage: false,
      rerender: false,
      getOrganizationsRoute: `${server}/help-desk/organization/get-organizations`,
      getTotalOrganizationsRoute: `${server}/help-desk/organization/get-total-organizations`,
      getProjectsRoute: `${server}/help-desk/organization/get-projects`,
      getMembersRoute: `${server}/help-desk/organization/get-members`,
      deleteOrganizationRoute: `${server}/help-desk/organization/delete-organization`,
    };
  }

  componentDidMount() {
    const { currentUserId, accountType, organizationId } = this.props;

    const {
      filterString,
      searchString,
      page,
      getOrganizationsRoute,
      getTotalOrganizationsRoute,
      getProjectsRoute,
      getMembersRoute,
    } = this.state;

    this.setState({ isLoading: true });

    fetch(getOrganizationsRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        currentUserId,
        accountType,
        organizationId,
        filterString,
        searchString,
        page,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ organizations: res, isLoading: false }));

    fetch(getTotalOrganizationsRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        currentUserId,
        accountType,
        organizationId,
        filterString,
        searchString,
        page,
      }),
    })
      .then((res) => res.json())
      .then((res) =>
        this.setState({ totalOrganizations: res, isLoading: false }),
      );

    fetch(getProjectsRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((res) => this.setState({ projects: res, isLoading: false }));

    fetch(getMembersRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((res) => this.setState({ members: res, isLoading: false }));
  }

  componentDidUpdate() {
    const { currentUserId, accountType, organizationId } = this.props;

    const {
      filterString,
      searchString,
      page,
      rerender,
      getOrganizationsRoute,
      getTotalOrganizationsRoute,
    } = this.state;

    if (rerender) {
      fetch(getOrganizationsRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          currentUserId,
          accountType,
          organizationId,
          filterString,
          searchString,
          page,
        }),
      })
        .then((res) => res.json())
        .then((res) => this.setState({ organizations: res, rerender: false }));

      fetch(getTotalOrganizationsRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          currentUserId,
          accountType,
          organizationId,
          filterString,
          searchString,
          page,
        }),
      })
        .then((res) => res.json())
        .then((res) =>
          this.setState({ totalOrganizations: res, rerender: false }),
        );
    }
  }

  calculatePages(totalEntries) {
    return Math.ceil(totalEntries / 10);
  }

  handleChangeFilter(event) {
    this.setState({
      filterString: event.target.value,
      page: 1,
      rerender: true,
    });
  }

  handleClickClearFilter() {
    const manageOrganizationsFilter = document.getElementById(
      'manage-organizations-filter',
    );

    manageOrganizationsFilter.value = '';

    this.setState({ filterString: '', page: 1, rerender: true });
  }

  handleChangeSearch(event) {
    this.setState({
      searchString: event.target.value,
      page: 1,
      rerender: true,
    });
  }

  handleClickClearSearch() {
    const manageOrganizationsSearchBar = document.getElementById(
      'manage-organizations-search-bar',
    );

    manageOrganizationsSearchBar.value = '';

    this.setState({ searchString: '', page: 1, rerender: true });
  }

  handleClickEdit(organization) {
    const { currentUserId, accountType, organizationId } = this.props;

    ReactDOM.render(
      <EditOrganizationPage
        currentUserId={currentUserId}
        accountType={accountType}
        organizationId={organizationId}
        organization={organization}
      />,
      document.getElementById('main'),
    );
  }

  handleClickDelete(organizationId) {
    const { deleteOrganizationRoute } = this.state;

    fetch(deleteOrganizationRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ organizationId }),
    });

    function OrganizationDeletedNotification() {
      return (
        <div className="alert alert-success">
          <span>Organization deleted.</span>
        </div>
      );
    }

    ReactDOM.render(
      <OrganizationDeletedNotification />,
      document.getElementById('notification'),
    );

    setTimeout(() => {
      this.setState({ rerender: true });
    }, 1000);
  }

  addProject(organizationId) {
    const { currentUserId, accountType } = this.props;

    ReactDOM.render(
      <AddProjectPage
        currentUserId={currentUserId}
        accountType={accountType}
        organizationId={organizationId}
      />,
      document.getElementById('main'),
    );
  }

  editProject(project) {
    const { currentUserId, accountType, organizationId } = this.props;

    ReactDOM.render(
      <EditProjectPage
        currentUserId={currentUserId}
        accountType={accountType}
        organizationId={organizationId}
        project={project}
      />,
      document.getElementById('main'),
    );
  }

  addMember() {
    const { currentUserId, accountType, organizationId } = this.props;

    ReactDOM.render(
      <CreateAccountPage
        currentUserId={currentUserId}
        accountType={accountType}
        organizationId={organizationId}
      />,
      document.getElementById('main'),
    );
  }

  editMember(account) {
    const { currentUserId, accountType, organizationId } = this.props;

    ReactDOM.render(
      <EditAccountPage
        currentUserId={currentUserId}
        accountType={accountType}
        organizationId={organizationId}
        account={account}
      />,
      document.getElementById('main'),
    );
  }

  render() {
    const { currentUserId, accountType, organizationId } = this.props;

    const {
      organization,
      organizations,
      totalOrganizations,
      project,
      projects,
      member,
      members,
      page,
      isLoading,
      renderEditOrganizationPage,
      renderAddProjectPage,
      renderEditProjectPage,
      renderCreateAccountPage,
      renderEditAccountPage,
    } = this.state;

    if (isLoading) {
      return <LoadingText />;
    }

    let totalEntries = 0;
    let totalPages = 1;

    if (totalOrganizations[0]) {
      totalEntries = parseInt(totalOrganizations[0].totalOrganizations, 10);
      totalPages = this.calculatePages(
        parseInt(totalOrganizations[0].totalOrganizations, 10),
      );
    }

    const isFirstPage = page - 1 === 0;
    const isLastPage = page === totalPages;

    const previousPage = [];
    const nextPage = [];

    if (totalPages < 3) {
      for (let i = page + 1; i < page + 2; i += 1) {
        nextPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }

      for (let i = page - 1; i < page; i += 1) {
        previousPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    } else if (page - 1 < 1) {
      for (let i = page + 1; i < page + 3; i += 1) {
        nextPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    } else if (page < totalPages) {
      for (let i = page - 1; i < page; i += 1) {
        previousPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }

      for (let i = page + 1; i < page + 2; i += 1) {
        nextPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    } else if (page === totalPages) {
      for (let i = page - 2; i < page; i += 1) {
        previousPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    }

    return (
      <div>
        {renderEditOrganizationPage ? (
          <EditOrganizationPage
            currentUserId={currentUserId}
            accountType={accountType}
            organizationId={organizationId}
            organization={organization}
          />
        ) : (
          <div>
            {renderAddProjectPage ? (
              <AddProjectPage
                currentUserId={currentUserId}
                accountType={accountType}
                organizationId={organization.organizationId}
              />
            ) : (
              <div>
                {renderEditProjectPage ? (
                  <EditProjectPage
                    currentUserId={currentUserId}
                    accountType={accountType}
                    organizationId={organizationId}
                    project={project}
                  />
                ) : (
                  <div>
                    {renderCreateAccountPage ? (
                      <CreateAccountPage
                        currentUserId={currentUserId}
                        accountType={accountType}
                        organizationId={organizationId}
                      />
                    ) : (
                      <div>
                        {renderEditAccountPage ? (
                          <EditAccountPage
                            currentUserId={currentUserId}
                            accountType={accountType}
                            organizationId={organizationId}
                            account={member}
                          />
                        ) : (
                          <div className="manage-organizations-page fade-in">
                            <div className="pt-5" />
                            <div className="row">
                              <div className="ml-4 col">
                                <h5 className="text-secondary">
                                  Manage Organizations&ensp;&#40;
                                  {totalEntries}
                                  &#41;
                                </h5>
                              </div>
                              <div className="mr-4 col">
                                {/* <div className="float-right">
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <div className="input-group">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            <i className="fa fa-filter" />
                                          </span>
                                        </div>
                                        <select
                                          id="manage-organizations-filter"
                                          className="form-control text-secondary"
                                          onChange={this.handleChangeFilter.bind(
                                            this,
                                          )}
                                          defaultValue=""
                                        >
                                          <option value="" disabled>
                                            Filter
                                          </option>
                                          <option value="Client">Client</option>
                                          <option value="Affiliate">
                                            Affiliate
                                          </option>
                                        </select>
                                        <div className="input-group-append">
                                          <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={this.handleClickClearFilter.bind(
                                              this,
                                            )}
                                          >
                                            <i className="fa fa-undo" />
                                          </button>
                                        </div>
                                      </div>
                                      &ensp;
                                      <div className="input-group">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            <i className="fa fa-search" />
                                          </span>
                                        </div>
                                        <input
                                          id="manage-organizations-search-bar"
                                          className="form-control"
                                          name="searchString"
                                          onChange={this.handleChangeSearch.bind(
                                            this,
                                          )}
                                          placeholder="Search"
                                        />
                                        <div className="input-group-append">
                                          <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={this.handleClickClearSearch.bind(
                                              this,
                                            )}
                                          >
                                            <i className="fa fa-undo" />
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                            <br />
                            <table className="table">
                              <tbody className="organization-tbody">
                                {organizations.map((org) => (
                                  <tr key={org.organizationId}>
                                    <td className="fade-in">
                                      {org.organizationId}
                                    </td>
                                    <td className="fade-in w-25">
                                      <b>{org.name}</b>
                                      <br />
                                      {org.address}
                                      <br />
                                      {org.email}
                                      <br />
                                      {org.mobile}
                                      <br />
                                      {org.landline}
                                    </td>
                                    <td className="fade-in text-center">
                                      <div className="d-flex btn-group">
                                        <button
                                          type="button"
                                          className="w-100 btn btn-sm btn-success"
                                          name="edit"
                                          onClick={() =>
                                            this.setState({
                                              organization: org,
                                              renderEditOrganizationPage: true,
                                            })
                                          }
                                        >
                                          Edit
                                        </button>
                                        <button
                                          type="button"
                                          className="w-100 btn btn-sm btn-danger"
                                          name="delete"
                                          onClick={this.handleClickDelete.bind(
                                            this,
                                            org.organizationId,
                                          )}
                                        >
                                          Delete
                                        </button>
                                      </div>
                                    </td>
                                    <td className="fade-in w-25">
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-info"
                                        onClick={() =>
                                          this.setState({
                                            organization: org,
                                            renderAddProjectPage: true,
                                          })
                                        }
                                      >
                                        <i className="fa fa-plus" />
                                        &nbsp; New Project
                                      </button>
                                      <div className="mb-2" />
                                      {projects
                                        .filter(
                                          (proj) =>
                                            proj.organizationId ===
                                            org.organizationId,
                                        )
                                        .map((proj) => (
                                          <div
                                            key={proj.projectId}
                                            className="inline-block"
                                            onClick={() =>
                                              this.setState({
                                                project: proj,
                                                renderEditProjectPage: true,
                                              })
                                            }
                                          >
                                            <ul className="list-group">
                                              <li className="clickable member list-group-item">
                                                <small>
                                                  <b>
                                                    &#91;
                                                    {proj.code}
                                                    &#93; &nbsp;
                                                    {proj.name}
                                                  </b>
                                                </small>
                                              </li>
                                            </ul>
                                          </div>
                                        ))}
                                    </td>
                                    <td className="fade-in w-50">
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-info"
                                        onClick={() =>
                                          this.setState({
                                            renderCreateAccountPage: true,
                                          })
                                        }
                                      >
                                        <i className="fa fa-plus" />
                                        &nbsp; New Member
                                      </button>
                                      <div className="mb-2" />
                                      {members
                                        .filter(
                                          (mem) =>
                                            mem.organizationId ===
                                            org.organizationId,
                                        )
                                        .map((mem) => (
                                          <div
                                            key={mem.accountId}
                                            className="inline-block"
                                            onClick={() =>
                                              this.setState({
                                                member: mem,
                                                renderEditAccountPage: true,
                                              })
                                            }
                                          >
                                            <ul className="list-group">
                                              <li className="clickable member list-group-item">
                                                <small>
                                                  <b>{mem.name}</b>
                                                </small>
                                              </li>
                                            </ul>
                                          </div>
                                        ))}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div className="organization-pagination">
                              {totalEntries > 0 ? (
                                <div>
                                  {isFirstPage && isLastPage ? (
                                    <div className="text-center">
                                      <button
                                        type="button"
                                        className="btn border btn-light"
                                        disabled
                                      >
                                        &lsaquo;
                                      </button>
                                      <button
                                        type="button"
                                        className="btn border btn-info"
                                        disabled
                                      >
                                        {page}
                                      </button>
                                      <button
                                        type="button"
                                        className="btn border btn-light"
                                        disabled
                                      >
                                        &rsaquo;
                                      </button>
                                    </div>
                                  ) : (
                                    <div>
                                      {isFirstPage ? (
                                        <div className="text-center">
                                          <button
                                            type="button"
                                            className="btn border btn-light"
                                            disabled
                                          >
                                            &lsaquo;
                                          </button>
                                          <button
                                            type="button"
                                            className="btn border btn-info"
                                            disabled
                                          >
                                            {page}
                                          </button>
                                          {nextPage}
                                          <button
                                            type="button"
                                            className="btn border btn-light"
                                            onClick={() =>
                                              this.setState({
                                                page: page + 1,
                                                rerender: true,
                                              })
                                            }
                                          >
                                            &rsaquo;
                                          </button>
                                        </div>
                                      ) : (
                                        <div>
                                          {isLastPage ? (
                                            <div className="text-center">
                                              <button
                                                type="button"
                                                className="btn border btn-light"
                                                onClick={() =>
                                                  this.setState({
                                                    page: page - 1,
                                                    rerender: true,
                                                  })
                                                }
                                              >
                                                &lsaquo;
                                              </button>
                                              {previousPage}
                                              <button
                                                type="button"
                                                className="btn border btn-info"
                                                disabled
                                              >
                                                {page}
                                              </button>
                                              <button
                                                type="button"
                                                className="btn border btn-light"
                                                disabled
                                              >
                                                &rsaquo;
                                              </button>
                                            </div>
                                          ) : (
                                            <div>
                                              {!isFirstPage && !isLastPage ? (
                                                <div className="text-center">
                                                  <button
                                                    type="button"
                                                    className="btn border btn-light"
                                                    onClick={() =>
                                                      this.setState({
                                                        page: page - 1,
                                                        rerender: true,
                                                      })
                                                    }
                                                  >
                                                    &lsaquo;
                                                  </button>
                                                  {previousPage}
                                                  <button
                                                    type="button"
                                                    className="btn border btn-info"
                                                    disabled
                                                  >
                                                    {page}
                                                  </button>
                                                  {nextPage}
                                                  <button
                                                    type="button"
                                                    className="btn border btn-light"
                                                    onClick={() =>
                                                      this.setState({
                                                        page: page + 1,
                                                        rerender: true,
                                                      })
                                                    }
                                                  >
                                                    &rsaquo;
                                                  </button>
                                                </div>
                                              ) : (
                                                <div />
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div />
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

ManageOrganizationsPage.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  accountType: PropTypes.string.isRequired,
  organizationId: PropTypes.number.isRequired,
};

export default ManageOrganizationsPage;
