import React from 'react';
import server from '../../server';

class ServerStatusText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      serverStatus: [],
      getServerStatusRoute: `${server}/api/server/get-server-status`,
    };
  }

  componentDidMount() {
    const { getServerStatusRoute } = this.state;

    fetch(getServerStatusRoute)
      .then((res) => res.json())
      .then((res) => this.setState({ serverStatus: res }));
  }

  render() {
    const { serverStatus } = this.state;

    if (serverStatus[0] && serverStatus[0].response === 'server-online') {
      return <div />;
    }

    return <div />;
  }
}

export default ServerStatusText;
