import React from 'react';
import PropTypes from 'prop-types';
import LoadingText from '../common/LoadingText';
import server from '../../server';

class ReferenceText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      referenceArray: [],
      isLoading: false,
      getReferenceRoute: `${server}/help-desk/conversation/get-reference`,
    };
  }

  componentDidMount() {
    const { conversationId } = this.props;

    const { getReferenceRoute } = this.state;

    fetch(getReferenceRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        conversationId,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ referenceArray: res }));
  }

  render() {
    const { referenceArray, isLoading } = this.state;

    let reference = '';

    if (referenceArray[0]) {
      reference = referenceArray[0].reference;
    }

    if (isLoading) {
      return <LoadingText />;
    }

    return <span>{reference}</span>;
  }
}

ReferenceText.propTypes = {
  conversationId: PropTypes.number.isRequired,
};

export default ReferenceText;
