import React from 'react';
import ReactDOM from 'react-dom';
import './LoginPage.css';
import server from '../../server';
import socket from '../../socket';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accounts: [],
      email: '',
      password: '',
      showForgotPassword: false,
      forgotPasswordRoute: `${server}/help-desk/user/forgot-password`,
      loginRoute: `${server}/help-desk/user/login`,
      loginBroadcastRoute: `${server}/help-desk/broadcast/login-broadcast`,
      forgotPasswordResponse: [],
    };
  }

  componentDidUpdate() {
    const { accounts, email, loginBroadcastRoute } = this.state;

    function LoginNotification() {
      return (
        <div className="alert alert-success">
          <span>Logged in.</span>
        </div>
      );
    }

    if (accounts[0] && email === accounts[0].email) {
      localStorage.setItem('accountId', accounts[0].accountId.toString());
      localStorage.setItem(
        'organizationId',
        accounts[0].organizationId.toString(),
      );
      localStorage.setItem('email', accounts[0].email);
      localStorage.setItem('name', accounts[0].name);
      localStorage.setItem('token', accounts[0].token);

      fetch(loginBroadcastRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          accountId: accounts[0].accountId,
          organizationId: accounts[0].organizationId,
          email: accounts[0].email,
          name: accounts[0].name,
        }),
      });

      socket.emit('login', {
        accountId: accounts[0].accountId,
        organizationId: accounts[0].organizationId,
        email: accounts[0].email,
        name: accounts[0].name,
      });

      ReactDOM.render(
        <LoginNotification />,
        document.getElementById('notification'),
      );

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }

    function IncorrectPasswordNotification() {
      return (
        <div className="alert alert-danger">
          <span>Incorrect password.</span>
        </div>
      );
    }

    if (accounts[0] && accounts[0].response === 'incorrect-password') {
      ReactDOM.render(
        <IncorrectPasswordNotification />,
        document.getElementById('notification'),
      );

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }

    function AccountDoesNotExistNotification() {
      return (
        <div className="alert alert-danger">
          <span>Account does not exist.</span>
        </div>
      );
    }

    if (accounts[0] && accounts[0].response === 'account-does-not-exist') {
      ReactDOM.render(
        <AccountDoesNotExistNotification />,
        document.getElementById('notification'),
      );

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }

  validateEmail = (enteredEmail) => {
    const regex = /\S+@\S+\.\S+/;

    return regex.test(enteredEmail);
  };

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    const {
      email,
      password,
      showForgotPassword,
      forgotPasswordRoute,
      loginRoute,
    } = this.state;

    function EmptyFieldsNotification() {
      return (
        <div className="alert alert-warning">
          <span>Enter the required data.</span>
        </div>
      );
    }

    function InvalidEmailNotification() {
      return (
        <div className="alert alert-warning">
          <span>Invalid email.</span>
        </div>
      );
    }

    function UnregisteredEmailNotification() {
      return (
        <div className="alert alert-danger">
          <span>Unregistered email.</span>
        </div>
      );
    }

    function ForgotPasswordSuccessNotification() {
      return (
        <div className="alert alert-success">
          <span>Email sent.</span>
        </div>
      );
    }

    if (showForgotPassword && !this.validateEmail(email)) {
      ReactDOM.render(
        <InvalidEmailNotification />,
        document.getElementById('notification'),
      );
    } else if (showForgotPassword && email === '') {
      ReactDOM.render(
        <EmptyFieldsNotification />,
        document.getElementById('notification'),
      );
    } else if (showForgotPassword && email !== '') {
      const submitButton = document.querySelector('#submit');

      submitButton.disabled = true;

      fetch(forgotPasswordRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          this.setState({ forgotPasswordResponse: res });

          if (res[0] && res[0].response === 'unregistered-email') {
            ReactDOM.render(
              <UnregisteredEmailNotification />,
              document.getElementById('notification'),
            );
          } else if (res[0] && res[0].response === 'forgot-password-success') {
            ReactDOM.render(
              <ForgotPasswordSuccessNotification />,
              document.getElementById('notification'),
            );
          }

          setTimeout(() => {
            submitButton.disabled = false;
          }, 1000);
        });
    } else if (!showForgotPassword && (email === '' || password === '')) {
      ReactDOM.render(
        <EmptyFieldsNotification />,
        document.getElementById('notification'),
      );
    } else {
      fetch(loginRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
          password,
        }),
      })
        .then((res) => res.json())
        .then((res) => this.setState({ accounts: res }));
    }
  }

  showForgotPassword() {
    const { showForgotPassword } = this.state;

    this.setState({ showForgotPassword: !showForgotPassword });
  }

  render() {
    const {
      email,
      password,
      showForgotPassword,
      forgotPasswordResponse,
    } = this.state;

    if (showForgotPassword) {
      return (
        <div className="fade-in centered-form mx-auto">
          <div className="pt-3" />
          <form onSubmit={this.handleSubmit.bind(this)}>
            <h5 className="text-center text-secondary">Forgot Password</h5>
            <br />
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fa fa-user" />
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                name="email"
                value={email}
                onChange={this.handleChange.bind(this)}
                placeholder="Email"
              />
            </div>
            <div className="text-right">
              <button
                type="button"
                className="forgot-password btn btn-link"
                onClick={this.showForgotPassword.bind(this)}
              >
                Login
              </button>
            </div>
            <br />
            {forgotPasswordResponse[0] &&
            forgotPasswordResponse[0].response === 'forgot-password-success' ? (
              <div className="fade-in text-center green-500">
                Check your email for the new password.
              </div>
            ) : (
              ''
            )}
            {forgotPasswordResponse[0] &&
            forgotPasswordResponse[0].response === 'forgot-password-error' ? (
              <div className="fade-in text-center red-500">
                An error was encountered in the password reset process. Contact
                the administrator for details.
              </div>
            ) : (
              ''
            )}
            {forgotPasswordResponse[0] === undefined ||
            forgotPasswordResponse[0].response === '' ||
            forgotPasswordResponse[0].response === 'unregistered-email' ? (
              <div className="fade-out text-center">
                <button type="submit" id="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            ) : (
              ''
            )}
          </form>
          <br />
          <br />
        </div>
      );
    }

    return (
      <div className="fade-in centered-form mx-auto">
        <div className="pt-3" />
        <form onSubmit={this.handleSubmit.bind(this)}>
          <h5 className="text-center text-secondary">Login</h5>
          <br />
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fa fa-user" />
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              name="email"
              value={email}
              onChange={this.handleChange.bind(this)}
              placeholder="Email"
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fa fa-lock" />
              </span>
            </div>
            <input
              type="password"
              className="form-control"
              name="password"
              value={password}
              onChange={this.handleChange.bind(this)}
              placeholder="Password"
            />
          </div>
          <div className="text-right">
            <button
              type="button"
              className="forgot-password btn btn-link"
              onClick={this.showForgotPassword.bind(this)}
            >
              Forgot Password
            </button>
          </div>
          <br />
          <div className="text-center">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default LoginPage;
