import React from 'react';
import PropTypes from 'prop-types';
import LoadingText from '../common/LoadingText';
import server from '../../server';

class ConversationAttachmentText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      attachmentArray: [],
      isLoading: false,
      getConversationAttachmentRoute: `${server}/help-desk/conversation/get-conversation-attachment`,
    };
  }

  componentDidMount() {
    const { conversationId } = this.props;

    const { getConversationAttachmentRoute } = this.state;

    fetch(getConversationAttachmentRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        conversationId,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ attachmentArray: res }));
  }

  displayAttachment(attachment) {
    if (attachment) {
      return (
        <div>
          <i className="fa fa-paperclip" />
          &ensp;
          <span className="btn">{attachment.substring(20)}</span>
        </div>
      );
    }

    return <div />;
  }

  render() {
    const { attachmentArray, isLoading } = this.state;

    let attachment = '';

    if (attachmentArray[0]) {
      attachment = attachmentArray[0].attachment;
    }

    if (isLoading) {
      return <LoadingText />;
    }

    return <span>{this.displayAttachment(attachment)}</span>;
  }
}

ConversationAttachmentText.propTypes = {
  conversationId: PropTypes.number.isRequired,
};

export default ConversationAttachmentText;
