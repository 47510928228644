import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import server from '../../server';

class EditOrganizationPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editOrganizationRoute: `${server}/help-desk/organization/edit-organization`,
    };
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { editOrganizationRoute } = this.state;

    fetch(editOrganizationRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        organizationId: event.target.organizationId.value,
        type: event.target.type.value,
        name: event.target.name.value,
        address: event.target.address.value,
        email: event.target.email.value,
        mobile: event.target.mobile.value,
        landline: event.target.landline.value,
      }),
    });

    function OrganizationEditedNotification() {
      return (
        <div className="alert alert-success">
          <span>Organization edited.</span>
        </div>
      );
    }

    ReactDOM.render(
      <OrganizationEditedNotification />,
      document.getElementById('notification'),
    );

    setTimeout(() => {
      window.location.href = '/manage-organizations';
    }, 1000);
  }

  render() {
    const { organization } = this.props;

    const {
      organizationId = organization.organizationId,
      type = organization.type,
      name = organization.name,
      address = organization.address,
      email = organization.email,
      mobile = organization.mobile,
      landline = organization.landline,
    } = organization;

    return (
      <div className="fade-in centered-form mx-auto">
        <div className="pt-3 pl-3">
          <a href="manage-organizations" className="back-link text-secondary">
            <i className="fas fa-arrow-left back-link-icon" />
            &nbsp;Back
          </a>
        </div>
        <form onSubmit={this.handleSubmit.bind(this)}>
          <h5 className="text-center text-secondary">Edit Organization</h5>
          <br />
          <input
            type="hidden"
            name="organizationId"
            defaultValue={organizationId}
          />
          <div className="form-group">
            <label htmlFor="type" className="col-12 text-secondary">
              Type
              <select
                id="type"
                className="form-control"
                name="type"
                defaultValue={type}
                onChange={this.handleChange.bind(this)}
              >
                <option value="Client">Client</option>
                <option value="Affiliate">Affiliate</option>
              </select>
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="name" className="col-12 text-secondary">
              Name
              <input
                type="text"
                id="name"
                className="form-control"
                name="name"
                defaultValue={name}
                onChange={this.handleChange.bind(this)}
                placeholder="Enter name"
              />
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="address" className="col-12 text-secondary">
              Address
              <input
                type="text"
                id="address"
                className="form-control"
                name="address"
                defaultValue={address}
                onChange={this.handleChange.bind(this)}
                placeholder="Enter company"
              />
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="email" className="col-12 text-secondary">
              Email
              <input
                type="text"
                id="email"
                className="form-control"
                name="email"
                defaultValue={email}
                onChange={this.handleChange.bind(this)}
                placeholder="Enter email"
              />
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="mobile" className="col-12 text-secondary">
              Mobile
              <input
                type="text"
                id="mobile"
                className="form-control"
                name="mobile"
                defaultValue={mobile}
                onChange={this.handleChange.bind(this)}
                placeholder="Enter mobile"
              />
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="landline" className="col-12 text-secondary">
              Landline
              <input
                type="text"
                id="landline"
                className="form-control"
                name="landline"
                defaultValue={landline}
                onChange={this.handleChange.bind(this)}
                placeholder="Enter landline"
              />
            </label>
          </div>
          <br />
          <div className="text-center">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

EditOrganizationPage.propTypes = {
  organizationId: PropTypes.number.isRequired,
  organization: PropTypes.shape({
    organizationId: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
    email: PropTypes.string,
    mobile: PropTypes.string,
    landline: PropTypes.string,
  }).isRequired,
};

export default EditOrganizationPage;
