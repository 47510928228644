import React from 'react';
import PropTypes from 'prop-types';
import LoadingText from '../common/LoadingText';
import server from '../../server';

class MonthlyConversationsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      organizations: [],
      totalOrganizations: [],
      projects: [],
      conversations: [],
      filterString: '',
      searchString: '',
      page: 1,
      isLoading: false,
      rerender: false,
      year: new Date(Date.now()).getFullYear(),
      getOrganizationsRoute: `${server}/help-desk/organization/get-organizations`,
      getTotalOrganizationsRoute: `${server}/help-desk/organization/get-total-organizations`,
      getProjectsRoute: `${server}/help-desk/organization/get-projects`,
      getAllConversationsRoute: `${server}/help-desk/conversation/get-all-conversations`,
    };
  }

  componentDidMount() {
    const { currentUserId, accountType, organizationId } = this.props;

    const {
      filterString,
      searchString,
      page,
      getOrganizationsRoute,
      getTotalOrganizationsRoute,
      getProjectsRoute,
      getAllConversationsRoute,
    } = this.state;

    this.setState({ isLoading: true });

    fetch(getOrganizationsRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        currentUserId,
        accountType,
        organizationId,
        filterString,
        searchString,
        page,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ organizations: res, isLoading: false }));

    fetch(getTotalOrganizationsRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        currentUserId,
        accountType,
        organizationId,
        filterString,
        searchString,
        page,
      }),
    })
      .then((res) => res.json())
      .then((res) =>
        this.setState({ totalOrganizations: res, isLoading: false }),
      );

    fetch(getProjectsRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((res) => this.setState({ projects: res, isLoading: false }));

    fetch(getAllConversationsRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((res) => this.setState({ conversations: res, isLoading: false }));
  }

  componentDidUpdate() {
    const { currentUserId, accountType, organizationId } = this.props;

    const {
      filterString,
      searchString,
      page,
      rerender,
      getOrganizationsRoute,
      getTotalOrganizationsRoute,
    } = this.state;

    if (rerender) {
      fetch(getOrganizationsRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          currentUserId,
          accountType,
          organizationId,
          filterString,
          searchString,
          page,
        }),
      })
        .then((res) => res.json())
        .then((res) => this.setState({ organizations: res, rerender: false }));

      fetch(getTotalOrganizationsRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          currentUserId,
          accountType,
          organizationId,
          filterString,
          searchString,
          page,
        }),
      })
        .then((res) => res.json())
        .then((res) =>
          this.setState({ totalOrganizations: res, rerender: false }),
        );
    }
  }

  calculatePages(totalEntries) {
    return Math.ceil(totalEntries / 10);
  }

  calculateMonthlyTotalsPerOrganization(year, organizationId, conversations) {
    let januaryTotal = 0;
    let februaryTotal = 0;
    let marchTotal = 0;
    let aprilTotal = 0;
    let mayTotal = 0;
    let juneTotal = 0;
    let julyTotal = 0;
    let augustTotal = 0;
    let septemberTotal = 0;
    let octoberTotal = 0;
    let novemberTotal = 0;
    let decemberTotal = 0;
    let activeMonths = 0;
    let monthlyAverage = 0;

    if (conversations) {
      for (let i = 0; i < conversations.length; i += 1) {
        if (
          conversations[i].organizationId === organizationId &&
          new Date(conversations[i].dateCreated).getFullYear() === year &&
          new Date(conversations[i].dateCreated).getMonth() === 0
        ) {
          januaryTotal += 1;
        }
      }
      for (let i = 0; i < conversations.length; i += 1) {
        if (
          conversations[i].organizationId === organizationId &&
          new Date(conversations[i].dateCreated).getFullYear() === year &&
          new Date(conversations[i].dateCreated).getMonth() === 1
        ) {
          februaryTotal += 1;
        }
      }
      for (let i = 0; i < conversations.length; i += 1) {
        if (
          conversations[i].organizationId === organizationId &&
          new Date(conversations[i].dateCreated).getFullYear() === year &&
          new Date(conversations[i].dateCreated).getMonth() === 2
        ) {
          marchTotal += 1;
        }
      }
      for (let i = 0; i < conversations.length; i += 1) {
        if (
          conversations[i].organizationId === organizationId &&
          new Date(conversations[i].dateCreated).getFullYear() === year &&
          new Date(conversations[i].dateCreated).getMonth() === 3
        ) {
          aprilTotal += 1;
        }
      }
      for (let i = 0; i < conversations.length; i += 1) {
        if (
          conversations[i].organizationId === organizationId &&
          new Date(conversations[i].dateCreated).getFullYear() === year &&
          new Date(conversations[i].dateCreated).getMonth() === 4
        ) {
          mayTotal += 1;
        }
      }
      for (let i = 0; i < conversations.length; i += 1) {
        if (
          conversations[i].organizationId === organizationId &&
          new Date(conversations[i].dateCreated).getFullYear() === year &&
          new Date(conversations[i].dateCreated).getMonth() === 5
        ) {
          juneTotal += 1;
        }
      }
      for (let i = 0; i < conversations.length; i += 1) {
        if (
          conversations[i].organizationId === organizationId &&
          new Date(conversations[i].dateCreated).getFullYear() === year &&
          new Date(conversations[i].dateCreated).getMonth() === 6
        ) {
          julyTotal += 1;
        }
      }
      for (let i = 0; i < conversations.length; i += 1) {
        if (
          conversations[i].organizationId === organizationId &&
          new Date(conversations[i].dateCreated).getFullYear() === year &&
          new Date(conversations[i].dateCreated).getMonth() === 7
        ) {
          augustTotal += 1;
        }
      }
      for (let i = 0; i < conversations.length; i += 1) {
        if (
          conversations[i].organizationId === organizationId &&
          new Date(conversations[i].dateCreated).getFullYear() === year &&
          new Date(conversations[i].dateCreated).getMonth() === 8
        ) {
          septemberTotal += 1;
        }
      }
      for (let i = 0; i < conversations.length; i += 1) {
        if (
          conversations[i].organizationId === organizationId &&
          new Date(conversations[i].dateCreated).getFullYear() === year &&
          new Date(conversations[i].dateCreated).getMonth() === 9
        ) {
          octoberTotal += 1;
        }
      }
      for (let i = 0; i < conversations.length; i += 1) {
        if (
          conversations[i].organizationId === organizationId &&
          new Date(conversations[i].dateCreated).getFullYear() === year &&
          new Date(conversations[i].dateCreated).getMonth() === 10
        ) {
          novemberTotal += 1;
        }
      }
      for (let i = 0; i < conversations.length; i += 1) {
        if (
          conversations[i].organizationId === organizationId &&
          new Date(conversations[i].dateCreated).getFullYear() === year &&
          new Date(conversations[i].dateCreated).getMonth() === 11
        ) {
          decemberTotal += 1;
        }
      }
    }

    if (januaryTotal > 0) {
      activeMonths += 1;
    }

    if (februaryTotal > 0) {
      activeMonths += 1;
    }

    if (marchTotal > 0) {
      activeMonths += 1;
    }

    if (aprilTotal > 0) {
      activeMonths += 1;
    }

    if (mayTotal > 0) {
      activeMonths += 1;
    }

    if (juneTotal > 0) {
      activeMonths += 1;
    }

    if (julyTotal > 0) {
      activeMonths += 1;
    }

    if (augustTotal > 0) {
      activeMonths += 1;
    }

    if (septemberTotal > 0) {
      activeMonths += 1;
    }

    if (octoberTotal > 0) {
      activeMonths += 1;
    }

    if (novemberTotal > 0) {
      activeMonths += 1;
    }

    if (decemberTotal > 0) {
      activeMonths += 1;
    }

    if (activeMonths > 0) {
      const yearTotal =
        januaryTotal +
        februaryTotal +
        marchTotal +
        aprilTotal +
        mayTotal +
        juneTotal +
        julyTotal +
        augustTotal +
        septemberTotal +
        octoberTotal +
        novemberTotal +
        decemberTotal;

      if (yearTotal % activeMonths === 0) {
        monthlyAverage = yearTotal / activeMonths;
      } else {
        monthlyAverage = Math.round(yearTotal / activeMonths);
      }
    } else {
      activeMonths = 0;
    }

    return (
      <div>
        {activeMonths === 1 ? (
          <div>
            <b>{monthlyAverage}</b>
            &nbsp; per month (active for&nbsp;
            {activeMonths}
            &nbsp;month)
          </div>
        ) : (
          <div>
            <b>{monthlyAverage}</b>
            &nbsp; per month (active for&nbsp;
            {activeMonths}
            &nbsp;months)
          </div>
        )}
        <table className="table">
          <tbody>
            <tr>
              <td>
                <div className="text-center">Jan</div>
                <div className="text-center">{januaryTotal}</div>
              </td>
              <td>
                <div className="text-center">Feb</div>
                <div className="text-center">{februaryTotal}</div>
              </td>
              <td>
                <div className="text-center">Mar</div>
                <div className="text-center">{marchTotal}</div>
              </td>
              <td>
                <div className="text-center">Apr</div>
                <div className="text-center">{aprilTotal}</div>
              </td>
              <td>
                <div className="text-center">May</div>
                <div className="text-center">{mayTotal}</div>
              </td>
              <td>
                <div className="text-center">Jun</div>
                <div className="text-center">{juneTotal}</div>
              </td>
              <td>
                <div className="text-center">Jul</div>
                <div className="text-center">{julyTotal}</div>
              </td>
              <td>
                <div className="text-center">Aug</div>
                <div className="text-center">{augustTotal}</div>
              </td>
              <td>
                <div className="text-center">Sep</div>
                <div className="text-center">{septemberTotal}</div>
              </td>
              <td>
                <div className="text-center">Oct</div>
                <div className="text-center">{octoberTotal}</div>
              </td>
              <td>
                <div className="text-center">Nov</div>
                <div className="text-center">{novemberTotal}</div>
              </td>
              <td>
                <div className="text-center">Dec</div>
                <div className="text-center">{decemberTotal}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  calculateMonthlyTotalsPerProject(year, projectId, conversations) {
    let januaryTotal = 0;
    let februaryTotal = 0;
    let marchTotal = 0;
    let aprilTotal = 0;
    let mayTotal = 0;
    let juneTotal = 0;
    let julyTotal = 0;
    let augustTotal = 0;
    let septemberTotal = 0;
    let octoberTotal = 0;
    let novemberTotal = 0;
    let decemberTotal = 0;
    let activeMonths = 0;
    let monthlyAverage = 0;

    if (conversations) {
      for (let i = 0; i < conversations.length; i += 1) {
        if (
          conversations[i].projectId === projectId &&
          new Date(conversations[i].dateCreated).getFullYear() === year &&
          new Date(conversations[i].dateCreated).getMonth() === 0
        ) {
          januaryTotal += 1;
        }
      }

      for (let i = 0; i < conversations.length; i += 1) {
        if (
          conversations[i].projectId === projectId &&
          new Date(conversations[i].dateCreated).getFullYear() === year &&
          new Date(conversations[i].dateCreated).getMonth() === 1
        ) {
          februaryTotal += 1;
        }
      }

      for (let i = 0; i < conversations.length; i += 1) {
        if (
          conversations[i].projectId === projectId &&
          new Date(conversations[i].dateCreated).getFullYear() === year &&
          new Date(conversations[i].dateCreated).getMonth() === 2
        ) {
          marchTotal += 1;
        }
      }

      for (let i = 0; i < conversations.length; i += 1) {
        if (
          conversations[i].projectId === projectId &&
          new Date(conversations[i].dateCreated).getFullYear() === year &&
          new Date(conversations[i].dateCreated).getMonth() === 3
        ) {
          aprilTotal += 1;
        }
      }

      for (let i = 0; i < conversations.length; i += 1) {
        if (
          conversations[i].projectId === projectId &&
          new Date(conversations[i].dateCreated).getFullYear() === year &&
          new Date(conversations[i].dateCreated).getMonth() === 4
        ) {
          mayTotal += 1;
        }
      }

      for (let i = 0; i < conversations.length; i += 1) {
        if (
          conversations[i].projectId === projectId &&
          new Date(conversations[i].dateCreated).getFullYear() === year &&
          new Date(conversations[i].dateCreated).getMonth() === 5
        ) {
          juneTotal += 1;
        }
      }

      for (let i = 0; i < conversations.length; i += 1) {
        if (
          conversations[i].projectId === projectId &&
          new Date(conversations[i].dateCreated).getFullYear() === year &&
          new Date(conversations[i].dateCreated).getMonth() === 6
        ) {
          julyTotal += 1;
        }
      }

      for (let i = 0; i < conversations.length; i += 1) {
        if (
          conversations[i].projectId === projectId &&
          new Date(conversations[i].dateCreated).getFullYear() === year &&
          new Date(conversations[i].dateCreated).getMonth() === 7
        ) {
          augustTotal += 1;
        }
      }

      for (let i = 0; i < conversations.length; i += 1) {
        if (
          conversations[i].projectId === projectId &&
          new Date(conversations[i].dateCreated).getFullYear() === year &&
          new Date(conversations[i].dateCreated).getMonth() === 8
        ) {
          septemberTotal += 1;
        }
      }

      for (let i = 0; i < conversations.length; i += 1) {
        if (
          conversations[i].projectId === projectId &&
          new Date(conversations[i].dateCreated).getFullYear() === year &&
          new Date(conversations[i].dateCreated).getMonth() === 9
        ) {
          octoberTotal += 1;
        }
      }

      for (let i = 0; i < conversations.length; i += 1) {
        if (
          conversations[i].projectId === projectId &&
          new Date(conversations[i].dateCreated).getFullYear() === year &&
          new Date(conversations[i].dateCreated).getMonth() === 10
        ) {
          novemberTotal += 1;
        }
      }

      for (let i = 0; i < conversations.length; i += 1) {
        if (
          conversations[i].projectId === projectId &&
          new Date(conversations[i].dateCreated).getFullYear() === year &&
          new Date(conversations[i].dateCreated).getMonth() === 11
        ) {
          decemberTotal += 1;
        }
      }
    }

    if (januaryTotal > 0) {
      activeMonths += 1;
    }

    if (februaryTotal > 0) {
      activeMonths += 1;
    }

    if (marchTotal > 0) {
      activeMonths += 1;
    }

    if (aprilTotal > 0) {
      activeMonths += 1;
    }

    if (mayTotal > 0) {
      activeMonths += 1;
    }

    if (juneTotal > 0) {
      activeMonths += 1;
    }

    if (julyTotal > 0) {
      activeMonths += 1;
    }

    if (augustTotal > 0) {
      activeMonths += 1;
    }

    if (septemberTotal > 0) {
      activeMonths += 1;
    }

    if (octoberTotal > 0) {
      activeMonths += 1;
    }

    if (novemberTotal > 0) {
      activeMonths += 1;
    }

    if (decemberTotal > 0) {
      activeMonths += 1;
    }

    if (activeMonths > 0) {
      const yearTotal =
        januaryTotal +
        februaryTotal +
        marchTotal +
        aprilTotal +
        mayTotal +
        juneTotal +
        julyTotal +
        augustTotal +
        septemberTotal +
        octoberTotal +
        novemberTotal +
        decemberTotal;

      if (yearTotal % activeMonths === 0) {
        monthlyAverage = yearTotal / activeMonths;
      } else {
        monthlyAverage = Math.round(yearTotal / activeMonths);
      }
    } else {
      activeMonths = 0;
    }

    return (
      <div className="text-secondary">
        <small>
          {activeMonths === 1 ? (
            <div>
              <b>{monthlyAverage}</b>
              &nbsp; per month (active for&nbsp;
              {activeMonths}
              &nbsp;month)
            </div>
          ) : (
            <div>
              <b>{monthlyAverage}</b>
              &nbsp; per month (active for&nbsp;
              {activeMonths}
              &nbsp;months)
            </div>
          )}
          <table className="table text-secondary">
            <tbody>
              <tr>
                <td>
                  <div className="text-center">Jan</div>
                  <div className="text-center">{januaryTotal}</div>
                </td>
                <td>
                  <div className="text-center">Feb</div>
                  <div className="text-center">{februaryTotal}</div>
                </td>
                <td>
                  <div className="text-center">Mar</div>
                  <div className="text-center">{marchTotal}</div>
                </td>
                <td>
                  <div className="text-center">Apr</div>
                  <div className="text-center">{aprilTotal}</div>
                </td>
                <td>
                  <div className="text-center">May</div>
                  <div className="text-center">{mayTotal}</div>
                </td>
                <td>
                  <div className="text-center">Jun</div>
                  <div className="text-center">{juneTotal}</div>
                </td>
                <td>
                  <div className="text-center">Jul</div>
                  <div className="text-center">{julyTotal}</div>
                </td>
                <td>
                  <div className="text-center">Aug</div>
                  <div className="text-center">{augustTotal}</div>
                </td>
                <td>
                  <div className="text-center">Sep</div>
                  <div className="text-center">{septemberTotal}</div>
                </td>
                <td>
                  <div className="text-center">Oct</div>
                  <div className="text-center">{octoberTotal}</div>
                </td>
                <td>
                  <div className="text-center">Nov</div>
                  <div className="text-center">{novemberTotal}</div>
                </td>
                <td>
                  <div className="text-center">Dec</div>
                  <div className="text-center">{decemberTotal}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </small>
      </div>
    );
  }

  render() {
    const {
      organizations,
      totalOrganizations,
      projects,
      conversations,
      page,
      isLoading,
      year,
    } = this.state;

    if (isLoading) {
      return <LoadingText />;
    }

    let totalEntries = 0;
    let totalPages = 1;

    if (totalOrganizations[0]) {
      totalEntries = parseInt(totalOrganizations[0].totalOrganizations, 10);
      totalPages = this.calculatePages(
        parseInt(totalOrganizations[0].totalOrganizations, 10),
      );
    }

    const isFirstPage = page - 1 === 0;
    const isLastPage = page === totalPages;

    const previousPage = [];
    const nextPage = [];

    if (totalPages < 3) {
      for (let i = page + 1; i < page + 2; i += 1) {
        nextPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }

      for (let i = page - 1; i < page; i += 1) {
        previousPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    } else if (page - 1 < 1) {
      for (let i = page + 1; i < page + 3; i += 1) {
        nextPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    } else if (page < totalPages) {
      for (let i = page - 1; i < page; i += 1) {
        previousPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }

      for (let i = page + 1; i < page + 2; i += 1) {
        nextPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    } else if (page === totalPages) {
      for (let i = page - 2; i < page; i += 1) {
        previousPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    }

    const yearSpan = [];

    for (let i = 2020; i <= new Date(Date.now()).getFullYear(); i += 1) {
      yearSpan.push(i);
    }

    return (
      <div className="fade-in container-fluid p-5">
        <div className="pt-4" />
        <div className="row">
          <h5 className="text-secondary">Monthly Conversations</h5>
        </div>
        {yearSpan.map((_) =>
          year === _ ? (
            <span key={_}>
              <button
                type="button"
                className="btn border btn-sm btn-info"
                onClick={() => this.setState({ year: _ })}
                disabled
              >
                {_}
              </button>
            </span>
          ) : (
            <span key={_}>
              <button
                type="button"
                className="btn border btn-sm btn-light"
                onClick={() => this.setState({ year: _ })}
              >
                {_}
              </button>
            </span>
          ),
        )}
        <br />
        <div className="row justify-content-md-center">
          <div className="col-12">
            <table className="table">
              <tbody>
                {organizations.map((org) => (
                  <tr key={org.organizationId}>
                    <td className="fade-in">
                      <b>{org.name}</b>
                      {this.calculateMonthlyTotalsPerOrganization(
                        year,
                        org.organizationId,
                        conversations,
                      )}
                      {projects
                        .filter(
                          (proj) => proj.organizationId === org.organizationId,
                        )
                        .map((proj) => (
                          <div key={proj.projectId} className="px-5">
                            <ul className="text-secondary">
                              <li>
                                <small>
                                  <b>
                                    &#91;
                                    {proj.code}
                                    &#93;&nbsp;
                                    {proj.name}
                                  </b>
                                </small>
                              </li>
                            </ul>
                            {this.calculateMonthlyTotalsPerProject(
                              year,
                              proj.projectId,
                              conversations,
                            )}
                          </div>
                        ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div>
          {totalEntries > 0 ? (
            <div>
              {isFirstPage && isLastPage ? (
                <div className="text-center">
                  <button
                    type="button"
                    className="btn border btn-light"
                    disabled
                  >
                    &lsaquo;
                  </button>
                  <button
                    type="button"
                    className="btn border btn-info"
                    disabled
                  >
                    {page}
                  </button>
                  <button
                    type="button"
                    className="btn border btn-light"
                    disabled
                  >
                    &rsaquo;
                  </button>
                </div>
              ) : (
                <div>
                  {isFirstPage ? (
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn border btn-light"
                        disabled
                      >
                        &lsaquo;
                      </button>
                      <button
                        type="button"
                        className="btn border btn-info"
                        disabled
                      >
                        {page}
                      </button>
                      {nextPage}
                      <button
                        type="button"
                        className="btn border btn-light"
                        onClick={() =>
                          this.setState({
                            page: page + 1,
                            rerender: true,
                          })
                        }
                      >
                        &rsaquo;
                      </button>
                    </div>
                  ) : (
                    <div>
                      {isLastPage ? (
                        <div className="text-center">
                          <button
                            type="button"
                            className="btn border btn-light"
                            onClick={() =>
                              this.setState({
                                page: page - 1,
                                rerender: true,
                              })
                            }
                          >
                            &lsaquo;
                          </button>
                          {previousPage}
                          <button
                            type="button"
                            className="btn border btn-info"
                            disabled
                          >
                            {page}
                          </button>
                          <button
                            type="button"
                            className="btn border btn-light"
                            disabled
                          >
                            &rsaquo;
                          </button>
                        </div>
                      ) : (
                        <div>
                          {!isFirstPage && !isLastPage ? (
                            <div className="text-center">
                              <button
                                type="button"
                                className="btn border btn-light"
                                onClick={() =>
                                  this.setState({
                                    page: page - 1,
                                    rerender: true,
                                  })
                                }
                              >
                                &lsaquo;
                              </button>
                              {previousPage}
                              <button
                                type="button"
                                className="btn border btn-info"
                                disabled
                              >
                                {page}
                              </button>
                              {nextPage}
                              <button
                                type="button"
                                className="btn border btn-light"
                                onClick={() =>
                                  this.setState({
                                    page: page + 1,
                                    rerender: true,
                                  })
                                }
                              >
                                &rsaquo;
                              </button>
                            </div>
                          ) : (
                            <div />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  }
}

MonthlyConversationsPage.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  accountType: PropTypes.string.isRequired,
  organizationId: PropTypes.number.isRequired,
};

export default MonthlyConversationsPage;
