import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './ManageAccountsPage.css';
import EditAccountPage from './EditAccountPage';
import SetPasswordPage from './SetPasswordPage';
import LoadingText from '../common/LoadingText';
import server from '../../server';

class ManageAccountsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      account: {},
      accounts: [],
      totalAccounts: [],
      filterString: '',
      searchString: '',
      page: 1,
      isLoading: false,
      rerender: false,
      renderEditAccountPage: false,
      renderSetPasswordPage: false,
      getAccountsRoute: `${server}/help-desk/account/get-accounts`,
      getTotalAccountsRoute: `${server}/help-desk/account/get-total-accounts`,
      deleteAccountRoute: `${server}/help-desk/account/delete-account`,
    };
  }

  componentDidMount() {
    const { currentUserId, accountType, organizationId } = this.props;

    const {
      filterString,
      searchString,
      page,
      getAccountsRoute,
      getTotalAccountsRoute,
    } = this.state;

    this.setState({ isLoading: true });

    fetch(getAccountsRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        currentUserId,
        accountType,
        organizationId,
        filterString,
        searchString,
        page,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ accounts: res, isLoading: false }));

    fetch(getTotalAccountsRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        currentUserId,
        accountType,
        organizationId,
        filterString,
        searchString,
        page,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ totalAccounts: res, isLoading: false }));
  }

  componentDidUpdate() {
    const { currentUserId, accountType, organizationId } = this.props;

    const {
      filterString,
      searchString,
      page,
      rerender,
      getAccountsRoute,
      getTotalAccountsRoute,
    } = this.state;

    if (rerender) {
      fetch(getAccountsRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          currentUserId,
          accountType,
          organizationId,
          filterString,
          searchString,
          page,
        }),
      })
        .then((res) => res.json())
        .then((res) => this.setState({ accounts: res, rerender: false }));

      fetch(getTotalAccountsRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          currentUserId,
          accountType,
          organizationId,
          filterString,
          searchString,
          page,
        }),
      })
        .then((res) => res.json())
        .then((res) => this.setState({ totalAccounts: res, rerender: false }));
    }
  }

  calculatePages(totalEntries) {
    return Math.ceil(totalEntries / 10);
  }

  convertToLocalDate(date) {
    const localDate = new Date(date).toLocaleDateString('en-GB', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

    return localDate;
  }

  convertToLocalTime(time) {
    const localTime = new Date(time).toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
    });

    return localTime;
  }

  calculateExpirationDate(date, duration) {
    const subscriptionStarted = new Date(date);

    const expirationDate = new Date(
      subscriptionStarted.setDate(subscriptionStarted.getDate() + duration),
    ).toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });

    return expirationDate;
  }

  checkAccountStatus(date, duration) {
    const subscriptionExpiration = new Date(
      new Date(date).setDate(new Date(date).getDate() + duration),
    );

    const currentDate = new Date();

    let accountStatus = '';

    switch (true) {
      case currentDate < subscriptionExpiration:
        accountStatus = 'Active';

        return accountStatus;

      case currentDate > subscriptionExpiration:
        accountStatus = 'Inactive';

        return accountStatus;

      default:
        accountStatus = 'Active';

        return accountStatus;
    }
  }

  handleChangeFilter(event) {
    this.setState({
      filterString: event.target.value,
      page: 1,
      rerender: true,
    });
  }

  handleClickClearFilter() {
    const manageAccountsFilter = document.getElementById(
      'manage-accounts-filter',
    );

    manageAccountsFilter.value = '';

    this.setState({ filterString: '', page: 1, rerender: true });
  }

  handleChangeSearch(event) {
    this.setState({
      searchString: event.target.value,
      page: 1,
      rerender: true,
    });
  }

  handleClickClearSearch() {
    const manageAccountsSearchBar = document.getElementById(
      'manage-accounts-search-bar',
    );

    manageAccountsSearchBar.value = '';

    this.setState({ searchString: '', page: 1, rerender: true });
  }

  handleClickEdit(account) {
    const { currentUserId, accountType, organizationId } = this.props;

    ReactDOM.render(
      <EditAccountPage
        currentUserId={currentUserId}
        accountType={accountType}
        organizationId={organizationId}
        account={account}
      />,
      document.getElementById('main'),
    );
  }

  handleClickDelete(accountId) {
    const { deleteAccountRoute } = this.state;

    fetch(deleteAccountRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ accountId }),
    });

    function AccountDeletedNotification() {
      return (
        <div className="alert alert-success">
          <span>Account deleted.</span>
        </div>
      );
    }

    ReactDOM.render(
      <AccountDeletedNotification />,
      document.getElementById('notification'),
    );

    setTimeout(() => {
      this.setState({ rerender: true });
    }, 1000);
  }

  handleClickSetPassword(accountId) {
    ReactDOM.render(
      <SetPasswordPage accountId={accountId} />,
      document.getElementById('main'),
    );
  }

  render() {
    const { currentUserId, accountType, organizationId } = this.props;

    const {
      account,
      accounts,
      totalAccounts,
      page,
      isLoading,
      renderEditAccountPage,
      renderSetPasswordPage,
    } = this.state;

    if (isLoading) {
      return <LoadingText />;
    }

    let totalEntries = 0;
    let totalPages = 1;

    if (totalAccounts[0]) {
      totalEntries = parseInt(totalAccounts[0].totalAccounts, 10);
      totalPages = this.calculatePages(
        parseInt(totalAccounts[0].totalAccounts, 10),
      );
    }

    const isFirstPage = page - 1 === 0;
    const isLastPage = page === totalPages;

    const previousPage = [];
    const nextPage = [];

    if (totalPages < 3) {
      for (let i = page + 1; i < page + 2; i += 1) {
        nextPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }

      for (let i = page - 1; i < page; i += 1) {
        previousPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    } else if (page - 1 < 1) {
      for (let i = page + 1; i < page + 3; i += 1) {
        nextPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    } else if (page < totalPages) {
      for (let i = page - 1; i < page; i += 1) {
        previousPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }

      for (let i = page + 1; i < page + 2; i += 1) {
        nextPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    } else if (page === totalPages) {
      for (let i = page - 2; i < page; i += 1) {
        previousPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    }

    return (
      <div>
        {renderEditAccountPage ? (
          <EditAccountPage
            currentUserId={currentUserId}
            accountType={accountType}
            organizationId={organizationId}
            account={account}
          />
        ) : (
          <div>
            {renderSetPasswordPage ? (
              <SetPasswordPage accountId={account.accountId} />
            ) : (
              <div className="manage-accounts-page fade-in">
                <div className="pt-5" />
                <div className="row">
                  <div className="ml-4 col">
                    <h5 className="text-secondary">
                      Manage Accounts&ensp;&#40;
                      {totalEntries}
                      &#41;
                    </h5>
                  </div>
                  <div className="mr-4 col">
                    <div className="float-right">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fa fa-filter" />
                              </span>
                            </div>
                            <select
                              id="manage-accounts-filter"
                              className="form-control text-secondary"
                              onChange={this.handleChangeFilter.bind(this)}
                              defaultValue=""
                            >
                              <option value="" disabled>
                                Filter
                              </option>
                              <option value="Admin">Admin</option>
                              <option value="User">User</option>
                            </select>
                            <div className="input-group-append">
                              <button
                                type="button"
                                className="btn input-group-text"
                                onClick={this.handleClickClearFilter.bind(this)}
                              >
                                <i className="fa fa-undo" />
                              </button>
                            </div>
                          </div>
                          &ensp;
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fa fa-search" />
                              </span>
                            </div>
                            <input
                              id="manage-accounts-search-bar"
                              className="form-control"
                              name="searchString"
                              onChange={this.handleChangeSearch.bind(this)}
                              placeholder="Search"
                            />
                            {/* <div className="input-group-append">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={this.handleClickClearSearch.bind(this)}
                              >
                                <i className="fa fa-undo" />
                              </button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <table className="table">
                  <tbody className="account-tbody">
                    {accounts.map((_) => (
                      <tr key={_.accountId}>
                        <td className="fade-in">
                          {_.accountId}
                          <br />
                          {_.type}
                          <br />
                          {this.checkAccountStatus(
                            _.subscriptionStarted,
                            _.subscriptionDuration,
                          )}
                        </td>
                        <td className="fade-in w-25">
                          <b>{_.name}</b>
                          <br />
                          {_.email}
                          <br />
                          Expiration:&nbsp;
                          {this.calculateExpirationDate(
                            _.subscriptionStarted,
                            _.subscriptionDuration,
                          )}
                          &nbsp;
                          {this.convertToLocalTime(_.subscriptionStarted)}
                        </td>
                        <td className="fade-in w-25">
                          <b>{_.organizationName}</b>
                          <br />
                          {_.position}
                        </td>
                        <td className="fade-in w-25">
                          {_.mobile}
                          <br />
                          {_.landline}
                        </td>
                        {_.type === 'User' ? (
                          <td className="fade-in w-25">
                            <div className="d-flex btn-group">
                              <button
                                type="button"
                                className="btn btn-sm btn-success"
                                name="edit"
                                onClick={() =>
                                  this.setState({
                                    renderEditAccountPage: true,
                                    account: _,
                                  })
                                }
                              >
                                Edit
                              </button>
                              <button
                                type="button"
                                className="btn btn-sm btn-warning"
                                name="edit"
                                onClick={() =>
                                  this.setState({
                                    account: _,
                                    renderSetPasswordPage: true,
                                  })
                                }
                              >
                                Set Password
                              </button>
                              <button
                                type="button"
                                className="btn btn-sm btn-danger"
                                name="delete"
                                onClick={this.handleClickDelete.bind(
                                  this,
                                  _.accountId,
                                )}
                              >
                                Delete
                              </button>
                            </div>
                          </td>
                        ) : (
                          <td className="fade-in w-25">
                            <div className="text-center">
                              <button
                                type="button"
                                className="w-25 btn btn-sm btn-success"
                                name="edit"
                                onClick={() =>
                                  this.setState({
                                    account: _,
                                    renderEditAccountPage: true,
                                  })
                                }
                              >
                                Edit
                              </button>
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="account-pagination">
                  {totalEntries > 0 ? (
                    <div>
                      {isFirstPage && isLastPage ? (
                        <div className="text-center">
                          <button
                            type="button"
                            className="btn border btn-light"
                            disabled
                          >
                            &lsaquo;
                          </button>
                          <button
                            type="button"
                            className="btn border btn-info"
                            disabled
                          >
                            {page}
                          </button>
                          <button
                            type="button"
                            className="btn border btn-light"
                            disabled
                          >
                            &rsaquo;
                          </button>
                        </div>
                      ) : (
                        <div>
                          {isFirstPage ? (
                            <div className="text-center">
                              <button
                                type="button"
                                className="btn border btn-light"
                                disabled
                              >
                                &lsaquo;
                              </button>
                              <button
                                type="button"
                                className="btn border btn-info"
                                disabled
                              >
                                {page}
                              </button>
                              {nextPage}
                              <button
                                type="button"
                                className="btn border btn-light"
                                onClick={() =>
                                  this.setState({
                                    page: page + 1,
                                    rerender: true,
                                  })
                                }
                              >
                                &rsaquo;
                              </button>
                            </div>
                          ) : (
                            <div>
                              {isLastPage ? (
                                <div className="text-center">
                                  <button
                                    type="button"
                                    className="btn border btn-light"
                                    onClick={() =>
                                      this.setState({
                                        page: page - 1,
                                        rerender: true,
                                      })
                                    }
                                  >
                                    &lsaquo;
                                  </button>
                                  {previousPage}
                                  <button
                                    type="button"
                                    className="btn border btn-info"
                                    disabled
                                  >
                                    {page}
                                  </button>
                                  <button
                                    type="button"
                                    className="btn border btn-light"
                                    disabled
                                  >
                                    &rsaquo;
                                  </button>
                                </div>
                              ) : (
                                <div>
                                  {!isFirstPage && !isLastPage ? (
                                    <div className="text-center">
                                      <button
                                        type="button"
                                        className="btn border btn-light"
                                        onClick={() =>
                                          this.setState({
                                            page: page - 1,
                                            rerender: true,
                                          })
                                        }
                                      >
                                        &lsaquo;
                                      </button>
                                      {previousPage}
                                      <button
                                        type="button"
                                        className="btn border btn-info"
                                        disabled
                                      >
                                        {page}
                                      </button>
                                      {nextPage}
                                      <button
                                        type="button"
                                        className="btn border btn-light"
                                        onClick={() =>
                                          this.setState({
                                            page: page + 1,
                                            rerender: true,
                                          })
                                        }
                                      >
                                        &rsaquo;
                                      </button>
                                    </div>
                                  ) : (
                                    <div />
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              </div>
            )}
            )
          </div>
        )}
      </div>
    );
  }
}

ManageAccountsPage.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  accountType: PropTypes.string.isRequired,
  organizationId: PropTypes.number.isRequired,
};

export default ManageAccountsPage;
