// import React from 'react';
// import './common.css';

// function Header(): JSX.Element {
//   return (
//     <div id="header">
//       <div className="navbar bg-dark">
//         <div className="logo">
//           <span className="cid">
//             ci
//             <span className="logo-overlap">d</span>
//           </span>
//           <span className="systems">systems</span>
//           &ensp;
//           <span className="help-desk">Help Desk</span>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Header;

import React from 'react';
import './Header.css';

function Header() {
  return (
    <div className="header bg-dark">
      <div className="header-logo bg-dark">
        <div className="logo">
          <div className="cid">cid</div>
          <div className="systems">systems</div>
        </div>
      </div>
    </div>
    // <div>
    //   <div className="header">
    //     <span className="left-header-block">
    //       <div className="new-btn">
    //         <button
    //           type="button"
    //           className="btn btn-sm btn-info"
    //           data-toggle="dropdown"
    //         >
    //           <i className="fa fa-plus" />
    //           &nbsp;New
    //         </button>
    //         <div className="new-dropdown dropdown">
    //           <div className="dropdown-menu">
    //             <a
    //               href="/start-conversation"
    //               className="dropdown-button dropdown-item btn btn-light text-secondary"
    //             >
    //               <div className="text-center">
    //                 <i className="fa fa-comments fa-2x text-secondary" />
    //                 <div className="text-center">Conversation</div>
    //               </div>
    //             </a>
    //             <a
    //               href="/manage-conversations"
    //               className="dropdown-button dropdown-item btn btn-light text-secondary"
    //             >
    //               <div className="text-center">
    //                 <i className="fa fa-user fa-2x text-secondary" />
    //                 <div className="text-center">User</div>
    //               </div>
    //             </a>
    //             <a
    //               href="/manage-conversations"
    //               className="dropdown-button dropdown-item btn btn-light text-secondary"
    //             >
    //               <div className="text-center">
    //                 <i className="fa fa-building fa-2x text-secondary" />
    //                 <div className="text-center">Organization</div>
    //               </div>
    //             </a>
    //           </div>
    //         </div>
    //       </div>
    //     </span>
    //   </div>
    //   <div>
    //     <span className="right-header-block">
    //       <div className="search-bar">
    //         <div className="input-group">
    //           <div className="input-group-prepend">
    //             <span className="input-group-text bg-light">
    //               <i className="fa fa-search text-secondary" />
    //             </span>
    //           </div>
    //           <input
    //             id="search-bar"
    //             className="form-control"
    //             name="searchString"
    //             placeholder="Search"
    //           />
    //         </div>
    //       </div>
    //       <div className="notification-icon">
    //         <i className="fa fa-bell-o text-secondary fa-lg" />
    //       </div>
    //       <div className="user-icon bg-secondary">
    //         <i className="fa fa-user text-light fa-lg" />
    //       </div>
    //     </span>
    //   </div>
    // </div>
  );
}

export default Header;
