import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import server from '../../server';

class EditProjectPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editProjectRoute: `${server}/help-desk/organization/edit-project`,
      deleteProjectRoute: `${server}/help-desk/organization/delete-project`,
    };
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { editProjectRoute } = this.state;

    fetch(editProjectRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        projectId: event.target.projectId.value,
        organizationId: event.target.organizationId.value,
        code: event.target.code.value,
        name: event.target.name.value,
      }),
    });

    function ProjectEditedNotification() {
      return (
        <div id="notification" className="alert alert-success">
          <span>Project edited.</span>
        </div>
      );
    }

    ReactDOM.render(
      <ProjectEditedNotification />,
      document.getElementById('notification'),
    );

    setTimeout(() => {
      window.location.href = '/manage-organizations';
    }, 1000);
  }

  handleClickDelete(projectId) {
    const { deleteProjectRoute } = this.state;

    fetch(deleteProjectRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ projectId }),
    });

    function ProjectDeletedNotification() {
      return (
        <div className="alert alert-success">
          <span>Project deleted.</span>
        </div>
      );
    }

    ReactDOM.render(
      <ProjectDeletedNotification />,
      document.getElementById('notification'),
    );

    setTimeout(() => {
      window.location.href = '/manage-organizations';
    }, 1000);
  }

  render() {
    const { project } = this.props;

    const {
      projectId = project.projectId,
      organizationId = project.organizationId,
      code = project.code,
      name = project.name,
    } = project;

    return (
      <div className="fade-in centered-form mx-auto">
        <div className="pt-3 pl-3">
          <a href="manage-organizations" className="back-link text-secondary">
            <i className="fas fa-arrow-left back-link-icon" />
            &nbsp;Back
          </a>
        </div>
        <form onSubmit={this.handleSubmit.bind(this)}>
          <h5 className="text-center text-secondary">Edit Project</h5>
          <br />
          <input type="hidden" name="projectId" defaultValue={projectId} />
          <input
            type="hidden"
            name="organizationId"
            defaultValue={organizationId}
          />
          <div className="form-group">
            <label htmlFor="code" className="col-12 text-secondary">
              Code
              <input
                type="text"
                id="code"
                className="form-control"
                name="code"
                defaultValue={code}
                onChange={this.handleChange.bind(this)}
                placeholder="Enter code"
              />
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="name" className="col-12 text-secondary">
              Name
              <input
                type="text"
                id="name"
                className="form-control"
                name="name"
                defaultValue={name}
                onChange={this.handleChange.bind(this)}
                placeholder="Enter name"
              />
            </label>
          </div>
          <br />
          <div className="text-center">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
          <br />
          <div className="text-center">
            <button
              type="button"
              className="btn btn-danger"
              onClick={this.handleClickDelete.bind(this, projectId)}
            >
              Delete
            </button>
          </div>
        </form>
      </div>
    );
  }
}

EditProjectPage.propTypes = {
  organizationId: PropTypes.number.isRequired,
  project: PropTypes.shape({
    projectId: PropTypes.number,
    organizationId: PropTypes.number,
    code: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

export default EditProjectPage;
