import React from 'react';
import PropTypes from 'prop-types';
import './NotificationsPage.css';
import AccountNameText from './AccountNameText';
import ConversationAttachmentText from './ConversationAttachmentText';
import MessageAttachmentText from './MessageAttachmentText';
import MessageText from './MessageText';
import ReferenceText from './ReferenceText';
import StatusText from './StatusText';
import TitleText from './TitleText';
import LoadingText from '../common/LoadingText';
import ConversationPage from '../conversation/ConversationPage';
import server from '../../server';

class NotificationsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
      totalNotifications: [],
      account: [],
      conversation: [],
      page: 1,
      isLoading: false,
      rerender: false,
      renderConversationPage: false,
      getNotificationsRoute: `${server}/help-desk/notification/get-notifications`,
      getTotalNotificationsRoute: `${server}/help-desk/notification/get-total-notifications`,
      getAccountRoute: `${server}/help-desk/account/get-account`,
      getConversationRoute: `${server}/help-desk/conversation/get-conversation`,
    };
  }

  componentDidMount() {
    const { accountId, organizationId } = this.props;

    const {
      page,
      getNotificationsRoute,
      getTotalNotificationsRoute,
      getAccountRoute,
    } = this.state;

    this.setState({ isLoading: true });

    fetch(getNotificationsRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        accountId,
        organizationId,
        page,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ notifications: res, isLoading: false }));

    fetch(getTotalNotificationsRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        accountId,
        organizationId,
        page,
      }),
    })
      .then((res) => res.json())
      .then((res) =>
        this.setState({ totalNotifications: res, isLoading: false }),
      );

    fetch(getAccountRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        accountId,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ account: res, isLoading: false }));
  }

  componentDidUpdate() {
    const { accountId, organizationId } = this.props;

    const {
      page,
      rerender,
      getNotificationsRoute,
      getTotalNotificationsRoute,
    } = this.state;

    if (rerender) {
      fetch(getNotificationsRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          accountId,
          organizationId,
          page,
        }),
      })
        .then((res) => res.json())
        .then((res) => this.setState({ notifications: res, rerender: false }));

      fetch(getTotalNotificationsRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          accountId,
          organizationId,
          page,
        }),
      })
        .then((res) => res.json())
        .then((res) =>
          this.setState({ totalNotifications: res, rerender: false }),
        );
    }
  }

  getConversation(conversationId) {
    const { account, getConversationRoute } = this.state;

    if (account[0]) {
      fetch(getConversationRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          conversationId,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          this.setState({ conversation: res, renderConversationPage: true });
        });
    }
  }

  calculatePages(totalEntries) {
    return Math.ceil(totalEntries / 10);
  }

  convertToLocalDate(date) {
    const localDate = new Date(date).toLocaleDateString('en-GB', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

    return localDate;
  }

  convertToLocalTime(time) {
    const localTime = new Date(time).toLocaleTimeString('en-GB');

    return localTime;
  }

  render() {
    const { accountId, organizationId } = this.props;

    const {
      notifications,
      totalNotifications,
      account,
      conversation,
      page,
      isLoading,
      renderConversationPage,
    } = this.state;

    if (isLoading) {
      return <LoadingText />;
    }

    let totalEntries = 0;
    let totalPages = 1;

    if (totalNotifications[0]) {
      totalEntries = parseInt(totalNotifications[0].totalNotifications, 10);
      totalPages = this.calculatePages(
        parseInt(totalNotifications[0].totalNotifications, 10),
      );
    }

    const isFirstPage = page - 1 === 0;
    const isLastPage = page === totalPages;

    const previousPage = [];
    const nextPage = [];

    if (totalPages < 3) {
      for (let i = page + 1; i < page + 2; i += 1) {
        nextPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }

      for (let i = page - 1; i < page; i += 1) {
        previousPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    } else if (page - 1 < 1) {
      for (let i = page + 1; i < page + 3; i += 1) {
        nextPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    } else if (page < totalPages) {
      for (let i = page - 1; i < page; i += 1) {
        previousPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }

      for (let i = page + 1; i < page + 2; i += 1) {
        nextPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    } else if (page === totalPages) {
      for (let i = page - 2; i < page; i += 1) {
        previousPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    }

    return (
      <div>
        {renderConversationPage ? (
          <ConversationPage
            currentUserId={accountId}
            accountType={account[0].type}
            organizationId={organizationId}
            conversation={conversation[0]}
          />
        ) : (
          <div className="notifications-page fade-in">
            <div className="pt-4" />
            <div className="row">
              <div className="ml-5 col">
                <h5 className="ml-1 text-secondary">
                  Notifications&ensp;&#40;
                  {totalEntries}
                  &#41;
                </h5>
              </div>
            </div>
            <br />
            <table className="table">
              <tbody className="notification-tbody">
                {notifications.map((_) => (
                  <tr
                    key={_.notificationId}
                    className="fade-in"
                    onClick={() => this.getConversation(_.conversationId)}
                  >
                    <td className="clickable-notification-log fade-in">
                      {_.type === 'start-conversation' ? (
                        <div>
                          <b className="text-info">
                            <AccountNameText accountId={_.accountId} />
                          </b>
                          &nbsp;started a conversation in&nbsp;
                          <b className="text-info">
                            <ReferenceText conversationId={_.conversationId} />
                          </b>
                          :
                          <div className="truncated-notification-log">
                            <b>
                              <TitleText conversationId={_.conversationId} />
                            </b>
                          </div>
                          <ConversationAttachmentText
                            conversationId={_.conversationId}
                          />
                          <small className="text-secondary">
                            {this.convertToLocalDate(_.timestamp)}
                            &nbsp;
                            {this.convertToLocalTime(_.timestamp)}
                          </small>
                        </div>
                      ) : (
                        <div />
                      )}
                      {_.type === 'send-message' ? (
                        <div>
                          <b className="text-info">
                            <AccountNameText accountId={_.accountId} />
                          </b>
                          &nbsp;sent a message in&nbsp;
                          <b className="text-info">
                            <ReferenceText conversationId={_.conversationId} />
                          </b>
                          :
                          <br />
                          <div className="truncated-notification-log">
                            <i>
                              <MessageText messageId={_.messageId} />
                            </i>
                          </div>
                          <MessageAttachmentText messageId={_.messageId} />
                          <small className="text-secondary">
                            {this.convertToLocalDate(_.timestamp)}
                            &ensp;
                            {this.convertToLocalTime(_.timestamp)}
                          </small>
                        </div>
                      ) : (
                        <div />
                      )}
                      {_.type === 'change-status' ? (
                        <div>
                          <b className="text-info">
                            <AccountNameText accountId={_.accountId} />
                          </b>
                          &nbsp;changed the status of&nbsp;
                          <b className="text-info">
                            <ReferenceText conversationId={_.conversationId} />
                          </b>
                          :
                          <br />
                          <div className="truncated-notification-log">
                            <StatusText statusId={_.statusId} />
                          </div>
                          <small className="text-secondary">
                            {this.convertToLocalDate(_.timestamp)}
                            &ensp;
                            {this.convertToLocalTime(_.timestamp)}
                          </small>
                        </div>
                      ) : (
                        <div />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="notification-pagination">
              {totalEntries > 0 ? (
                <div>
                  {isFirstPage && isLastPage ? (
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn border btn-light"
                        disabled
                      >
                        &lsaquo;
                      </button>
                      <button
                        type="button"
                        className="btn border btn-info"
                        disabled
                      >
                        {page}
                      </button>
                      <button
                        type="button"
                        className="btn border btn-light"
                        disabled
                      >
                        &rsaquo;
                      </button>
                    </div>
                  ) : (
                    <div>
                      {isFirstPage ? (
                        <div className="text-center">
                          <button
                            type="button"
                            className="btn border btn-light"
                            disabled
                          >
                            &lsaquo;
                          </button>
                          <button
                            type="button"
                            className="btn border btn-info"
                            disabled
                          >
                            {page}
                          </button>
                          {nextPage}
                          <button
                            type="button"
                            className="btn border btn-light"
                            onClick={() =>
                              this.setState({ page: page + 1, rerender: true })
                            }
                          >
                            &rsaquo;
                          </button>
                        </div>
                      ) : (
                        <div>
                          {isLastPage ? (
                            <div className="text-center">
                              <button
                                type="button"
                                className="btn border btn-light"
                                onClick={() =>
                                  this.setState({
                                    page: page - 1,
                                    rerender: true,
                                  })
                                }
                              >
                                &lsaquo;
                              </button>
                              {previousPage}
                              <button
                                type="button"
                                className="btn border btn-info"
                                disabled
                              >
                                {page}
                              </button>
                              <button
                                type="button"
                                className="btn border btn-light"
                                disabled
                              >
                                &rsaquo;
                              </button>
                            </div>
                          ) : (
                            <div>
                              {!isFirstPage && !isLastPage ? (
                                <div className="text-center">
                                  <button
                                    type="button"
                                    className="btn border btn-light"
                                    onClick={() =>
                                      this.setState({
                                        page: page - 1,
                                        rerender: true,
                                      })
                                    }
                                  >
                                    &lsaquo;
                                  </button>
                                  {previousPage}
                                  <button
                                    type="button"
                                    className="btn border btn-info"
                                    disabled
                                  >
                                    {page}
                                  </button>
                                  {nextPage}
                                  <button
                                    type="button"
                                    className="btn border btn-light"
                                    onClick={() =>
                                      this.setState({
                                        page: page + 1,
                                        rerender: true,
                                      })
                                    }
                                  >
                                    &rsaquo;
                                  </button>
                                </div>
                              ) : (
                                <div />
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

NotificationsPage.propTypes = {
  accountId: PropTypes.number.isRequired,
  organizationId: PropTypes.number.isRequired,
};

export default NotificationsPage;
