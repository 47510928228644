import React from 'react';
import PropTypes from 'prop-types';
import LoadingText from '../common/LoadingText';
import server from '../../server';

class TitleText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      titleArray: [],
      isLoading: false,
      getTitleRoute: `${server}/help-desk/conversation/get-title`,
    };
  }

  componentDidMount() {
    const { conversationId } = this.props;

    const { getTitleRoute } = this.state;

    fetch(getTitleRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        conversationId,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ titleArray: res }));
  }

  render() {
    const { titleArray, isLoading } = this.state;

    let title = '';

    if (titleArray[0]) {
      title = titleArray[0].title;
    }

    if (isLoading) {
      return <LoadingText />;
    }

    return <span>{title}</span>;
  }
}

TitleText.propTypes = {
  conversationId: PropTypes.number.isRequired,
};

export default TitleText;
