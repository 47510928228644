import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './Sidebar.css';
import StartConversationPage from '../conversation/StartConversationPage';
import ManageConversationsPage from '../conversation/ManageConversationsPage';
import CreateAccounPage from '../account/CreateAccountPage';
import ManageAccountsPage from '../account/ManageAccountsPage';
import AddOrganizationPage from '../organization/AddOrganizationPage';
import ManageOrganizationsPage from '../organization/ManageOrganizationsPage';
import LoginPage from '../user/ProfilePage';
import ChangePasswordPage from '../user/ChangePasswordPage';
import server from '../../server';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      account: [],
      getAccountRoute: `${server}/help-desk/account/get-account`,
    };
  }

  componentDidMount() {
    const { accountId } = this.props;

    const { getAccountRoute } = this.state;

    fetch(getAccountRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        accountId,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ account: res }));
  }

  removeMainContent() {
    ReactDOM.render(<div />, document.getElementById('main'));
  }

  displayStartConversation() {
    const { accountId } = this.props;

    const { account } = this.state;

    if (account[0] !== undefined) {
      ReactDOM.render(
        <StartConversationPage
          currentUserId={accountId}
          accountType={account[0].type}
          organizationId={account[0].organizationId}
        />,
        document.getElementById('main'),
      );
    }
  }

  displayManageConversations() {
    const { accountId } = this.props;

    const { account } = this.state;

    if (account[0] !== undefined) {
      ReactDOM.render(
        <ManageConversationsPage
          currentUserId={accountId}
          accountType={account[0].type}
          organizationId={account[0].organizationId}
        />,
        document.getElementById('main'),
      );
    }
  }

  displayCreateAccount() {
    const { accountId } = this.props;

    const { account } = this.state;

    ReactDOM.render(
      <CreateAccounPage
        currentUserId={accountId}
        accountType={account[0].type}
        organizationId={account[0].organizationId}
      />,
      document.getElementById('main'),
    );
  }

  displayManageAccounts() {
    const { accountId } = this.props;

    const { account } = this.state;

    ReactDOM.render(
      <ManageAccountsPage
        currentUserId={accountId}
        accountType={account[0].type}
        organizationId={account[0].organizationId}
      />,
      document.getElementById('main'),
    );
  }

  displayAddOrganization() {
    const { accountId } = this.props;

    const { account } = this.state;

    ReactDOM.render(
      <AddOrganizationPage
        currentUserId={accountId}
        accountType={account[0].type}
        organizationId={account[0].organizationId}
      />,
      document.getElementById('main'),
    );
  }

  displayManageOrganizations() {
    const { accountId } = this.props;

    const { account } = this.state;

    ReactDOM.render(
      <ManageOrganizationsPage
        currentUserId={accountId}
        accountType={account[0].type}
        organizationId={account[0].organizationId}
      />,
      document.getElementById('main'),
    );
  }

  displayProfile() {
    const { accountId } = this.props;

    ReactDOM.render(
      <LoginPage accountId={accountId} />,
      document.getElementById('main'),
    );
  }

  displayChangePassword() {
    const { accountId, email } = this.props;

    ReactDOM.render(
      <ChangePasswordPage accountId={accountId} email={email} />,
      document.getElementById('main'),
    );
  }

  handleLogout() {
    function LogoutNotification() {
      return (
        <div className="alert alert-success">
          <span>Logged out.</span>
        </div>
      );
    }

    localStorage.removeItem('accountId');
    localStorage.removeItem('organizationId');
    localStorage.removeItem('email');
    localStorage.removeItem('name');
    localStorage.removeItem('token');

    ReactDOM.render(
      <LogoutNotification />,
      document.getElementById('notification'),
    );

    setTimeout(() => {
      window.location.href = '/';
    }, 1000);
  }

  render() {
    const { account } = this.state;

    if (account[0]) {
      return (
        <div className="sidebar">
          <div className="logo">
            <div className="cid">cid</div>
            <div className="systems">systems</div>
          </div>
          {/* <i
          className="sidebar-icon fa fa-inbox fa-lg"
          onClick={this.displayManageConversations.bind(this)}
        />
        <i className="sidebar-icon fa fa-id-card  fa-lg" />
        <i className="sidebar-icon fa fa-building fa-lg" /> */}
        </div>
      );
    }

    return <div />;
  }
}

Sidebar.propTypes = {
  accountId: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
};

export default Sidebar;
