import React from 'react';
import PropTypes from 'prop-types';
import LoadingText from '../common/LoadingText';
import server from '../../server';

class MessageText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      messageArray: [],
      isLoading: false,
      getMessageRoute: `${server}/help-desk/conversation/get-message`,
    };
  }

  componentDidMount() {
    const { messageId } = this.props;

    const { getMessageRoute } = this.state;

    fetch(getMessageRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        messageId,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ messageArray: res }));
  }

  render() {
    const { messageArray, isLoading } = this.state;

    let message = '';

    if (messageArray[0]) {
      message = messageArray[0].message;
    }

    if (isLoading) {
      return <LoadingText />;
    }

    return <span>{message}</span>;
  }
}

MessageText.propTypes = {
  messageId: PropTypes.number.isRequired,
};

export default MessageText;
