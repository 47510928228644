import React from 'react';
import PropTypes from 'prop-types';
import LoadingText from '../common/LoadingText';
import server from '../../server';

class AccountNameText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accountNameArray: [],
      isLoading: false,
      getAccountNameRoute: `${server}/help-desk/account/get-account-name`,
    };
  }

  componentDidMount() {
    const { accountId } = this.props;

    const { getAccountNameRoute } = this.state;

    fetch(getAccountNameRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        accountId,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ accountNameArray: res }));
  }

  render() {
    const { accountNameArray, isLoading } = this.state;

    let accountName = '';

    if (accountNameArray[0]) {
      accountName = accountNameArray[0].name;
    }

    if (isLoading) {
      return <LoadingText />;
    }

    return <span>{accountName}</span>;
  }
}

AccountNameText.propTypes = {
  accountId: PropTypes.number.isRequired,
};

export default AccountNameText;
