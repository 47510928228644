import React, { useState, useEffect } from 'react';
import SessionExpiredPage from './SessionExpiredPage';
import Sidebar from '../common/Sidebar';
import Header from '../common/Header';
import Notification from '../notification/Notification';
import ServerStatusText from '../server-status/ServerStatusText';
import LoginPage from '../user/LoginPage';
import ControlPanel from '../common/ControlPanel';
import server from '../../server';

const Authentication = () => {
  const accountId = Number(localStorage.getItem('accountId'));
  const organizationId = Number(localStorage.getItem('organizationId'));
  const email = localStorage.getItem('email');
  const name = localStorage.getItem('name');
  const token = localStorage.getItem('token');
  const authenticateTokenRoute = `${server}/help-desk/authentication/authenticate-token`;

  const [authenticationResponse, setAuthenticationResponse] = useState([]);

  useEffect(() => {
    fetch(authenticateTokenRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        accountId,
        token,
      }),
    })
      .then((res) => res.json())
      .then((res) => setAuthenticationResponse(res));
  }, [accountId, authenticateTokenRoute, token]);

  return (
    <div>
      {authenticationResponse &&
      authenticationResponse.length > 0 &&
      authenticationResponse[0].response === 'session-authenticated' &&
      accountId &&
      organizationId &&
      email &&
      name &&
      token ? (
        <ControlPanel
          accountId={accountId}
          organizationId={organizationId}
          email={email}
          name={name}
          token={token}
        />
      ) : (
        <div>
          {authenticationResponse &&
          authenticationResponse.length > 0 &&
          authenticationResponse[0].response === 'session-expired' &&
          token ? (
            <SessionExpiredPage />
          ) : (
            <div>
              {!accountId || !organizationId || !email || !name || !token ? (
                <LoginPage />
              ) : (
                <div />
              )}
            </div>
          )}
        </div>
      )}
      {accountId && organizationId && email && name && token ? (
        <Sidebar
          accountId={accountId}
          organizationId={organizationId}
          email={email}
          name={name}
          token={token}
        />
      ) : (
        <div />
      )}
      <Header />
      <Notification />
      <ServerStatusText />
    </div>
  );
};

export default Authentication;
