import React from 'react';
import './Notification.css';

function Notification() {
  return (
    <div className="alert">
      <span className="unselectable">&nbsp;</span>
    </div>
  );
}

export default Notification;
