import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import server from '../../server';

class AddProjectPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      addProjectRoute: `${server}/help-desk/organization/add-project`,
    };
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { organizationId } = this.props;

    const { name, addProjectRoute } = this.state;

    function EmptyFieldsNotification() {
      return (
        <div className="alert alert-warning">
          <span>Enter the required data.</span>
        </div>
      );
    }

    function ProjectAddedNotification() {
      return (
        <div className="alert alert-success">
          <span>Project added.</span>
        </div>
      );
    }

    if (name === '') {
      ReactDOM.render(
        <EmptyFieldsNotification />,
        document.getElementById('notification'),
      );
    } else {
      fetch(addProjectRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          organizationId,
          code: event.target.code.value,
          name: event.target.name.value,
        }),
      });

      ReactDOM.render(
        <ProjectAddedNotification />,
        document.getElementById('notification'),
      );

      setTimeout(() => {
        window.location.href = '/manage-organizations';
      }, 1000);
    }
  }

  render() {
    const { organizationId } = this.props;

    return (
      <div className="fade-in centered-form mx-auto">
        <div className="pt-3 pl-3">
          <a href="manage-organizations" className="back-link text-secondary">
            <i className="fas fa-arrow-left back-link-icon" />
            &nbsp;Back
          </a>
        </div>
        <form onSubmit={this.handleSubmit.bind(this)}>
          <h5 className="text-center text-secondary">Add Project</h5>
          <br />
          <input
            type="hidden"
            name="organizationId"
            defaultValue={organizationId}
          />
          <div className="form-group">
            <label htmlFor="code" className="col-12 text-secondary">
              Code
              <input
                type="text"
                id="code"
                className="form-control"
                name="code"
                onChange={this.handleChange.bind(this)}
                placeholder="Enter code"
              />
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="name" className="col-12 text-secondary">
              Name
              <input
                type="text"
                id="name"
                className="form-control"
                name="name"
                onChange={this.handleChange.bind(this)}
                placeholder="Enter name"
              />
            </label>
          </div>
          <br />
          <div className="text-center">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

AddProjectPage.propTypes = {
  organizationId: PropTypes.number.isRequired,
};

export default AddProjectPage;
