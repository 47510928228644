import React from 'react';
import PropTypes from 'prop-types';
import LoadingText from '../common/LoadingText';
import server from '../../server';

class LifetimeStatusesPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      organizations: [],
      totalOrganizations: [],
      projects: [],
      statusLog: [],
      filterString: '',
      searchString: '',
      page: 1,
      isLoading: false,
      rerender: false,
      getOrganizationsRoute: `${server}/help-desk/organization/get-organizations`,
      getTotalOrganizationsRoute: `${server}/help-desk/organization/get-total-organizations`,
      getProjectsRoute: `${server}/help-desk/organization/get-projects`,
      getFullStatusLogRoute: `${server}/help-desk/statistics/get-full-status-log`,
    };
  }

  componentDidMount() {
    const { currentUserId, accountType, organizationId } = this.props;

    const {
      filterString,
      searchString,
      page,
      getOrganizationsRoute,
      getTotalOrganizationsRoute,
      getProjectsRoute,
      getFullStatusLogRoute,
    } = this.state;

    this.setState({ isLoading: true });

    fetch(getOrganizationsRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        currentUserId,
        accountType,
        organizationId,
        filterString,
        searchString,
        page,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ organizations: res, isLoading: false }));

    fetch(getTotalOrganizationsRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        currentUserId,
        accountType,
        organizationId,
        filterString,
        searchString,
        page,
      }),
    })
      .then((res) => res.json())
      .then((res) =>
        this.setState({ totalOrganizations: res, isLoading: false }),
      );

    fetch(getProjectsRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((res) => this.setState({ projects: res, isLoading: false }));

    fetch(getFullStatusLogRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((res) => this.setState({ statusLog: res }));
  }

  componentDidUpdate() {
    const { currentUserId, accountType, organizationId } = this.props;

    const {
      filterString,
      searchString,
      page,
      rerender,
      getOrganizationsRoute,
      getTotalOrganizationsRoute,
    } = this.state;

    if (rerender) {
      fetch(getOrganizationsRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          currentUserId,
          accountType,
          organizationId,
          filterString,
          searchString,
          page,
        }),
      })
        .then((res) => res.json())
        .then((res) => this.setState({ organizations: res, rerender: false }));

      fetch(getTotalOrganizationsRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          currentUserId,
          accountType,
          organizationId,
          filterString,
          searchString,
          page,
        }),
      })
        .then((res) => res.json())
        .then((res) =>
          this.setState({ totalOrganizations: res, rerender: false }),
        );
    }
  }

  calculatePages(totalEntries) {
    return Math.ceil(totalEntries / 10);
  }

  calculateTotalDurationPerOrganization(statusLog) {
    let issueOpened = 0;
    let issueRequiresClarification = 0;
    let issueUnderEvaluation = 0;
    let issueConfirmed = 0;
    let issueResolved = 0;
    let enhancementRequested = 0;
    let enhancementUnderDevelopment = 0;

    let issueOpenedCount = 0;
    let issueRequiresClarificationCount = 0;
    let issueUnderEvaluationCount = 0;
    let issueConfirmedCount = 0;
    let issueResolvedCount = 0;
    let enhancementRequestedCount = 0;
    let enhancementUnderDevelopmentCount = 0;

    for (let i = 0; i < statusLog.length; i += 1) {
      if (statusLog[i].statusId.toString() === '1') {
        issueOpenedCount += 1;
      }

      if (statusLog[i].statusId.toString() === '2') {
        issueRequiresClarificationCount += 1;
      }

      if (statusLog[i].statusId.toString() === '3') {
        issueUnderEvaluationCount += 1;
      }

      if (statusLog[i].statusId.toString() === '4') {
        issueConfirmedCount += 1;
      }

      if (statusLog[i].statusId.toString() === '5') {
        issueResolvedCount += 1;
      }

      if (statusLog[i].statusId.toString() === '7') {
        enhancementRequestedCount += 1;
      }

      if (statusLog[i].statusId.toString() === '8') {
        enhancementUnderDevelopmentCount += 1;
      }
    }

    for (let i = 0; i < statusLog.length; i += 1) {
      if (statusLog[i].statusId.toString() === '1') {
        issueOpened +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60 /
          issueOpenedCount;
      }

      if (statusLog[i].statusId.toString() === '2') {
        issueRequiresClarification +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60 /
          issueRequiresClarificationCount;
      }

      if (statusLog[i].statusId.toString() === '3') {
        issueUnderEvaluation +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60 /
          issueUnderEvaluationCount;
      }

      if (statusLog[i].statusId.toString() === '4') {
        issueConfirmed +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60 /
          issueConfirmedCount;
      }

      if (statusLog[i].statusId.toString() === '5') {
        issueResolved +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60 /
          issueResolvedCount;
      }

      if (statusLog[i].statusId.toString() === '7') {
        enhancementRequested +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60 /
          enhancementRequestedCount;
      }

      if (statusLog[i].statusId.toString() === '8') {
        enhancementUnderDevelopment +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60 /
          enhancementUnderDevelopmentCount;
      }
    }

    return (
      <div className="fade-in">
        <table className="table">
          <tbody>
            <tr>
              <td>
                <div>
                  <i className="red-500 fa fa-square" />
                  &ensp;Issue opened: waiting for agent response
                </div>
                <div>
                  <i className="orange-500 fa fa-square" />
                  &ensp;Issue requires clarification: waiting for client
                  response
                </div>
                <div>
                  <i className="yellow-500 fa fa-square" />
                  &ensp;Issue under evaluation: waiting for developer feedback
                </div>
                <div>
                  <i className="green-500 fa fa-square" />
                  &ensp;Issue confirmed: waiting for developer patch
                </div>
                <div>
                  <i className="blue-500 fa fa-square" />
                  &ensp;Issue resolved: waiting for client confirmation
                </div>
                <div>
                  <i className="indigo-500 fa fa-square" />
                  &ensp;Enhancement requested: waiting for developer
                  confirmation
                </div>
                <div>
                  <i className="purple-500 fa fa-square" />
                  &ensp;Enhancement under development: waiting for developer
                  patch
                </div>
              </td>
              <td>
                <div className="text-right">
                  {Math.round(issueOpened)}
                  &nbsp;h average
                </div>
                <div className="text-right">
                  {Math.round(issueRequiresClarification)}
                  &nbsp;h average
                </div>
                <div className="text-right">
                  {Math.round(issueUnderEvaluation)}
                  &nbsp;h average
                </div>
                <div className="text-right">
                  {Math.round(issueConfirmed)}
                  &nbsp;h average
                </div>
                <div className="text-right">
                  {Math.round(issueResolved)}
                  &nbsp;h average
                </div>
                <div className="text-right">
                  {Math.round(enhancementRequested)}
                  &nbsp;h average
                </div>
                <div className="text-right">
                  {Math.round(enhancementUnderDevelopment)}
                  &nbsp;h average
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <b>Total</b>
              </td>
              <td className="text-right">
                <b>
                  {Math.round(
                    (issueOpened +
                      issueRequiresClarification +
                      issueUnderEvaluation +
                      issueConfirmed +
                      issueResolved +
                      enhancementRequested +
                      enhancementUnderDevelopment) /
                      7,
                  )}
                  &nbsp;h average
                </b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  calculateTotalDurationPerProject(statusLog) {
    let issueOpened = 0;
    let issueRequiresClarification = 0;
    let issueUnderEvaluation = 0;
    let issueConfirmed = 0;
    let issueResolved = 0;
    let enhancementRequested = 0;
    let enhancementUnderDevelopment = 0;

    let issueOpenedCount = 0;
    let issueRequiresClarificationCount = 0;
    let issueUnderEvaluationCount = 0;
    let issueConfirmedCount = 0;
    let issueResolvedCount = 0;
    let enhancementRequestedCount = 0;
    let enhancementUnderDevelopmentCount = 0;

    for (let i = 0; i < statusLog.length; i += 1) {
      if (statusLog[i].statusId.toString() === '1') {
        issueOpenedCount += 1;
      }

      if (statusLog[i].statusId.toString() === '2') {
        issueRequiresClarificationCount += 1;
      }

      if (statusLog[i].statusId.toString() === '3') {
        issueUnderEvaluationCount += 1;
      }

      if (statusLog[i].statusId.toString() === '4') {
        issueConfirmedCount += 1;
      }

      if (statusLog[i].statusId.toString() === '5') {
        issueResolvedCount += 1;
      }

      if (statusLog[i].statusId.toString() === '7') {
        enhancementRequestedCount += 1;
      }

      if (statusLog[i].statusId.toString() === '8') {
        enhancementUnderDevelopmentCount += 1;
      }
    }

    for (let i = 0; i < statusLog.length; i += 1) {
      if (statusLog[i].statusId.toString() === '1') {
        issueOpened +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60 /
          issueOpenedCount;
      }

      if (statusLog[i].statusId.toString() === '2') {
        issueRequiresClarification +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60 /
          issueRequiresClarificationCount;
      }

      if (statusLog[i].statusId.toString() === '3') {
        issueUnderEvaluation +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60 /
          issueUnderEvaluationCount;
      }

      if (statusLog[i].statusId.toString() === '4') {
        issueConfirmed +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60 /
          issueConfirmedCount;
      }

      if (statusLog[i].statusId.toString() === '5') {
        issueResolved +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60 /
          issueResolvedCount;
      }

      if (statusLog[i].statusId.toString() === '7') {
        enhancementRequested +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60 /
          enhancementRequestedCount;
      }

      if (statusLog[i].statusId.toString() === '8') {
        enhancementUnderDevelopment +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60 /
          enhancementUnderDevelopmentCount;
      }
    }

    return (
      <div className="fade-in">
        <table className="table text-secondary">
          <tbody>
            <tr>
              <td>
                <div>
                  <i className="red-500 fa fa-square" />
                  &ensp;Issue opened: waiting for agent response
                </div>
                <div>
                  <i className="orange-500 fa fa-square" />
                  &ensp;Issue requires clarification: waiting for client
                  response
                </div>
                <div>
                  <i className="yellow-500 fa fa-square" />
                  &ensp;Issue under evaluation: waiting for developer feedback
                </div>
                <div>
                  <i className="green-500 fa fa-square" />
                  &ensp;Issue confirmed: waiting for developer patch
                </div>
                <div>
                  <i className="blue-500 fa fa-square" />
                  &ensp;Issue resolved: waiting for client confirmation
                </div>
                <div>
                  <i className="indigo-500 fa fa-square" />
                  &ensp;Enhancement requested: waiting for developer
                  confirmation
                </div>
                <div>
                  <i className="purple-500 fa fa-square" />
                  &ensp;Enhancement under development: waiting for developer
                  patch
                </div>
              </td>
              <td>
                <div className="text-right">
                  {Math.round(issueOpened)}
                  &nbsp;h average
                </div>
                <div className="text-right">
                  {Math.round(issueRequiresClarification)}
                  &nbsp;h average
                </div>
                <div className="text-right">
                  {Math.round(issueUnderEvaluation)}
                  &nbsp;h average
                </div>
                <div className="text-right">
                  {Math.round(issueConfirmed)}
                  &nbsp;h average
                </div>
                <div className="text-right">
                  {Math.round(issueResolved)}
                  &nbsp;h average
                </div>
                <div className="text-right">
                  {Math.round(enhancementRequested)}
                  &nbsp;h average
                </div>
                <div className="text-right">
                  {Math.round(enhancementUnderDevelopment)}
                  &nbsp;h average
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <b>Total</b>
              </td>
              <td className="text-right">
                <b>
                  {Math.round(
                    (issueOpened +
                      issueRequiresClarification +
                      issueUnderEvaluation +
                      issueConfirmed +
                      issueResolved +
                      enhancementRequested +
                      enhancementUnderDevelopment) /
                      7,
                  )}
                  &nbsp;h average
                </b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const {
      organizations,
      totalOrganizations,
      projects,
      statusLog,
      page,
      isLoading,
    } = this.state;

    if (isLoading) {
      return <LoadingText />;
    }

    let totalEntries = 0;
    let totalPages = 1;

    if (totalOrganizations[0]) {
      totalEntries = parseInt(totalOrganizations[0].totalOrganizations, 10);
      totalPages = this.calculatePages(
        parseInt(totalOrganizations[0].totalOrganizations, 10),
      );
    }

    const isFirstPage = page - 1 === 0;
    const isLastPage = page === totalPages;

    const previousPage = [];
    const nextPage = [];

    if (totalPages < 3) {
      for (let i = page + 1; i < page + 2; i += 1) {
        nextPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }

      for (let i = page - 1; i < page; i += 1) {
        previousPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    } else if (page - 1 < 1) {
      for (let i = page + 1; i < page + 3; i += 1) {
        nextPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    } else if (page < totalPages) {
      for (let i = page - 1; i < page; i += 1) {
        previousPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }

      for (let i = page + 1; i < page + 2; i += 1) {
        nextPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    } else if (page === totalPages) {
      for (let i = page - 2; i < page; i += 1) {
        previousPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    }

    return (
      <div className="fade-in container-fluid p-5">
        <div className="pt-4" />
        <div className="row">
          <h5 className="text-secondary">Lifetime Statuses</h5>
        </div>
        <br />
        <div className="row justify-content-md-center">
          <div className="col-12">
            <table className="table">
              <tbody>
                {organizations.map((org) => (
                  <tr key={org.organizationId}>
                    <td className="fade-in">
                      <td>
                        <b>{org.name}</b>
                      </td>
                      {this.calculateTotalDurationPerOrganization(
                        statusLog.filter(
                          (stat) => stat.organizationId === org.organizationId,
                        ),
                      )}
                      {projects
                        .filter(
                          (proj) => proj.organizationId === org.organizationId,
                        )
                        .map((proj) => (
                          <div key={proj.projectId} className="px-5">
                            <ul className="text-secondary">
                              <li>
                                <small>
                                  <td>
                                    <b>
                                      &#91;
                                      {proj.code}
                                      &#93;&nbsp;
                                      {proj.name}
                                    </b>
                                  </td>
                                  {this.calculateTotalDurationPerProject(
                                    statusLog.filter(
                                      (stat) =>
                                        stat.projectId === proj.projectId,
                                    ),
                                  )}
                                </small>
                              </li>
                            </ul>
                          </div>
                        ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div>
          {totalEntries > 0 ? (
            <div>
              {isFirstPage && isLastPage ? (
                <div className="text-center">
                  <button
                    type="button"
                    className="btn border btn-light"
                    disabled
                  >
                    &lsaquo;
                  </button>
                  <button
                    type="button"
                    className="btn border btn-info"
                    disabled
                  >
                    {page}
                  </button>
                  <button
                    type="button"
                    className="btn border btn-light"
                    disabled
                  >
                    &rsaquo;
                  </button>
                </div>
              ) : (
                <div>
                  {isFirstPage ? (
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn border btn-light"
                        disabled
                      >
                        &lsaquo;
                      </button>
                      <button
                        type="button"
                        className="btn border btn-info"
                        disabled
                      >
                        {page}
                      </button>
                      {nextPage}
                      <button
                        type="button"
                        className="btn border btn-light"
                        onClick={() =>
                          this.setState({
                            page: page + 1,
                            rerender: true,
                          })
                        }
                      >
                        &rsaquo;
                      </button>
                    </div>
                  ) : (
                    <div>
                      {isLastPage ? (
                        <div className="text-center">
                          <button
                            type="button"
                            className="btn border btn-light"
                            onClick={() =>
                              this.setState({
                                page: page - 1,
                                rerender: true,
                              })
                            }
                          >
                            &lsaquo;
                          </button>
                          {previousPage}
                          <button
                            type="button"
                            className="btn border btn-info"
                            disabled
                          >
                            {page}
                          </button>
                          <button
                            type="button"
                            className="btn border btn-light"
                            disabled
                          >
                            &rsaquo;
                          </button>
                        </div>
                      ) : (
                        <div>
                          {!isFirstPage && !isLastPage ? (
                            <div className="text-center">
                              <button
                                type="button"
                                className="btn border btn-light"
                                onClick={() =>
                                  this.setState({
                                    page: page - 1,
                                    rerender: true,
                                  })
                                }
                              >
                                &lsaquo;
                              </button>
                              {previousPage}
                              <button
                                type="button"
                                className="btn border btn-info"
                                disabled
                              >
                                {page}
                              </button>
                              {nextPage}
                              <button
                                type="button"
                                className="btn border btn-light"
                                onClick={() =>
                                  this.setState({
                                    page: page + 1,
                                    rerender: true,
                                  })
                                }
                              >
                                &rsaquo;
                              </button>
                            </div>
                          ) : (
                            <div />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  }
}

LifetimeStatusesPage.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  accountType: PropTypes.string.isRequired,
  organizationId: PropTypes.number.isRequired,
};

export default LifetimeStatusesPage;
