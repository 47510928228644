import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './ConversationPage.css';
import EditConversationPage from './EditConversationPage';
import LoadingText from '../common/LoadingText';
import MessageDisplay from './MessageDisplay';
import StatusLogPage from './StatusLogPage';
import server from '../../server';
import socket from '../../socket';

class ConversationPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      account: [],
      project: [],
      sender: [],
      messages: [],
      previousConversation: {},
      scrolledDown: false,
      currentStatus: '',
      statusChanged: false,
      message: '',
      messageSent: false,
      isLoading: false,
      renderStatusLogPage: false,
      renderEditConversationPage: false,
      viewConversationRoute: `${server}/help-desk/conversation/view-conversation`,
      getProjectRoute: `${server}/help-desk/organization/get-project`,
      getAccountRoute: `${server}/help-desk/account/get-account`,
      getLastMessageRoute: `${server}/help-desk/conversation/get-last-message`,
      changeStatusRoute: `${server}/help-desk/conversation/change-status`,
      changeStatusBroadcastRoute: `${server}/help-desk/broadcast/change-status-broadcast`,
      downloadAttachmentRoute: `${server}/help-desk/conversation/download-attachment`,
      sendMessageRoute: `${server}/help-desk/conversation/send-message`,
      sendMessageBroadcastRoute: `${server}/help-desk/broadcast/send-message-broadcast`,
      deleteConversationRoute: `${server}/help-desk/conversation/delete-conversation`,
    };
  }

  componentDidMount() {
    const { currentUserId, conversation } = this.props;

    const { conversationId, projectId, authorId } = conversation;

    const {
      viewConversationRoute,
      getProjectRoute,
      getAccountRoute,
    } = this.state;

    this.setState({ previousConversation: conversation, isLoading: true });

    fetch(viewConversationRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        conversationId,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ messages: res, isLoading: false }));

    socket.on('send-message', () => {
      setTimeout(() => {
        this.setState({ messageSent: true });
      }, 100);
    });

    fetch(getProjectRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        projectId,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ project: res, isLoading: false }));

    fetch(getAccountRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        accountId: authorId,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ account: res, isLoading: false }));

    fetch(getAccountRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        accountId: currentUserId,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ sender: res }));
  }

  componentDidUpdate() {
    const { conversation } = this.props;

    const { conversationId } = conversation;

    const {
      previousConversation,
      statusChanged,
      messageSent,
      viewConversationRoute,
    } = this.state;

    function scrollToBottom() {
      const messageDisplay = document.getElementById('message-display');

      if (messageDisplay) {
        messageDisplay.scrollTop = messageDisplay.scrollHeight;
      }
    }

    if (messageSent) {
      fetch(viewConversationRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ conversationId }),
      })
        .then((res) => res.json())
        .then((res) => {
          this.setState({ messages: res, messageSent: false });

          scrollToBottom();
        });
    }

    if (statusChanged) {
      fetch(viewConversationRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ conversationId }),
      })
        .then((res) => res.json())
        .then((res) => {
          this.setState({ messages: res, statusChanged: false });

          scrollToBottom();
        });
    }

    if (conversation !== previousConversation) {
      fetch(viewConversationRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ conversationId }),
      })
        .then((res) => res.json())
        .then((res) => {
          this.setState({
            messages: res,
            previousConversation: conversation,
          });

          scrollToBottom();
        });
    }
  }

  scrollToBottom() {
    const { scrolledDown } = this.state;

    if (!scrolledDown) {
      const messageDisplay = document.getElementById('message-display');

      if (messageDisplay) {
        messageDisplay.scrollTop = messageDisplay.scrollHeight;
      }

      this.setState({ scrolledDown: true });
    }
  }

  // displayStatusLog(conversation) {
  //   ReactDOM.render(
  //     <StatusLogPage conversation={conversation} />,
  //     document.getElementById('root'),
  //   );
  // }

  changeStatus(event) {
    const { currentUserId, conversation } = this.props;

    const { conversationId, organizationId, reference } = conversation;

    const {
      account,
      sender,
      changeStatusRoute,
      changeStatusBroadcastRoute,
    } = this.state;
    let senderName = '';

    if (sender[0]) {
      for (let i = 0; i < account.length; i += 1) {
        senderName = sender[i].name;
      }
    }

    const issueOpened = 'Issue opened: waiting for agent response';
    const issueRequiresClarification =
      'Issue requires clarification: waiting for client response';
    const issueUnderEvaluation =
      'Issue under evaluation: waiting for developer feedback';
    const issueConfirmed = 'Issue confirmed: waiting for developer patch';
    const issueResolved = 'Issue resolved: waiting for client confirmation';
    const issueClosed = 'Issue closed';
    const enhancementRequested =
      'Enhancement requested: waiting for developer confirmation';
    const enhancementUnderDevelopment =
      'Enhancement under development: waiting for developer patch';
    const enhancementCompleted = 'Enhancement completed';

    let status = '';

    switch (event.target.value) {
      case '1':
        status = issueOpened;
        break;
      case '2':
        status = issueRequiresClarification;
        break;
      case '3':
        status = issueUnderEvaluation;
        break;
      case '4':
        status = issueConfirmed;
        break;
      case '5':
        status = issueResolved;
        break;
      case '6':
        status = issueClosed;
        break;
      case '7':
        status = enhancementRequested;
        break;
      case '8':
        status = enhancementUnderDevelopment;
        break;
      case '9':
        status = enhancementCompleted;
        break;
      default:
        break;
    }

    this.setState({ currentStatus: event.target.value, statusChanged: true });

    fetch(changeStatusRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        accountId: currentUserId,
        conversationId,
        reference,
        statusId: event.target.value,
      }),
    });

    fetch(changeStatusBroadcastRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        accountId: currentUserId,
        organizationId,
        conversationId,
        reference,
        statusId: event.target.value,
        status,
      }),
    });

    socket.emit('change-status', {
      currentUserId,
      organizationId,
      conversationId,
      name: senderName,
      reference,
      statusId: event.target.value,
      status,
    });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  convertToLocalDate(date) {
    const localDate = new Date(date).toLocaleDateString('en-GB', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

    return localDate;
  }

  convertToLocalTime(time) {
    const localTime = new Date(time).toLocaleTimeString('en-GB');

    return localTime;
  }

  displayAttachment(attachment) {
    const { conversation } = this.props;

    const { authorId } = conversation;

    const { downloadAttachmentRoute } = this.state;

    if (attachment) {
      return (
        <form method="POST" action={downloadAttachmentRoute}>
          <input type="hidden" name="senderId" defaultValue={authorId} />
          <input type="hidden" name="attachment" defaultValue={attachment} />
          <i className="fa fa-paperclip" />
          &ensp;
          <button type="submit" className="btn btn-light">
            {attachment.substring(20)}
          </button>
        </form>
      );
    }

    return <div />;
  }

  handleClickEdit(conversation) {
    const { currentUserId, accountType, organizationId } = this.props;

    ReactDOM.render(
      <EditConversationPage
        currentUserId={currentUserId}
        accountType={accountType}
        organizationId={organizationId}
        conversation={conversation}
      />,
      document.getElementById('main'),
    );
  }

  handleClickDelete(conversationId) {
    const { deleteConversationRoute } = this.state;

    fetch(deleteConversationRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ conversationId }),
    });

    function ConversationDeletedNotification() {
      return (
        <div className="alert alert-success">
          <span>Conversation deleted.</span>
        </div>
      );
    }

    ReactDOM.render(
      <ConversationDeletedNotification />,
      document.getElementById('notification'),
    );

    setTimeout(() => {
      window.location.href = '/manage-conversations';
    }, 1000);
  }

  sendMessage(event) {
    event.preventDefault();

    const { currentUserId, conversation } = this.props;

    const { conversationId, organizationId, reference, title } = conversation;

    const {
      account,
      project,
      sender,
      message,
      getLastMessageRoute,
      sendMessageRoute,
      sendMessageBroadcastRoute,
    } = this.state;

    let senderName = '';

    if (sender[0]) {
      for (let i = 0; i < account.length; i += 1) {
        senderName = sender[i].name;
      }
    }

    let projectName = '';

    if (project[0]) {
      for (let i = 0; i < project.length; i += 1) {
        projectName = project[i].name;
      }
    }

    const attachmentInput = document.getElementById('attachment');

    let attachment = '';

    if (attachmentInput.files) {
      const file = attachmentInput.files[0];

      attachment = file;
    }

    function scrollToBottom() {
      const messageDisplay = document.getElementById('message-display');

      if (messageDisplay) {
        messageDisplay.scrollTop = messageDisplay.scrollHeight;
      }
    }

    const formData = new FormData();

    formData.append('conversationId', conversationId.toString());
    formData.append('senderId', currentUserId.toString());
    formData.append('message', message);
    formData.append('attachment', attachment);

    if (message || attachment) {
      fetch(sendMessageRoute, {
        method: 'POST',
        body: formData,
      }).then(() => {
        this.setState({ messageSent: true });

        fetch(getLastMessageRoute, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            conversationId,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            fetch(sendMessageBroadcastRoute, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                accountId: currentUserId,
                organizationId,
                conversationId,
                messageId: res[0].messageId,
              }),
            });

            socket.emit('send-message', {
              organizationId,
              conversationId,
              reference,
              projectName,
              title,
              accountId: currentUserId,
              name: senderName,
              message,
            });

            scrollToBottom();

            attachment = '';

            this.setState({ message: '' });

            const messageInput = document.getElementById('message');

            if (messageInput) {
              messageInput.value = '';
            }
          });
      });
    }
  }

  render() {
    const {
      currentUserId,
      accountType,
      organizationId,
      conversation,
    } = this.props;

    const {
      reference,
      type,
      authorId,
      title,
      description,
      attachment,
      statusId,
      dateCreated,
    } = conversation;

    const {
      account,
      project,
      messages,
      isLoading,
      renderStatusLogPage,
      renderEditConversationPage,
    } = this.state;

    let { currentStatus } = this.state;

    if (!currentStatus) {
      currentStatus = statusId.toString();
    }

    const issueOpened = 'Issue opened: waiting for agent response';
    const issueRequiresClarification =
      'Issue requires clarification: waiting for client response';
    const issueUnderEvaluation =
      'Issue under evaluation: waiting for developer feedback';
    const issueConfirmed = 'Issue confirmed: waiting for developer patch';
    const issueResolved = 'Issue resolved: waiting for client confirmation';
    const issueClosed = 'Issue closed';
    const enhancementRequested =
      'Enhancement requested: waiting for developer confirmation';
    const enhancementUnderDevelopment =
      'Enhancement under development: waiting for developer patch';
    const enhancementCompleted = 'Enhancement completed';

    let authorName = '';

    if (account !== undefined) {
      for (let i = 0; i < account.length; i += 1) {
        authorName = account[i].name;
      }
    }

    let projectName = '';

    if (project !== undefined) {
      for (let i = 0; i < project.length; i += 1) {
        projectName = project[i].name;
      }
    }

    if (isLoading) {
      return <LoadingText />;
    }

    return (
      <div>
        {renderEditConversationPage ? (
          <EditConversationPage
            currentUserId={currentUserId}
            accountType={accountType}
            organizationId={organizationId}
            conversation={conversation}
          />
        ) : (
          <div>
            {renderStatusLogPage ? (
              <StatusLogPage
                currentUserId={currentUserId}
                accountType={accountType}
                organizationId={organizationId}
                conversation={conversation}
              />
            ) : (
              <div
                className="conversation-page fade-in"
                onMouseMove={this.scrollToBottom.bind(this)}
              >
                <div className="pt-3" />
                <div className="row">
                  <div className="col-10">
                    <div className="mt-3 border-bottom">
                      <div className="row">
                        <div className="col">
                          <div className="back-link-container">
                            <a
                              href="manage-conversations"
                              className="back-link text-secondary"
                            >
                              <i className="fas fa-arrow-left back-link-icon" />
                              &nbsp;Back
                            </a>
                          </div>
                          <h6 className="text-secondary">
                            {reference}
                            {accountType === 'Admin' ? (
                              <span>
                                &ensp;
                                {currentStatus === '1' ? (
                                  <span>
                                    <i className="red-500 fa fa-square" />
                                  </span>
                                ) : (
                                  <span />
                                )}
                                {currentStatus === '2' ? (
                                  <span>
                                    <i className="orange-500 fa fa-square" />
                                  </span>
                                ) : (
                                  <span />
                                )}
                                {currentStatus === '3' ? (
                                  <span>
                                    <i className="yellow-500 fa fa-square" />
                                  </span>
                                ) : (
                                  <span />
                                )}
                                {currentStatus === '4' ? (
                                  <span>
                                    <i className="green-500 fa fa-square" />
                                  </span>
                                ) : (
                                  <span />
                                )}
                                {currentStatus === '5' ? (
                                  <span>
                                    <i className="blue-500 fa fa-square" />
                                  </span>
                                ) : (
                                  <span />
                                )}
                                {currentStatus === '6' ? (
                                  <span>
                                    <i className="gray-500 fa fa-square" />
                                  </span>
                                ) : (
                                  <span />
                                )}
                                {currentStatus === '7' ? (
                                  <span>
                                    <i className="indigo-500 fa fa-square" />
                                  </span>
                                ) : (
                                  <span />
                                )}
                                {currentStatus === '8' ? (
                                  <span>
                                    <i className="purple-500 fa fa-square" />
                                  </span>
                                ) : (
                                  <span />
                                )}
                                {currentStatus === '9' ? (
                                  <span>
                                    <i className="brown-500 fa fa-square" />
                                  </span>
                                ) : (
                                  <span />
                                )}
                              </span>
                            ) : (
                              <div>
                                <div className="pt-2" />
                                {currentStatus === '1' ? (
                                  <span>
                                    <i className="red-500 fa fa-square" />
                                    &ensp;
                                    {issueOpened}
                                  </span>
                                ) : (
                                  <span />
                                )}
                                {currentStatus === '2' ? (
                                  <span>
                                    <i className="orange-500 fa fa-square" />
                                    &ensp;
                                    {issueRequiresClarification}
                                  </span>
                                ) : (
                                  <span />
                                )}
                                {currentStatus === '3' ? (
                                  <span>
                                    <i className="yellow-500 fa fa-square" />
                                    &ensp;
                                    {issueUnderEvaluation}
                                  </span>
                                ) : (
                                  <span />
                                )}
                                {currentStatus === '4' ? (
                                  <span>
                                    <i className="green-500 fa fa-square" />
                                    &ensp;
                                    {issueConfirmed}
                                  </span>
                                ) : (
                                  <span />
                                )}
                                {currentStatus === '5' ? (
                                  <span>
                                    <i className="blue-500 fa fa-square" />
                                    &ensp;
                                    {issueResolved}
                                  </span>
                                ) : (
                                  <span />
                                )}
                                {currentStatus === '6' ? (
                                  <span>
                                    <i className="gray-500 fa fa-square" />
                                    &ensp;
                                    {issueClosed}
                                  </span>
                                ) : (
                                  <span />
                                )}
                                {currentStatus === '7' ? (
                                  <span>
                                    <i className="indigo-500 fa fa-square" />
                                    &ensp;
                                    {enhancementRequested}
                                  </span>
                                ) : (
                                  <span />
                                )}
                                {currentStatus === '8' ? (
                                  <span>
                                    <i className="purple-500 fa fa-square" />
                                    &ensp;
                                    {enhancementUnderDevelopment}
                                  </span>
                                ) : (
                                  <span />
                                )}
                                {currentStatus === '9' ? (
                                  <span>
                                    <i className="brown-500 fa fa-square" />
                                    &ensp;
                                    {enhancementCompleted}
                                  </span>
                                ) : (
                                  <span />
                                )}
                              </div>
                            )}
                          </h6>
                        </div>
                        <div>
                          {accountType === 'Admin' ? (
                            <button
                              type="button"
                              className="btn btn-info"
                              onClick={() =>
                                this.setState({ renderStatusLogPage: true })
                              }
                            >
                              Status Log
                            </button>
                          ) : (
                            <div />
                          )}
                        </div>
                        {accountType === 'Admin' ? (
                          <div className="col">
                            <span className="float-right text-secondary">
                              <div className="form-group">
                                <select
                                  className="form-control"
                                  name="status"
                                  defaultValue={statusId}
                                  onChange={this.changeStatus.bind(this)}
                                >
                                  <option value={1}>{issueOpened}</option>
                                  <option value={2}>
                                    {issueRequiresClarification}
                                  </option>
                                  <option value={3}>
                                    {issueUnderEvaluation}
                                  </option>
                                  <option value={4}>{issueConfirmed}</option>
                                  <option value={5}>{issueResolved}</option>
                                  <option value={6}>{issueClosed}</option>
                                  <option value={7}>
                                    {enhancementRequested}
                                  </option>
                                  <option value={8}>
                                    {enhancementUnderDevelopment}
                                  </option>
                                  <option value={9}>
                                    {enhancementCompleted}
                                  </option>
                                </select>
                              </div>
                            </span>
                          </div>
                        ) : (
                          <div />
                        )}
                      </div>
                      <h5>
                        <b>{title}</b>
                      </h5>
                    </div>
                    <div
                      id="message-display"
                      className="message-display mb-5 border"
                    >
                      <div className="p-3 bg-light border">
                        <span>
                          {authorId.toString() === currentUserId.toString() ? (
                            <b className="text-info">{authorName}</b>
                          ) : (
                            <b className="text-secondary">{authorName}</b>
                          )}
                          <small className="my-1 text-secondary">
                            &ensp;
                            {this.convertToLocalDate(dateCreated)}
                            &ensp;
                            {this.convertToLocalTime(dateCreated)}
                          </small>
                        </span>
                        <div className="mt-2">{description}</div>
                        <div className="mt-2">
                          {this.displayAttachment(attachment)}
                        </div>
                      </div>
                      {messages.map((message) => (
                        <div key={message.messageId} className="border">
                          <MessageDisplay
                            currentUserId={currentUserId}
                            authorId={authorId}
                            senderId={message.senderId}
                            name={message.name}
                            message={message.message}
                            attachment={message.attachment}
                            timestamp={message.timestamp}
                          />
                        </div>
                      ))}
                    </div>
                    <form onSubmit={this.sendMessage.bind(this)}>
                      <span className="mb-1 btn btn-sm">
                        <i className="fa fa-paperclip" />
                      </span>
                      <input
                        type="file"
                        id="attachment"
                        className="mb-3"
                        name="attachment"
                      />
                      <div className="input-group">
                        <div className="message-input-bar input-group">
                          <textarea
                            rows={4}
                            id="message"
                            className="form-control"
                            name="message"
                            placeholder="Write a message..."
                            onChange={this.handleChange.bind(this)}
                          />
                          <div className="input-group-append">
                            <button type="submit" className="btn btn-primary">
                              <i className="fa fa-reply" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-2">
                    <small>
                      <div className="m-3">
                        <div className="py-2 border-bottom">
                          <div className="text-secondary">Project</div>
                          <div>{projectName}</div>
                        </div>
                        <div className="py-2 border-bottom">
                          <div className="text-secondary">Type</div>
                          <div>{type}</div>
                        </div>
                        <div className="py-2 border-bottom">
                          <div className="text-secondary">Author</div>
                          <div>{authorName}</div>
                        </div>
                        <div className="py-2 border-bottom">
                          <div className="text-secondary">Date Created</div>
                          {this.convertToLocalDate(dateCreated)}
                          &ensp;
                          {this.convertToLocalTime(dateCreated)}
                        </div>
                      </div>
                    </small>
                    {accountType === 'Admin' ? (
                      <div className="d-flex btn-group">
                        <button
                          type="button"
                          className="w-100 btn btn-sm btn-success"
                          onClick={() =>
                            this.setState({ renderEditConversationPage: true })
                          }
                        >
                          Edit
                        </button>
                        <button
                          type="button"
                          className="w-100 btn btn-sm btn-danger"
                          name="delete"
                          onClick={this.handleClickDelete.bind(
                            this,
                            conversation.conversationId,
                          )}
                        >
                          Delete
                        </button>
                      </div>
                    ) : (
                      <div className="text-center">
                        <button
                          type="button"
                          className="btn btn-sm btn-success"
                          onClick={() =>
                            this.setState({ renderEditConversationPage: true })
                          }
                        >
                          Edit
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

ConversationPage.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  accountType: PropTypes.string.isRequired,
  organizationId: PropTypes.number.isRequired,
  conversation: PropTypes.shape({
    conversationId: PropTypes.number,
    organizationId: PropTypes.number,
    projectId: PropTypes.number,
    reference: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    authorId: PropTypes.number,
    description: PropTypes.string,
    attachment: PropTypes.string,
    statusId: PropTypes.number,
    dateCreated: PropTypes.string,
  }).isRequired,
};

export default ConversationPage;
