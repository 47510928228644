import React from 'react';
import PropTypes from 'prop-types';
import LoadingText from '../common/LoadingText';
import server from '../../server';

class TotalConversationsPerOrganizationText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      totalPerOrganizationArray: [],
      isLoading: false,
      getTotalConversationsPerOrganization: `${server}/help-desk/statistics/get-total-conversations-per-organization`,
    };
  }

  componentDidMount() {
    const { organizationId } = this.props;

    const { getTotalConversationsPerOrganization } = this.state;

    fetch(getTotalConversationsPerOrganization, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        organizationId,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ totalPerOrganizationArray: res }));
  }

  render() {
    const { totalPerOrganizationArray, isLoading } = this.state;

    let totalPerOrganization = 0;

    if (totalPerOrganizationArray[0]) {
      totalPerOrganization = totalPerOrganizationArray[0].totalConversations;
    }

    if (isLoading) {
      return <LoadingText />;
    }

    return <span>{totalPerOrganization}</span>;
  }
}

TotalConversationsPerOrganizationText.propTypes = {
  organizationId: PropTypes.number.isRequired,
};

export default TotalConversationsPerOrganizationText;
