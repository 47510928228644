import React from 'react';
import PropTypes from 'prop-types';
import TotalConversationsPerOrganizationText from './TotalConversationsPerOrganizationText';
import TotalConversationsPerProjectText from './TotalConversationsPerProjectText';
import LoadingText from '../common/LoadingText';
import server from '../../server';

class LifetimeConversationsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      organizations: [],
      totalOrganizations: [],
      projects: [],
      filterString: '',
      searchString: '',
      page: 1,
      isLoading: false,
      rerender: false,
      getOrganizationsRoute: `${server}/help-desk/organization/get-organizations`,
      getTotalOrganizationsRoute: `${server}/help-desk/organization/get-total-organizations`,
      getProjectsRoute: `${server}/help-desk/organization/get-projects`,
    };
  }

  componentDidMount() {
    const { currentUserId, accountType, organizationId } = this.props;

    const {
      filterString,
      searchString,
      page,
      getOrganizationsRoute,
      getTotalOrganizationsRoute,
      getProjectsRoute,
    } = this.state;

    this.setState({ isLoading: true });

    fetch(getOrganizationsRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        currentUserId,
        accountType,
        organizationId,
        filterString,
        searchString,
        page,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ organizations: res, isLoading: false }));

    fetch(getTotalOrganizationsRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        currentUserId,
        accountType,
        organizationId,
        filterString,
        searchString,
        page,
      }),
    })
      .then((res) => res.json())
      .then((res) =>
        this.setState({ totalOrganizations: res, isLoading: false }),
      );

    fetch(getProjectsRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((res) => this.setState({ projects: res, isLoading: false }));
  }

  componentDidUpdate() {
    const { currentUserId, accountType, organizationId } = this.props;

    const {
      filterString,
      searchString,
      page,
      rerender,
      getOrganizationsRoute,
      getTotalOrganizationsRoute,
    } = this.state;

    if (rerender) {
      fetch(getOrganizationsRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          currentUserId,
          accountType,
          organizationId,
          filterString,
          searchString,
          page,
        }),
      })
        .then((res) => res.json())
        .then((res) => this.setState({ organizations: res, rerender: false }));

      fetch(getTotalOrganizationsRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          currentUserId,
          accountType,
          organizationId,
          filterString,
          searchString,
          page,
        }),
      })
        .then((res) => res.json())
        .then((res) =>
          this.setState({ totalOrganizations: res, rerender: false }),
        );
    }
  }

  calculatePages(totalEntries) {
    return Math.ceil(totalEntries / 10);
  }

  render() {
    const {
      organizations,
      totalOrganizations,
      projects,
      page,
      isLoading,
    } = this.state;

    if (isLoading) {
      return <LoadingText />;
    }

    let totalEntries = 0;
    let totalPages = 1;

    if (totalOrganizations[0]) {
      totalEntries = parseInt(totalOrganizations[0].totalOrganizations, 10);
      totalPages = this.calculatePages(
        parseInt(totalOrganizations[0].totalOrganizations, 10),
      );
    }

    const isFirstPage = page - 1 === 0;
    const isLastPage = page === totalPages;

    const previousPage = [];
    const nextPage = [];

    if (totalPages < 3) {
      for (let i = page + 1; i < page + 2; i += 1) {
        nextPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }

      for (let i = page - 1; i < page; i += 1) {
        previousPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    } else if (page - 1 < 1) {
      for (let i = page + 1; i < page + 3; i += 1) {
        nextPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    } else if (page < totalPages) {
      for (let i = page - 1; i < page; i += 1) {
        previousPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }

      for (let i = page + 1; i < page + 2; i += 1) {
        nextPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    } else if (page === totalPages) {
      for (let i = page - 2; i < page; i += 1) {
        previousPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    }

    return (
      <div className="fade-in container-fluid p-5">
        <div className="pt-4" />
        <div className="row">
          <h5 className="text-secondary">Lifetime Conversations</h5>
        </div>
        <br />
        <div className="row justify-content-md-center">
          <div className="col-12">
            <table className="table">
              <tbody>
                {organizations.map((org) => (
                  <tr key={org.organizationId}>
                    <td className="fade-in">
                      <b>{org.name}</b>
                      {projects
                        .filter(
                          (proj) => proj.organizationId === org.organizationId,
                        )
                        .map((proj) => (
                          <div key={proj.projectId}>
                            <ul className="text-secondary">
                              <li>
                                <small>
                                  &#91;
                                  {proj.code}
                                  &#93;&nbsp;
                                  {proj.name}
                                </small>
                              </li>
                            </ul>
                          </div>
                        ))}
                    </td>
                    <td className="fade-in text-right">
                      <b>
                        <TotalConversationsPerOrganizationText
                          organizationId={org.organizationId}
                        />
                      </b>
                      {projects
                        .filter(
                          (proj) => proj.organizationId === org.organizationId,
                        )
                        .map((proj) => (
                          <div key={proj.projectId}>
                            <ul className="text-secondary">
                              <li>
                                <small>
                                  &nbsp;
                                  <TotalConversationsPerProjectText
                                    projectId={proj.projectId}
                                  />
                                </small>
                              </li>
                            </ul>
                          </div>
                        ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div>
          {totalEntries > 0 ? (
            <div>
              {isFirstPage && isLastPage ? (
                <div className="text-center">
                  <button
                    type="button"
                    className="btn border btn-light"
                    disabled
                  >
                    &lsaquo;
                  </button>
                  <button
                    type="button"
                    className="btn border btn-info"
                    disabled
                  >
                    {page}
                  </button>
                  <button
                    type="button"
                    className="btn border btn-light"
                    disabled
                  >
                    &rsaquo;
                  </button>
                </div>
              ) : (
                <div>
                  {isFirstPage ? (
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn border btn-light"
                        disabled
                      >
                        &lsaquo;
                      </button>
                      <button
                        type="button"
                        className="btn border btn-info"
                        disabled
                      >
                        {page}
                      </button>
                      {nextPage}
                      <button
                        type="button"
                        className="btn border btn-light"
                        onClick={() =>
                          this.setState({
                            page: page + 1,
                            rerender: true,
                          })
                        }
                      >
                        &rsaquo;
                      </button>
                    </div>
                  ) : (
                    <div>
                      {isLastPage ? (
                        <div className="text-center">
                          <button
                            type="button"
                            className="btn border btn-light"
                            onClick={() =>
                              this.setState({
                                page: page - 1,
                                rerender: true,
                              })
                            }
                          >
                            &lsaquo;
                          </button>
                          {previousPage}
                          <button
                            type="button"
                            className="btn border btn-info"
                            disabled
                          >
                            {page}
                          </button>
                          <button
                            type="button"
                            className="btn border btn-light"
                            disabled
                          >
                            &rsaquo;
                          </button>
                        </div>
                      ) : (
                        <div>
                          {!isFirstPage && !isLastPage ? (
                            <div className="text-center">
                              <button
                                type="button"
                                className="btn border btn-light"
                                onClick={() =>
                                  this.setState({
                                    page: page - 1,
                                    rerender: true,
                                  })
                                }
                              >
                                &lsaquo;
                              </button>
                              {previousPage}
                              <button
                                type="button"
                                className="btn border btn-info"
                                disabled
                              >
                                {page}
                              </button>
                              {nextPage}
                              <button
                                type="button"
                                className="btn border btn-light"
                                onClick={() =>
                                  this.setState({
                                    page: page + 1,
                                    rerender: true,
                                  })
                                }
                              >
                                &rsaquo;
                              </button>
                            </div>
                          ) : (
                            <div />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  }
}

LifetimeConversationsPage.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  accountType: PropTypes.string.isRequired,
  organizationId: PropTypes.number.isRequired,
};

export default LifetimeConversationsPage;
