import React from 'react';
import PropTypes from 'prop-types';
import LoadingText from '../common/LoadingText';
import server from '../../server';

class StatusText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      statusArray: [],
      isLoading: false,
      getStatusRoute: `${server}/help-desk/conversation/get-status`,
    };
  }

  componentDidMount() {
    const { statusId } = this.props;

    const { getStatusRoute } = this.state;

    fetch(getStatusRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        statusId,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ statusArray: res }));
  }

  render() {
    const { statusArray, isLoading } = this.state;

    let status = '';

    if (statusArray[0]) {
      status = statusArray[0].status;
    }

    if (isLoading) {
      return <LoadingText />;
    }

    switch (status) {
      case 'Issue opened: waiting for agent response':
        return (
          <span>
            <i className="red-500 fa fa-square" />
            &ensp;
            {status}
          </span>
        );
      case 'Issue requires clarification: waiting for client response':
        return (
          <span>
            <i className="orange-500 fa fa-square" />
            &ensp;
            {status}
          </span>
        );
      case 'Issue under evaluation: waiting for developer feedback':
        return (
          <span>
            <i className="yellow-500 fa fa-square" />
            &ensp;
            {status}
          </span>
        );
      case 'Issue confirmed: waiting for developer patch':
        return (
          <span>
            <i className="green-500 fa fa-square" />
            &ensp;
            {status}
          </span>
        );
      case 'Issue resolved: waiting for client confirmation':
        return (
          <span>
            <i className="blue-500 fa fa-square" />
            &ensp;
            {status}
          </span>
        );
      case 'Issue closed':
        return (
          <span>
            <i className="gray-500 fa fa-square" />
            &ensp;
            {status}
          </span>
        );
      case 'Enhancement requested: waiting for developer confirmation':
        return (
          <span>
            <i className="indigo-500 fa fa-square" />
            &ensp;
            {status}
          </span>
        );
      case 'Enhancement under development: waiting for developer patch':
        return (
          <span>
            <i className="purple-500 fa fa-square" />
            &ensp;
            {status}
          </span>
        );
      case 'Enhancement completed':
        return (
          <span>
            <i className="brown-500 fa fa-square" />
            &ensp;
            {status}
          </span>
        );
      default:
        return <span>{status}</span>;
    }
  }
}

StatusText.propTypes = {
  statusId: PropTypes.number.isRequired,
};

export default StatusText;
