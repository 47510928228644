import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import EditProfilePage from './EditProfilePage';
import LoadingText from '../common/LoadingText';
import server from '../../server';

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      account: [],
      isLoading: false,
      renderEditProfilePage: false,
      profileRoute: `${server}/help-desk/user/profile`,
    };
  }

  componentDidMount() {
    const { accountId } = this.props;

    const { profileRoute } = this.state;

    this.setState({ isLoading: true });

    fetch(profileRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ accountId }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ account: res, isLoading: false }));
  }

  handleClickEdit(account) {
    ReactDOM.render(
      <EditProfilePage account={account} />,
      document.getElementById('main'),
    );
  }

  render() {
    const { account, isLoading, renderEditProfilePage } = this.state;

    let accountId = '';
    let email = '';
    let name = '';
    let position = '';
    let mobile = '';
    let landline = '';

    if (isLoading) {
      return <LoadingText />;
    }

    for (let i = 0; i < account.length; i += 1) {
      accountId = account[i].accountId.toString();
      email = account[i].email;
      name = account[i].name;
      position = account[i].position;
      mobile = account[i].mobile;
      landline = account[i].landline;
    }

    return (
      <div>
        {renderEditProfilePage ? (
          <EditProfilePage account={account} />
        ) : (
          <div className="fade-in centered-form mx-auto">
            <div className="pt-3" />
            <h5 className="text-center text-secondary">Profile</h5>
            <br />
            <input type="hidden" name="accountId" defaultValue={accountId} />
            <div className="mb-3 border-bottom">
              <div className="mb-3 text-secondary">Email</div>
              <h6>{email}</h6>
            </div>
            <div className="mb-3 border-bottom">
              <div className="mb-3 text-secondary">Name</div>
              <h6>{name}</h6>
            </div>
            <div className="mb-3 border-bottom">
              <div className="mb-3 text-secondary">Position</div>
              <h6>{position}</h6>
            </div>
            <div className="mb-3 border-bottom">
              <div className="mb-3 text-secondary">Mobile</div>
              <h6>{mobile}</h6>
            </div>
            <div className="mb-3 border-bottom">
              <div className="mb-3 text-secondary">Landline</div>
              <h6>{landline}</h6>
            </div>
            <br />
            <div className="text-center">
              <button
                type="button"
                className="btn btn-success"
                name="edit"
                onClick={() =>
                  this.setState({
                    renderEditProfilePage: true,
                    account,
                  })
                }
              >
                Edit
              </button>
            </div>
          </div>
        )}
        )
      </div>
    );
  }
}

ProfilePage.propTypes = {
  accountId: PropTypes.number.isRequired,
};

export default ProfilePage;
