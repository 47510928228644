import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import server from '../../server';

class ChangePasswordPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accounts: [],
      oldPassword: '',
      newPassword: '',
      confirmedNewPassword: '',
      changePasswordRoute: `${server}/help-desk/user/change-password`,
    };
  }

  componentDidUpdate() {
    const { accounts } = this.state;

    function IncorrectPasswordNotification() {
      return (
        <div className="alert alert-danger">
          <span>Incorrect password.</span>
        </div>
      );
    }

    if (accounts[0] && accounts[0].response === 'incorrect-password') {
      ReactDOM.render(
        <IncorrectPasswordNotification />,
        document.getElementById('notification'),
      );

      accounts[0] = {
        accountId: 0,
        type: '',
        organizationId: 0,
        email: '',
        password: '',
        name: '',
        position: '',
        mobile: '',
        landline: '',
        token: '',
        response: '',
      };
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { email } = this.props;

    const {
      oldPassword,
      newPassword,
      confirmedNewPassword,
      changePasswordRoute,
    } = this.state;

    function EmptyFieldsNotification() {
      return (
        <div className="alert alert-warning">
          <span>Enter the required data.</span>
        </div>
      );
    }

    function PasswordsUnmatchedNotification() {
      return (
        <div className="alert alert-warning">
          <span>Passwords do not match.</span>
        </div>
      );
    }

    function PasswordChangedNotification() {
      return (
        <div className="alert alert-success">
          <span>Password changed.</span>
        </div>
      );
    }

    if (
      oldPassword === '' ||
      newPassword === '' ||
      confirmedNewPassword === ''
    ) {
      ReactDOM.render(
        <EmptyFieldsNotification />,
        document.getElementById('notification'),
      );
    } else if (newPassword !== confirmedNewPassword) {
      ReactDOM.render(
        <PasswordsUnmatchedNotification />,
        document.getElementById('notification'),
      );
    } else {
      fetch(changePasswordRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
          oldPassword: event.target.oldPassword.value,
          newPassword: event.target.newPassword.value,
        }),
      })
        .then((res) => res.json())
        .then((res) => this.setState({ accounts: res }));

      ReactDOM.render(
        <PasswordChangedNotification />,
        document.getElementById('notification'),
      );

      const oldPasswordInput = document.getElementById('old-password');

      if (oldPasswordInput) {
        oldPasswordInput.value = '';
      }

      const newPasswordInput = document.getElementById('new-password');

      if (newPasswordInput) {
        newPasswordInput.value = '';
      }

      const confirmedNewPasswordInput = document.getElementById(
        'confirmed-new-password',
      );

      if (confirmedNewPasswordInput) {
        confirmedNewPasswordInput.value = '';
      }

      setTimeout(() => {
        window.location.href = '/profile';
      }, 1000);
    }
  }

  render() {
    return (
      <div className="fade-in centered-form mx-auto">
        <div className="pt-3" />
        <form onSubmit={this.handleSubmit.bind(this)}>
          <h5 className="text-center text-secondary">Change Password</h5>
          <br />
          <div className="form-group">
            <label htmlFor="old-password" className="col-12 text-secondary">
              Old Password
              <input
                type="password"
                id="old-password"
                className="form-control"
                name="oldPassword"
                onChange={this.handleChange.bind(this)}
                placeholder="Enter old password"
              />
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="new-password" className="col-12 text-secondary">
              New Password
              <input
                type="password"
                id="new-password"
                className="form-control"
                name="newPassword"
                onChange={this.handleChange.bind(this)}
                placeholder="Enter new password"
              />
            </label>
          </div>
          <div className="form-group">
            <label
              htmlFor="confirmed-new-password"
              className="col-12 text-secondary"
            >
              Confirm New Password
              <input
                type="password"
                id="confirmed-new-password"
                className="form-control"
                name="confirmedNewPassword"
                onChange={this.handleChange.bind(this)}
                placeholder="Confirm new password"
              />
            </label>
          </div>
          <br />
          <div className="text-center">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

ChangePasswordPage.propTypes = {
  email: PropTypes.string.isRequired,
};

export default ChangePasswordPage;
