import React from 'react';
import PropTypes from 'prop-types';
import LoadingText from '../common/LoadingText';
import server from '../../server';

class TotalConversationsPerProjectText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      totalPerProjectArray: [],
      isLoading: false,
      getTotalConversationsPerProject: `${server}/help-desk/statistics/get-total-conversations-per-project`,
    };
  }

  componentDidMount() {
    const { projectId } = this.props;

    const { getTotalConversationsPerProject } = this.state;

    fetch(getTotalConversationsPerProject, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        projectId,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ totalPerProjectArray: res }));
  }

  render() {
    const { totalPerProjectArray, isLoading } = this.state;

    let totalPerProject = 0;

    if (totalPerProjectArray[0]) {
      totalPerProject = totalPerProjectArray[0].totalConversations;
    }

    if (isLoading) {
      return <LoadingText />;
    }

    return <span>{totalPerProject}</span>;
  }
}

TotalConversationsPerProjectText.propTypes = {
  projectId: PropTypes.number.isRequired,
};

export default TotalConversationsPerProjectText;
