import React from 'react';
import ReactDOM from 'react-dom';
import server from '../../server';

class CreateAccountPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      organizations: [],
      email: '',
      password: '',
      confirmedPassword: '',
      name: '',
      position: '',
      mobile: '',
      landline: '',
      getOrganizationOptionsRoute: `${server}/help-desk/account/get-organization-options`,
      createAccountRoute: `${server}/help-desk/account/create-account`,
    };
  }

  componentDidMount() {
    const { getOrganizationOptionsRoute } = this.state;

    fetch(getOrganizationOptionsRoute, { method: 'POST' })
      .then((res) => res.json())
      .then((res) => this.setState({ organizations: res }));
  }

  validateEmail = (enteredEmail) => {
    const regex = /\S+@\S+\.\S+/;

    return regex.test(enteredEmail);
  };

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleClick() {
    const { password, confirmedPassword } = this.state;

    function PasswordsUnmatched() {
      return (
        <div className="text-center">
          <span id="passwordValidation" className="text-danger">
            Passwords do not match.
          </span>
        </div>
      );
    }

    function PasswordsMatched() {
      return <span id="passwordValidation" />;
    }

    if (password !== confirmedPassword && confirmedPassword !== '') {
      ReactDOM.render(
        <PasswordsUnmatched />,
        document.getElementById('passwordValidation'),
      );
    } else {
      ReactDOM.render(
        <PasswordsMatched />,
        document.getElementById('passwordValidation'),
      );
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    const {
      email,
      password,
      confirmedPassword,
      name,
      position,
      mobile,
      landline,
      createAccountRoute,
    } = this.state;

    function EmptyFieldsNotification() {
      return (
        <div className="alert alert-warning">
          <span>Enter the required data.</span>
        </div>
      );
    }

    function InvalidEmailNotification() {
      return (
        <div className="alert alert-warning">
          <span>Invalid email.</span>
        </div>
      );
    }

    function PasswordsUnmatchedNotification() {
      return (
        <div className="alert alert-warning">
          <span>Passwords do not match.</span>
        </div>
      );
    }

    function AccountCreatedNotification() {
      return (
        <div className="alert alert-success">
          <span>Account created.</span>
        </div>
      );
    }

    if (
      email === '' ||
      password === '' ||
      confirmedPassword === '' ||
      name === '' ||
      position === '' ||
      mobile === '' ||
      landline === ''
    ) {
      ReactDOM.render(
        <EmptyFieldsNotification />,
        document.getElementById('notification'),
      );
    } else if (!this.validateEmail(email)) {
      ReactDOM.render(
        <InvalidEmailNotification />,
        document.getElementById('notification'),
      );
    } else if (password !== confirmedPassword) {
      ReactDOM.render(
        <PasswordsUnmatchedNotification />,
        document.getElementById('notification'),
      );
    } else {
      fetch(createAccountRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          type: event.target.type.value,
          organizationId: event.target.organizationId.value,
          email: event.target.email.value,
          name: event.target.name.value,
          password: event.target.password.value,
          position: event.target.position.value,
          mobile: event.target.mobile.value,
          landline: event.target.landline.value,
        }),
      });

      ReactDOM.render(
        <AccountCreatedNotification />,
        document.getElementById('notification'),
      );

      setTimeout(() => {
        window.location.href = '/manage-accounts';
      }, 1000);
    }
  }

  render() {
    const { organizations } = this.state;

    return (
      <div className="fade-in centered-form mx-auto">
        <div className="pt-3 pl-3">
          <a href="manage-accounts" className="back-link text-secondary">
            <i className="fas fa-arrow-left back-link-icon" />
            &nbsp;Back
          </a>
        </div>
        <form onSubmit={this.handleSubmit.bind(this)}>
          <h5 className="text-center text-secondary">Create Account</h5>
          <br />
          <div className="form-group">
            <label htmlFor="type" className="col-12 text-secondary">
              Type
              <select
                id="type"
                className="form-control"
                name="type"
                onChange={this.handleChange.bind(this)}
                onClick={this.handleClick.bind(this)}
              >
                <option value="User">User</option>
                <option value="Admin">Admin</option>
              </select>
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="organizationId" className="col-12 text-secondary">
              Organization
              <select
                id="organization-id"
                className="form-control"
                name="organizationId"
                onChange={this.handleChange.bind(this)}
                onClick={this.handleClick.bind(this)}
              >
                {organizations.map((organization) => (
                  <option
                    key={organization.organizationId}
                    value={organization.organizationId}
                  >
                    {organization.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="email" className="col-12 text-secondary">
              Email
              <input
                type="text"
                id="email"
                className="form-control"
                name="email"
                onChange={this.handleChange.bind(this)}
                onClick={this.handleClick.bind(this)}
                placeholder="Enter email"
              />
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="password" className="col-12 text-secondary">
              Password
              <input
                type="password"
                id="password"
                className="form-control"
                name="password"
                onChange={this.handleChange.bind(this)}
                onClick={this.handleClick.bind(this)}
                placeholder="Enter password"
              />
            </label>
          </div>
          <div className="form-group">
            <label
              htmlFor="confirmed-password"
              className="col-12 text-secondary"
            >
              Confirm Password
              <input
                type="password"
                id="confirmed-password"
                className="form-control"
                name="confirmedPassword"
                onChange={this.handleChange.bind(this)}
                onClick={this.handleClick.bind(this)}
                placeholder="Confirm password"
              />
            </label>
            <span id="passwordValidation" />
          </div>
          <div className="form-group">
            <label htmlFor="name" className="col-12 text-secondary">
              Name
              <input
                type="text"
                id="name"
                className="form-control"
                name="name"
                onChange={this.handleChange.bind(this)}
                onClick={this.handleClick.bind(this)}
                placeholder="Enter name"
              />
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="position" className="col-12 text-secondary">
              Position
              <input
                type="text"
                id="position"
                className="form-control"
                name="position"
                onChange={this.handleChange.bind(this)}
                onClick={this.handleClick.bind(this)}
                placeholder="Enter position"
              />
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="mobile" className="col-12 text-secondary">
              Mobile
              <input
                type="text"
                id="mobile"
                className="form-control"
                name="mobile"
                onChange={this.handleChange.bind(this)}
                onClick={this.handleClick.bind(this)}
                placeholder="Enter mobile"
              />
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="landline" className="col-12 text-secondary">
              Landline
              <input
                type="text"
                id="landline"
                className="form-control"
                name="landline"
                onChange={this.handleChange.bind(this)}
                onClick={this.handleClick.bind(this)}
                placeholder="Enter landline"
              />
            </label>
          </div>
          <br />
          <div className="text-center">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default CreateAccountPage;
