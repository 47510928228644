import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import server from '../../server';

class EditConversationPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      projects: [],
      getProjectOptionsRoute: `${server}/help-desk/conversation/get-project-options`,
      editConversationRoute: `${server}/help-desk/conversation/edit-conversation`,
    };
  }

  componentDidMount() {
    const { currentUserId, accountType, organizationId } = this.props;

    const { getProjectOptionsRoute } = this.state;

    fetch(getProjectOptionsRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        currentUserId,
        accountType,
        organizationId,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ projects: res }));
  }

  getProjectName(projectId) {
    const { projects } = this.state;

    let projectName = '';

    projects.forEach((project) => {
      if (project.projectId === projectId) {
        projectName = project.name;
      }
    });

    return projectName;
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { editConversationRoute } = this.state;

    const conversationId = event.target.conversationId.value;
    const organizationId = event.target.organizationId.value;
    const projectId = event.target.projectId.value;
    const reference = event.target.reference.value;
    const type = event.target.type.value;
    const authorId = event.target.authorId.value;
    const title = event.target.title.value;
    const description = event.target.description.value;

    function EmptyFieldsNotification() {
      return (
        <div className="alert alert-warning">
          <span>Enter the required data.</span>
        </div>
      );
    }

    function ConversationEditedNotification() {
      return (
        <div className="alert alert-success">
          <span>Conversation edited.</span>
        </div>
      );
    }

    if (projectId === 0 || title === '' || description === '') {
      ReactDOM.render(
        <EmptyFieldsNotification />,
        document.getElementById('notification'),
      );
    } else {
      fetch(editConversationRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          organizationId,
          conversationId,
          projectId,
          reference,
          type,
          authorId,
          title,
          description,
        }),
      });

      ReactDOM.render(
        <ConversationEditedNotification />,
        document.getElementById('notification'),
      );

      setTimeout(() => {
        window.location.href = '/manage-conversations';
      }, 1000);
    }
  }

  render() {
    const { conversation, accountType } = this.props;

    const {
      conversationId = conversation.conversationId,
      organizationId = conversation.organizationId,
      projectId = conversation.projectId,
      reference = conversation.reference,
      type = conversation.type,
      title = conversation.title,
      authorId = conversation.authorId,
      description = conversation.description,
    } = conversation;

    const { projects } = this.state;

    // const [currentUserOrganization] = organizations.filter(
    //   org => org.organizationId === organizationId,
    // );

    // let currentUserOrganizationId = '';

    // if (currentUserOrganization) {
    //   currentUserOrganizationId = currentUserOrganization.organizationId;
    // }

    // const currentUserProjects = projects.filter(
    //   proj => proj.organizationId === currentUserOrganizationId,
    // );

    return (
      <div className="fade-in centered-form mx-auto">
        {accountType === 'Admin' ? (
          <div>
            <div className="pt-3 pl-3">
              <a
                href="manage-conversations"
                className="back-link text-secondary"
              >
                <i className="fas fa-arrow-left back-link-icon" />
                &nbsp;Back
              </a>
            </div>
            <form onSubmit={this.handleSubmit.bind(this)}>
              <h5 className="text-center text-secondary">Edit Conversation</h5>
              <br />
              <input
                type="hidden"
                name="conversationId"
                defaultValue={conversationId}
              />
              <input
                type="hidden"
                name="organizationId"
                defaultValue={organizationId}
              />
              <input type="hidden" name="authorId" defaultValue={authorId} />
              <div className="form-group">
                <label htmlFor="project-id" className="col-12 text-secondary">
                  Project
                  <select
                    id="project-id"
                    className="form-control"
                    name="projectId"
                    defaultValue={projectId}
                    onChange={this.handleChange.bind(this)}
                  >
                    {projects
                      .filter((project) => project.projectId === projectId)
                      .map((project) => (
                        <option
                          key={project.projectId}
                          value={project.projectId}
                        >
                          {project.name}
                        </option>
                      ))}
                    {projects
                      .filter((project) => project.projectId !== projectId)
                      .map((project) => (
                        <option
                          key={project.projectId}
                          value={project.projectId}
                        >
                          {project.name}
                        </option>
                      ))}
                  </select>
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="reference" className="col-12 text-secondary">
                  Reference
                  <input
                    type="text"
                    id="reference"
                    className="form-control"
                    name="tireferencetle"
                    defaultValue={reference}
                    onChange={this.handleChange.bind(this)}
                    placeholder="Enter reference"
                  />
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="type" className="col-12 text-secondary">
                  Type
                  <select
                    id="type"
                    className="form-control"
                    name="type"
                    defaultValue={type}
                    onChange={this.handleChange.bind(this)}
                  >
                    <option value="Bug">Bug</option>
                    <option value="Enhancement Request">
                      Enhancement Request
                    </option>
                    <option value="Question">Question</option>
                    <option value="Task">Task</option>
                  </select>
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="title" className="col-12 text-secondary">
                  Title
                  <input
                    type="text"
                    id="title"
                    className="form-control"
                    name="title"
                    defaultValue={title}
                    onChange={this.handleChange.bind(this)}
                    placeholder="Enter title"
                  />
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="description" className="col-12 text-secondary">
                  Description
                  <textarea
                    rows={4}
                    id="description"
                    className="form-control"
                    name="description"
                    defaultValue={description}
                    onChange={this.handleChange.bind(this)}
                    placeholder="Enter description"
                  />
                </label>
              </div>
              <br />
              <div className="text-center">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div>
            <div className="pt-3" />
            <form onSubmit={this.handleSubmit.bind(this)}>
              <h5 className="text-center text-secondary">Edit Conversation</h5>
              <br />
              <input
                type="hidden"
                name="conversationId"
                defaultValue={conversationId}
              />
              <input
                type="hidden"
                name="organizationId"
                defaultValue={organizationId}
              />
              <input type="hidden" name="reference" defaultValue={reference} />
              <input type="hidden" name="authorId" defaultValue={authorId} />
              <div className="form-group">
                <label htmlFor="project-id" className="col-12 text-secondary">
                  Project
                  <select
                    id="project-id"
                    className="form-control"
                    name="projectId"
                    defaultValue={projectId}
                    onChange={this.handleChange.bind(this)}
                  >
                    {projects
                      .filter((project) => project.projectId === projectId)
                      .map((project) => (
                        <option
                          key={project.projectId}
                          value={project.projectId}
                        >
                          {project.name}
                        </option>
                      ))}
                    {projects
                      .filter((project) => project.projectId !== projectId)
                      .map((project) => (
                        <option
                          key={project.projectId}
                          value={project.projectId}
                        >
                          {project.name}
                        </option>
                      ))}
                  </select>
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="type" className="col-12 text-secondary">
                  Type
                  <select
                    id="type"
                    className="form-control"
                    name="type"
                    defaultValue={type}
                    onChange={this.handleChange.bind(this)}
                  >
                    <option value="Bug">Bug</option>
                    <option value="Enhancement Request">
                      Enhancement Request
                    </option>
                    <option value="Question">Question</option>
                    <option value="Task">Task</option>
                  </select>
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="title" className="col-12 text-secondary">
                  Title
                  <input
                    type="text"
                    id="title"
                    className="form-control"
                    name="title"
                    defaultValue={title}
                    onChange={this.handleChange.bind(this)}
                    placeholder="Enter title"
                  />
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="description" className="col-12 text-secondary">
                  Description
                  <textarea
                    rows={4}
                    id="description"
                    className="form-control"
                    name="description"
                    defaultValue={description}
                    onChange={this.handleChange.bind(this)}
                    placeholder="Enter description"
                  />
                </label>
              </div>
              <br />
              <div className="text-center">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

EditConversationPage.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  accountType: PropTypes.string.isRequired,
  organizationId: PropTypes.number.isRequired,
  conversation: PropTypes.shape({
    conversationId: PropTypes.number,
    organizationId: PropTypes.number,
    projectId: PropTypes.number,
    reference: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    authorId: PropTypes.number,
    description: PropTypes.string,
    attachment: PropTypes.string,
    statusId: PropTypes.number,
    dateCreated: PropTypes.string,
  }).isRequired,
};

export default EditConversationPage;
