import React from 'react';
import PropTypes from 'prop-types';
import './NotificationDropdownButton.css';
import AccountNameText from './AccountNameText';
import MessageText from './MessageText';
import ReferenceText from './ReferenceText';
import StatusText from './StatusText';
import TitleText from './TitleText';
import server from '../../server';
import socket from '../../socket';

class NotificationDropdownButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
      account: [],
      rerender: false,
      newNotification: false,
      getLatestNotificationsRoute: `${server}/help-desk/notification/get-latest-notifications`,
      getAccountRoute: `${server}/help-desk/account/get-account`,
      getConversationRoute: `${server}/help-desk/conversation/get-conversation`,
    };
  }

  componentDidMount() {
    const { accountId, organizationId } = this.props;

    const { getLatestNotificationsRoute, getAccountRoute } = this.state;

    fetch(getLatestNotificationsRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        accountId,
        organizationId,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ notifications: res }));

    fetch(getAccountRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        accountId,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ account: res }));

    socket.on('start-conversation', () => {
      this.setState({ rerender: true, newNotification: true });
    });

    socket.on('send-message', () => {
      this.setState({ rerender: true, newNotification: true });
    });

    socket.on('change-status', () => {
      this.setState({ rerender: true, newNotification: true });
    });
  }

  componentDidUpdate() {
    const { accountId, organizationId } = this.props;

    const { rerender, getLatestNotificationsRoute } = this.state;

    if (rerender) {
      fetch(getLatestNotificationsRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          accountId,
          organizationId,
        }),
      })
        .then((res) => res.json())
        .then((res) =>
          this.setState({
            notifications: res,
            rerender: false,
            newNotification: true,
          }),
        );
    }
  }

  getConversation(conversationId) {
    const { setConversationState, setRenderConversationState } = this.props;

    const { account, getConversationRoute } = this.state;

    if (account[0]) {
      fetch(getConversationRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          conversationId,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setConversationState(res);
          setRenderConversationState(true);
        });
    }
  }

  convertToLocalDate(date) {
    const localDate = new Date(date).toLocaleDateString('en-GB', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

    return localDate;
  }

  convertToLocalTime(time) {
    const localTime = new Date(time).toLocaleTimeString('en-GB');

    return localTime;
  }

  render() {
    const { notifications, newNotification } = this.state;

    return (
      <div className="dropdown">
        <button
          type="button"
          className="control-panel-btn btn btn-dark text-light"
          data-toggle="dropdown"
        >
          {newNotification ? (
            <div>
              <i className="fa fa-bell fa-lg" />
              <small>
                <i className="new-notification-indicator fa fa-circle fa-sm text-danger" />
              </small>
            </div>
          ) : (
            <div>
              <i className="fa fa-bell fa-lg" />
            </div>
          )}
        </button>
        <div className="dropdown-menu dropdown-menu-right bg-dark">
          <div className="dropdown-item bg-dark text-light">Notifications</div>
          {notifications.slice(0, 5).map((notification) => (
            <div key={notification.notificationId}>
              {notification.type === 'start-conversation' ? (
                <div
                  className="clickable-notification dropdown-item border border-dark bg-secondary text-light"
                  onClick={() => {
                    this.getConversation(notification.conversationId);
                  }}
                >
                  <b className="text-warning">
                    <AccountNameText accountId={notification.accountId} />
                  </b>
                  &nbsp;started a conversation in&nbsp;
                  <b className="text-warning">
                    <ReferenceText
                      conversationId={notification.conversationId}
                    />
                  </b>
                  :
                  <br />
                  <div className="truncated-notification">
                    <b>
                      <TitleText conversationId={notification.conversationId} />
                    </b>
                  </div>
                  <small className="text-dark">
                    {this.convertToLocalDate(notification.timestamp)}
                    &ensp;
                    {this.convertToLocalTime(notification.timestamp)}
                  </small>
                </div>
              ) : (
                <div />
              )}
              {notification.type === 'send-message' ? (
                <div
                  className="clickable-notification dropdown-item border border-dark bg-secondary text-light"
                  onClick={() => {
                    this.getConversation(notification.conversationId);
                  }}
                >
                  <b className="text-warning">
                    <AccountNameText accountId={notification.accountId} />
                  </b>
                  &nbsp;sent a message in&nbsp;
                  <b className="text-warning">
                    <ReferenceText
                      conversationId={notification.conversationId}
                    />
                  </b>
                  :
                  <br />
                  <div className="truncated-notification">
                    <i>
                      <MessageText messageId={notification.messageId} />
                    </i>
                  </div>
                  <small className="text-dark">
                    {this.convertToLocalDate(notification.timestamp)}
                    &ensp;
                    {this.convertToLocalTime(notification.timestamp)}
                  </small>
                </div>
              ) : (
                <div />
              )}
              {notification.type === 'change-status' ? (
                <div
                  className="clickable-notification dropdown-item border border-dark bg-secondary text-light"
                  onClick={() =>
                    this.getConversation(notification.conversationId)
                  }
                >
                  <b className="text-warning">
                    <AccountNameText accountId={notification.accountId} />
                  </b>
                  &nbsp;changed the status of&nbsp;
                  <b className="text-warning">
                    <ReferenceText
                      conversationId={notification.conversationId}
                    />
                  </b>
                  :
                  <div>
                    <StatusText statusId={notification.statusId} />
                  </div>
                  <small className="text-dark">
                    {this.convertToLocalDate(notification.timestamp)}
                    &ensp;
                    {this.convertToLocalTime(notification.timestamp)}
                  </small>
                </div>
              ) : (
                <div />
              )}
              {/* {notification.type === 'login' ? (
              <div
                className="clickable-notification dropdown-item border
                  border-dark bg-secondary text-light"
              >
                <span className="text-light">
                  <b>
                    <AccountName accountId={notification.accountId} />
                  </b>
                </span>
                &nbsp;logged in.
                <br />
                <small className="text-dark">
                  {this.convertToLocalDate(notification.timestamp)}
                  &ensp;
                  {this.convertToLocalTime(notification.timestamp)}
                </small>
              </div>
            ) : (
              <div />
            )}
            {notification.type === 'logout' ? (
              <div
                className="clickable-notification dropdown-item border
                  border-dark bg-secondary text-light"
              >
                <span className="text-light">
                  <b>
                    <AccountName accountId={notification.accountId} />
                  </b>
                </span>
                &nbsp;logged out.
                <br />
                <small className="text-dark">
                  {this.convertToLocalDate(notification.timestamp)}
                  &ensp;
                  {this.convertToLocalTime(notification.timestamp)}
                </small>
              </div>
            ) : (
              <div />
            )} */}
            </div>
          ))}
          <a
            href="/notifications"
            className="dropdown-item btn btn-dark text-light"
          >
            <div className="text-center">See All</div>
          </a>
        </div>
      </div>
    );
  }
}

NotificationDropdownButton.propTypes = {
  accountId: PropTypes.number.isRequired,
  organizationId: PropTypes.number.isRequired,
  setConversationState: PropTypes.func.isRequired,
  setRenderConversationState: PropTypes.func.isRequired,
};

export default NotificationDropdownButton;
