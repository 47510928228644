import React from 'react';
import PropTypes from 'prop-types';
import './LastMessageDisplay.css';
import LoadingText from '../common/LoadingText';
import server from '../../server';

class LastMessageDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lastMessage: [],
      conversation: [],
      isLoading: false,
      getLastMessageRoute: `${server}/help-desk/conversation/get-last-message`,
      getConversationRoute: `${server}/help-desk/conversation/get-conversation`,
      downloadAttachmentRoute: `${server}/help-desk/conversation/download-attachment`,
    };
  }

  componentDidMount() {
    const { conversationId } = this.props;

    const { getLastMessageRoute, getConversationRoute } = this.state;

    this.setState({ isLoading: true });

    fetch(getLastMessageRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        conversationId,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ lastMessage: res, isLoading: false }));

    fetch(getConversationRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        conversationId,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ conversation: res, isLoading: false }));
  }

  convertToLocalDate(date) {
    const localDate = new Date(date).toLocaleDateString('en-GB', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

    return localDate;
  }

  convertToLocalTime(time) {
    const localTime = new Date(time).toLocaleTimeString('en-GB');

    return localTime;
  }

  displayAttachment(attachment) {
    const { lastMessage } = this.state;

    let senderId = 0;

    if (lastMessage[0]) {
      senderId = lastMessage[0].senderId;
    }

    const { downloadAttachmentRoute } = this.state;

    if (attachment) {
      return (
        <form method="POST" action={downloadAttachmentRoute}>
          <input type="hidden" name="senderId" defaultValue={senderId} />
          <input type="hidden" name="attachment" defaultValue={attachment} />
          <i className="fa fa-paperclip" />
          &ensp;
          <div className="btn">{attachment.substring(20)}</div>
        </form>
      );
    }

    return <div />;
  }

  render() {
    const { currentUserId } = this.props;

    const { lastMessage, conversation, isLoading } = this.state;

    if (isLoading) {
      return (
        <div className="m-5">
          <LoadingText />
        </div>
      );
    }

    let isSentByCurrentUser = false;

    if (lastMessage[0]) {
      const { senderId, name, message, attachment, timestamp } = lastMessage[0];

      if (senderId.toString() === currentUserId.toString()) {
        isSentByCurrentUser = true;
      }

      return isSentByCurrentUser ? (
        <div className="last-message-display">
          <span>
            <b className="text-info">{name}</b>
            <small className="text-secondary">
              &ensp;
              {this.convertToLocalDate(timestamp)}
              &ensp;
              {this.convertToLocalTime(timestamp)}
            </small>
          </span>
          <div className="truncated-message mt-2">{message}</div>
          <div className="mt-2">{this.displayAttachment(attachment)}</div>
        </div>
      ) : (
        <div className="last-message-display">
          <span>
            <b className="text-secondary">{name}</b>
            <small className="text-secondary">
              &ensp;
              {this.convertToLocalDate(timestamp)}
              &ensp;
              {this.convertToLocalTime(timestamp)}
            </small>
          </span>
          <div className="truncated-message mt-2">{message}</div>
          <div className="mt-2">{this.displayAttachment(attachment)}</div>
        </div>
      );
    }

    if (conversation[0]) {
      const {
        authorId,
        authorName,
        description,
        attachment,
        dateCreated,
      } = conversation[0];

      if (authorId.toString() === currentUserId.toString()) {
        isSentByCurrentUser = true;
      }

      return isSentByCurrentUser ? (
        <div>
          <span>
            <b className="text-info">{authorName}</b>
            <small className="text-secondary">
              &ensp;
              {this.convertToLocalDate(dateCreated)}
              &ensp;
              {this.convertToLocalTime(dateCreated)}
            </small>
          </span>
          <div className="truncated-message mt-2">{description}</div>
          <div className="mt-2">{this.displayAttachment(attachment)}</div>
        </div>
      ) : (
        <div>
          <span>
            <b className="text-secondary">{authorName}</b>
            <small className="text-secondary">
              &ensp;
              {this.convertToLocalDate(dateCreated)}
              &ensp;
              {this.convertToLocalTime(dateCreated)}
            </small>
          </span>
          <div className="truncated-message mt-2">{description}</div>
          <div className="mt-2">{this.displayAttachment(attachment)}</div>
        </div>
      );
    }

    return <div />;
  }
}

LastMessageDisplay.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  conversationId: PropTypes.number.isRequired,
};

export default LastMessageDisplay;
