import React from 'react';
import PropTypes from 'prop-types';
import LoadingText from '../common/LoadingText';
import server from '../../server';

class StatusLogPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      statusLog: [],
      statusLogCount: [],
      statuses: [],
      page: 1,
      isLoading: false,
      rerender: false,
      getStatusLogRoute: `${server}/help-desk/conversation/get-status-log`,
      getStatusLogCountRoute: `${server}/help-desk/conversation/get-status-log-count`,
      getStatusesRoute: `${server}/help-desk/conversation/get-statuses`,
    };
  }

  componentDidMount() {
    const { conversation } = this.props;

    const { conversationId } = conversation;

    const {
      page,
      getStatusLogRoute,
      getStatusLogCountRoute,
      getStatusesRoute,
    } = this.state;

    fetch(getStatusLogRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        conversationId,
        page,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ statusLog: res }));

    fetch(getStatusLogCountRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        conversationId,
      }),
    })
      .then((res) => res.json())
      .then((res) => this.setState({ statusLogCount: res, isLoading: false }));

    fetch(getStatusesRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((res) => this.setState({ statuses: res }));
  }

  componentDidUpdate() {
    const { conversation } = this.props;

    const { conversationId } = conversation;

    const {
      page,
      rerender,
      getStatusLogRoute,
      getStatusLogCountRoute,
    } = this.state;

    if (rerender) {
      fetch(getStatusLogRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          conversationId,
          page,
        }),
      })
        .then((res) => res.json())
        .then((res) => this.setState({ statusLog: res, rerender: false }));

      fetch(getStatusLogCountRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          conversationId,
        }),
      })
        .then((res) => res.json())
        .then((res) => this.setState({ statusLogCount: res, rerender: false }));
    }
  }

  convertToLocalDate(date) {
    const localDate = new Date(date).toLocaleDateString('en-GB', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

    return localDate;
  }

  convertToLocalTime(time) {
    const localTime = new Date(time).toLocaleTimeString('en-GB');

    return localTime;
  }

  calculatePages(totalEntries) {
    return Math.ceil(totalEntries / 10);
  }

  calculateDuration(dateStarted, dateEnded) {
    const timeStart = new Date(dateStarted).getTime();
    const timeEnd = new Date(dateEnded).getTime();

    const durationInHours = (timeEnd - timeStart) / 1000 / 60 / 60;

    return `${durationInHours.toFixed(2)} h`;
  }

  calculateTotalDuration(statusLog) {
    let issueOpened = 0;
    let issueRequiresClarification = 0;
    let issueUnderEvaluation = 0;
    let issueConfirmed = 0;
    let issueResolved = 0;
    let enhancementRequested = 0;
    let enhancementUnderDevelopment = 0;

    for (let i = 0; i < statusLog.length; i += 1) {
      if (statusLog[i].statusId.toString() === '1') {
        issueOpened +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60;
      }

      if (statusLog[i].statusId.toString() === '2') {
        issueRequiresClarification +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60;
      }

      if (statusLog[i].statusId.toString() === '3') {
        issueUnderEvaluation +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60;
      }

      if (statusLog[i].statusId.toString() === '4') {
        issueConfirmed +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60;
      }

      if (statusLog[i].statusId.toString() === '5') {
        issueResolved +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60;
      }

      if (statusLog[i].statusId.toString() === '7') {
        enhancementRequested +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60;
      }

      if (statusLog[i].statusId.toString() === '8') {
        enhancementUnderDevelopment +=
          (new Date(statusLog[i].dateEnded).getTime() -
            new Date(statusLog[i].dateStarted).getTime()) /
          1000 /
          60 /
          60;
      }
    }

    return (
      <div className="fade-in">
        <div className="pt-5" />
        <h6>Total Duration</h6>
        <div className="pt-4" />
        <table className="table">
          <thead>
            <tr>
              <th>Status</th>
              <th className="text-right">Duration</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <i className="red-500 fa fa-square" />
                &ensp;Issue opened: waiting for agent response
              </td>
              <td className="text-right">
                {issueOpened.toFixed(2)}
                &nbsp;h
              </td>
            </tr>
            <tr>
              <td>
                <i className="orange-500 fa fa-square" />
                &ensp;Issue requires clarification: waiting for client response
              </td>
              <td className="text-right">
                {issueRequiresClarification.toFixed(2)}
                &nbsp;h
              </td>
            </tr>
            <tr>
              <td>
                <i className="yellow-500 fa fa-square" />
                &ensp;Issue under evaluation: waiting for developer feedback
              </td>
              <td className="text-right">
                {issueUnderEvaluation.toFixed(2)}
                &nbsp;h
              </td>
            </tr>
            <tr>
              <td>
                <i className="green-500 fa fa-square" />
                &ensp;Issue confirmed: waiting for developer patch
              </td>
              <td className="text-right">
                {issueConfirmed.toFixed(2)}
                &nbsp;h
              </td>
            </tr>
            <tr>
              <td>
                <i className="blue-500 fa fa-square" />
                &ensp;Issue resolved: waiting for client confirmation
              </td>
              <td className="text-right">
                {issueResolved.toFixed(2)}
                &nbsp;h
              </td>
            </tr>
            <tr>
              <td>
                <i className="indigo-500 fa fa-square" />
                &ensp;Enhancement requested: waiting for developer confirmation
              </td>
              <td className="text-right">
                {enhancementRequested.toFixed(2)}
                &nbsp;h
              </td>
            </tr>
            <tr>
              <td>
                <i className="purple-500 fa fa-square" />
                &ensp;Enhancement under development: waiting for developer patch
              </td>
              <td className="text-right">
                {enhancementUnderDevelopment.toFixed(2)}
                &nbsp;h
              </td>
            </tr>
            <tr>
              <td>
                <b>Total</b>
              </td>
              <td className="text-right">
                <b>
                  {(
                    issueOpened +
                    issueRequiresClarification +
                    issueUnderEvaluation +
                    issueConfirmed +
                    issueResolved +
                    enhancementRequested +
                    enhancementUnderDevelopment
                  ).toFixed(2)}
                  &nbsp;h
                </b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const { conversation } = this.props;

    const { reference } = conversation;

    const { statusLog, statusLogCount, statuses, page, isLoading } = this.state;

    if (isLoading) {
      return <LoadingText />;
    }

    function getStatus(statusId) {
      if (statuses.length > 0) {
        switch (statusId) {
          case '1':
            return (
              <span>
                <i className="red-500 fa fa-square" />
                &ensp;
                {statuses[0].status}
              </span>
            );
          case '2':
            return (
              <span>
                <i className="orange-500 fa fa-square" />
                &ensp;
                {statuses[1].status}
              </span>
            );
          case '3':
            return (
              <span>
                <i className="yellow-500 fa fa-square" />
                &ensp;
                {statuses[2].status}
              </span>
            );
          case '4':
            return (
              <span>
                <i className="green-500 fa fa-square" />
                &ensp;
                {statuses[3].status}
              </span>
            );
          case '5':
            return (
              <span>
                <i className="blue-500 fa fa-square" />
                &ensp;
                {statuses[4].status}
              </span>
            );
          case '6':
            return (
              <span>
                <i className="gray-500 fa fa-square" />
                &ensp;
                {statuses[5].status}
              </span>
            );
          case '7':
            return (
              <span>
                <i className="indigo-500 fa fa-square" />
                &ensp;
                {statuses[6].status}
              </span>
            );
          case '8':
            return (
              <span>
                <i className="purple-500 fa fa-square" />
                &ensp;
                {statuses[7].status}
              </span>
            );
          case '9':
            return (
              <span>
                <i className="brown-500 fa fa-square" />
                &ensp;
                {statuses[8].status}
              </span>
            );
          default:
            return <div />;
        }
      }

      return <div />;
    }

    let totalEntries = 0;
    let totalPages = 1;

    if (statusLogCount[0]) {
      totalEntries = parseInt(statusLogCount[0].statusLogCount, 10);
      totalPages = this.calculatePages(
        parseInt(statusLogCount[0].statusLogCount, 10),
      );
    }

    const isFirstPage = page - 1 === 0;
    const isLastPage = page === totalPages;

    const previousPage = [];
    const nextPage = [];

    if (totalPages < 3) {
      for (let i = page + 1; i < page + 2; i += 1) {
        nextPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }

      for (let i = page - 1; i < page; i += 1) {
        previousPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    } else if (page - 1 < 1) {
      for (let i = page + 1; i < page + 3; i += 1) {
        nextPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    } else if (page < totalPages) {
      for (let i = page - 1; i < page; i += 1) {
        previousPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }

      for (let i = page + 1; i < page + 2; i += 1) {
        nextPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    } else if (page === totalPages) {
      for (let i = page - 2; i < page; i += 1) {
        previousPage.push(
          <button
            key={i}
            type="button"
            className="btn border btn-light"
            onClick={() => this.setState({ page: i, rerender: true })}
          >
            {i}
          </button>,
        );
      }
    }

    return (
      <div className="fade-in container-fluid p-5">
        <div className="pt-4" />
        <h6 className="text-secondary">
          {reference}
          &ensp;Status Log
        </h6>
        <br />
        <table className="table">
          <thead>
            <tr>
              <th>Status</th>
              <th>Date Started</th>
              <th>Date Ended</th>
              <th className="text-right">Duration</th>
            </tr>
          </thead>
          <tbody>
            {statusLog.map((log) => (
              <tr key={log.statusLogId} className="fade-in">
                <td>
                  {getStatus(log.statusId.toString())}
                  &ensp;
                </td>
                <td>
                  {this.convertToLocalDate(log.dateStarted)}
                  &ensp;
                  {this.convertToLocalTime(log.dateStarted)}
                  &ensp;
                </td>
                <td>
                  {this.convertToLocalDate(log.dateEnded)}
                  &ensp;
                  {this.convertToLocalTime(log.dateEnded)}
                  &ensp;
                </td>
                <td className="text-right">
                  {this.calculateDuration(log.dateStarted, log.dateEnded)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div>
          {totalEntries > 0 ? (
            <div>
              {isFirstPage && isLastPage ? (
                <div className="text-center">
                  <button
                    type="button"
                    className="btn border btn-light"
                    disabled
                  >
                    &lsaquo;
                  </button>
                  <button
                    type="button"
                    className="btn border btn-info"
                    disabled
                  >
                    {page}
                  </button>
                  <button
                    type="button"
                    className="btn border btn-light"
                    disabled
                  >
                    &rsaquo;
                  </button>
                </div>
              ) : (
                <div>
                  {isFirstPage ? (
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn border btn-light"
                        disabled
                      >
                        &lsaquo;
                      </button>
                      <button
                        type="button"
                        className="btn border btn-info"
                        disabled
                      >
                        {page}
                      </button>
                      {nextPage}
                      <button
                        type="button"
                        className="btn border btn-light"
                        onClick={() =>
                          this.setState({ page: page + 1, rerender: true })
                        }
                      >
                        &rsaquo;
                      </button>
                    </div>
                  ) : (
                    <div>
                      {isLastPage ? (
                        <div className="text-center">
                          <button
                            type="button"
                            className="btn border btn-light"
                            onClick={() =>
                              this.setState({
                                page: page - 1,
                                rerender: true,
                              })
                            }
                          >
                            &lsaquo;
                          </button>
                          {previousPage}
                          <button
                            type="button"
                            className="btn border btn-info"
                            disabled
                          >
                            {page}
                          </button>
                          <button
                            type="button"
                            className="btn border btn-light"
                            disabled
                          >
                            &rsaquo;
                          </button>
                        </div>
                      ) : (
                        <div>
                          {!isFirstPage && !isLastPage ? (
                            <div className="text-center">
                              <button
                                type="button"
                                className="btn border btn-light"
                                onClick={() =>
                                  this.setState({
                                    page: page - 1,
                                    rerender: true,
                                  })
                                }
                              >
                                &lsaquo;
                              </button>
                              {previousPage}
                              <button
                                type="button"
                                className="btn border btn-info"
                                disabled
                              >
                                {page}
                              </button>
                              {nextPage}
                              <button
                                type="button"
                                className="btn border btn-light"
                                onClick={() =>
                                  this.setState({
                                    page: page + 1,
                                    rerender: true,
                                  })
                                }
                              >
                                &rsaquo;
                              </button>
                            </div>
                          ) : (
                            <div />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div />
          )}
        </div>
        <div>{this.calculateTotalDuration(statusLog)}</div>
      </div>
    );
  }
}

StatusLogPage.propTypes = {
  conversation: PropTypes.shape({
    conversationId: PropTypes.number,
    organizationId: PropTypes.number,
    projectId: PropTypes.number,
    reference: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    authorId: PropTypes.number,
    description: PropTypes.string,
    attachment: PropTypes.string,
    statusId: PropTypes.number,
    dateCreated: PropTypes.string,
  }).isRequired,
};

export default StatusLogPage;
