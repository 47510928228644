import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import server from '../../server';

class EditProfilePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editProfileRoute: `${server}/help-desk/user/edit-profile`,
    };
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { editProfileRoute } = this.state;

    fetch(editProfileRoute, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        accountId: event.target.accountId.value,
        email: event.target.email.value,
        name: event.target.name.value,
        position: event.target.position.value,
        mobile: event.target.mobile.value,
        landline: event.target.landline.value,
      }),
    }).then((res) => res.json());

    function ProfileEditedNotification() {
      return (
        <div className="alert alert-success">
          <span>Profile edited.</span>
        </div>
      );
    }

    ReactDOM.render(
      <ProfileEditedNotification />,
      document.getElementById('notification'),
    );

    setTimeout(() => {
      window.location.href = '/profile';
    }, 1000);
  }

  render() {
    const { account } = this.props;

    const {
      accountId = account[0].accountId,
      email = account[0].email,
      name = account[0].name,
      position = account[0].position,
      mobile = account[0].mobile,
      landline = account[0].landline,
    } = account[0];

    return (
      <div className="fade-in centered-form mx-auto">
        <div className="pt-3" />
        <form onSubmit={this.handleSubmit.bind(this)}>
          <h5 className="text-center text-secondary">Profile</h5>
          <br />
          <input type="hidden" name="accountId" defaultValue={accountId} />
          <div className="form-group">
            <label htmlFor="email" className="col-12 text-secondary">
              Email
              <input
                type="text"
                id="email"
                className="form-control"
                name="email"
                defaultValue={email}
                onChange={this.handleChange.bind(this)}
                placeholder="Enter email"
              />
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="name" className="col-12 text-secondary">
              Name
              <input
                type="text"
                id="name"
                className="form-control"
                name="name"
                defaultValue={name}
                onChange={this.handleChange.bind(this)}
                placeholder="Enter name"
              />
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="position" className="col-12 text-secondary">
              Position
              <input
                type="text"
                id="position"
                className="form-control"
                name="position"
                defaultValue={position}
                onChange={this.handleChange.bind(this)}
                placeholder="Enter position"
              />
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="mobile" className="col-12 text-secondary">
              Mobile
              <input
                type="text"
                id="mobile"
                className="form-control"
                name="mobile"
                defaultValue={mobile}
                onChange={this.handleChange.bind(this)}
                placeholder="Enter mobile"
              />
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="landline" className="col-12 text-secondary">
              Landline
              <input
                type="text"
                id="landline"
                className="form-control"
                name="landline"
                defaultValue={landline}
                onChange={this.handleChange.bind(this)}
                placeholder="Enter landline"
              />
            </label>
          </div>
          <br />
          <div className="text-center">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

EditProfilePage.propTypes = {
  account: PropTypes.shape({
    accountId: PropTypes.number,
    type: PropTypes.string,
    organizationId: PropTypes.number,
    email: PropTypes.string,
    name: PropTypes.string,
    position: PropTypes.string,
    mobile: PropTypes.string,
    landline: PropTypes.string,
  }).isRequired,
};

export default EditProfilePage;
